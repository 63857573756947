import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Cardboard() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener to show/hide scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the 'goods and products' link in the nav
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Cardboard Freight Services | Logistics Solutions by QFM</title>
        <meta name="description" content="QFM offers reliable cardboard freight services for transporting recycled and new cardboard products with eco-friendly, secure, and timely delivery across Australia." />
        <meta name="keywords" content="Cardboard Freight Services, Cardboard Transport, Recycled Cardboard Shipping, Eco-friendly Freight, Australia Cardboard Logistics, Secure Transport, Melbourne Cardboard Freight" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Cardboard Freight Services | Logistics Solutions by QFM" />
        <meta property="og:description" content="Expert freight solutions for cardboard products. We deliver secure and eco-friendly transport of recycled and new cardboard supplies across Australia." />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-cardboard.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/cardboard-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/cardboard-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/cardboard-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/cardboard-freight-services",
                "name": "Cardboard Freight Services | Logistics Solutions by QFM",
                "description": "Specialised freight solutions for transporting cardboard products, including recycled materials and new supplies. Enjoy eco-friendly and secure delivery across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/cardboard-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/cardboard-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Cardboard Freight Services",
                    "item": "https://qfmlogistics.com.au/cardboard-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/cardboard-freight-services#service",
                "name": "Cardboard Freight Services",
                "description": "Expert freight solutions for cardboard products, including recycled and new supplies. Our eco-friendly logistics ensure secure, timely delivery to retailers, warehouses, and distribution centres across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-cardboard.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-cardboard-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your cardboard freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-cardboard.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-cardboard.jpg",
                "description": "Secure transport solutions for cardboard products and recycled materials across Australia.",
                "name": "Cardboard Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/cardboard-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-cardboard-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-cardboard-two.jpg",
                "description": "Specialised logistics for transporting cardboard products efficiently and sustainably across Australia.",
                "name": "Cardboard Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/cardboard-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/cardboard-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of cardboard products do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide freight solutions for all types of cardboard products, including corrugated boxes, flat-packed packaging materials, industrial cardboard sheets, and recycled cardboard. Whether you're a manufacturer, distributor, or recycling facility, we ensure secure transport tailored to your specific shipping needs."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you ensure the safe transport of cardboard shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We use reinforced palletisation, protective strapping, and moisture-resistant transport methods to prevent crushing, tearing, or exposure damage. Our team ensures that loads are properly secured during transit to maintain the integrity of your cardboard products."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What are your delivery timeframes for cardboard freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Delivery times vary depending on distance, shipment size, and service level. Metro-to-metro deliveries typically take 1-3 business days, while regional and interstate shipments may range from 3-7 business days. Express and priority shipping options are available for urgent deliveries."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer bulk freight options for large cardboard shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide bulk freight solutions for high-volume cardboard shipments, including full truckloads (FTL), partial loads (LTL), and palletised transport. Our logistics team can optimise load efficiency and route planning to reduce costs while ensuring timely deliveries."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for cardboard freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To get a personalised freight quote, visit our Contact Us page or call our support team. Please provide key details such as pickup and delivery locations, shipment dimensions, weight, and any special requirements. Our team will tailor a competitive quote to match your transport needs."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/cardboard-freight-services#webpage" }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Cardboard Freight Services</h1>
            <p>
              Lightweight yet essential, cardboard plays a crucial role in packaging, storage, and recycling industries. Whether transporting raw materials for manufacturing or finished cartons for distribution, QFM ensures your shipments are handled with care to prevent crushing, moisture exposure, or transit damage.
            </p>
            {!isMobile && (
              <p>
                Our extensive freight network spans major cities and regional areas across Australia, providing reliable delivery for bulk cardboard supplies. From high-volume shipments to time-sensitive deliveries, we offer tailored transport solutions that keep your supply chain moving efficiently and sustainably.
              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-cardboard.jpg"
              alt="Eco-friendly and secure transport for cardboard products across Australia"
              title="Cardboard Freight Services - Efficient and Reliable Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Reliable Cardboard Freight Solutions for Packaging & Recycling</h5>
            )}
            <p>
              From bulk packaging materials to recycled cardboard shipments, our freight services provide secure, eco-conscious transport designed to protect your goods in transit.
              <Link to="/goods-and-products"> Discover our full range of logistics solutions</Link>.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Cardboard Freight by QFM</h2>
            <ul>
              <li>
                <b>Secure Cardboard Freight Solutions:</b> Expert transport services for packaging materials, corrugated sheets, and recycled cardboard, ensuring safe handling and damage-free deliveries.
              </li>
              <li>
                <b>Scalable Shipping for Any Volume:</b> From single pallets to bulk consignments, we offer tailored logistics solutions for manufacturers, distributors, and recycling centres.
              </li>
              <li>
                <b>Nationwide Logistics Coverage:</b> Reliable freight services across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations, supporting seamless supply chain operations.
              </li>
              <li>
                <b>Real-Time Tracking & Dependable Delivery:</b> Advanced shipment monitoring ensures complete visibility, giving you confidence that your cardboard freight arrives on time.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-cardboard-two.jpg"
              loading="lazy"
              alt="Efficient and secure transport for cardboard products across Australia"
              title="Cardboard Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of cardboard products do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We provide freight solutions for all types of cardboard products, including corrugated boxes, flat-packed packaging materials, industrial cardboard sheets, and recycled cardboard. Whether you're a manufacturer, distributor, or recycling facility, we ensure secure transport tailored to your specific shipping needs.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>How do you ensure the safe transport of cardboard shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
                <p>
                  We use reinforced palletisation, protective strapping, and moisture-resistant transport methods to prevent crushing, tearing, or exposure damage. Our team ensures that loads are properly secured during transit to maintain the integrity of your cardboard products.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>What are your delivery timeframes for cardboard freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '280px' : '0px' }}>
                <p>
                  Delivery times vary depending on distance, shipment size, and service level. Metro-to-metro deliveries typically take 1-3 business days, while regional and interstate shipments may range from 3-7 business days. Express and priority shipping options are available for urgent deliveries.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Do you offer bulk freight options for large cardboard shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '280px' : '0px' }}>
                <p>
                  Yes, we provide bulk freight solutions for high-volume cardboard shipments, including full truckloads (FTL), partial loads (LTL), and palletised transport. Our logistics team can optimise load efficiency and route planning to reduce costs while ensuring timely deliveries.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I request a quote for cardboard freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  To get a personalised freight quote, visit our <Link to="/get-in-touch">Contact Us</Link> page or call our support team. Please provide key details such as pickup and delivery locations, shipment dimensions, weight, and any special requirements. Our team will tailor a competitive quote to match your transport needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Cardboard;