import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function AirFreight() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/our-services"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Air Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management offers reliable air freight services across Australia. Our fast and flexible logistics solutions ensure secure and timely transport of goods with advanced tracking and expert handling." />
                <meta name="keywords" content="Air Freight Services, Quan Freight Management, QFM Logistics, Air Transport Australia, Melbourne Air Freight, Brisbane Air Logistics, Sydney Air Freight, Perth Air Logistics, Freight Solutions, Reliable Air Logistics, Express Freight, Domestic Freight, Urgent Freight, Advanced Air Freight Solutions, Cost-Effective Logistics Services" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Air Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's air freight services. Serving all states and territories across Australia, we provide fast and secure delivery with advanced logistics solutions and professional handling." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-services-air-freight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/air-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/air-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/rail-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/rail-freight-services",
                                "name": "Rail Freight Services | Logistics Solutions by QFM",
                                "description": "QFM offers reliable rail freight services connecting Melbourne to Brisbane, Sydney, and Perth. Our cost-effective and sustainable solutions ensure secure and efficient transport of goods across Australia's extensive rail network.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/rail-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/rail-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Our Services",
                                        "item": "https://qfmlogistics.com.au/our-services"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Rail Freight Services",
                                        "item": "https://qfmlogistics.com.au/rail-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/rail-freight-services#service",
                                "name": "Rail Freight Services",
                                "description": "Our rail freight services connect Melbourne to Brisbane, Sydney, and Perth, offering efficient and environmentally friendly logistics solutions for bulk and specialised shipments.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-services-rail-freight.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-rail-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-rail-freight.jpg",
                                "description": "Reliable rail freight services connecting Melbourne to Brisbane, Sydney, and Perth.",
                                "name": "Rail Freight Services – Reliable Logistics by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-rail-freight-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-rail-freight-two.jpg",
                                "description": "Efficient rail freight services with sustainable logistics solutions across Australia's extensive rail network.",
                                "name": "Rail Freight Services – Comprehensive Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/rail-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What are the primary routes for your rail freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our rail freight services connect Melbourne to major cities like Brisbane, Sydney, and Perth, providing reliable and cost-effective logistics solutions."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of goods can be transported via rail freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Rail freight is suitable for transporting bulk goods, pallets, heavy machinery, and specialised cargo requiring secure handling."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How does rail freight compare to road freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Rail freight is ideal for long-distance transport, offering cost savings and reduced environmental impact compared to road freight. Road freight is better suited for shorter distances and last-mile delivery."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can I track my rail freight shipment?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, all rail freight shipments include real-time tracking to keep you updated throughout the journey."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a rail freight quote?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page and provide shipment details such as origin, destination, size, and weight for a customised quote."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]

                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Air Freight Services</h1>

                        <p>
                            Experience unparalleled speed and efficiency with QFM's Air Freight solutions, designed to transport goods swiftly and securely across Australia. From small parcels to large-scale shipments, our services are tailored to meet the demands of businesses requiring urgent, reliable, and high-value freight delivery. Whether handling time-critical shipments or fragile cargo, our Air Freight ensures your goods arrive on schedule, in perfect condition, every time.
                        </p>
                        {!isMobile && (
                            <p>
                                Our advanced logistics technology and dedicated team manage every aspect of your air shipments, providing seamless coordination from pickup to delivery. Committed to excellence, QFM guarantees reliability, speed, and security for all your air transportation needs.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-services-air-freight.jpg"
                            alt="Fast and reliable air freight services connecting Australia-wide with speed and precision"
                            title="Air Freight Services – Swift and Secure Logistics by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Swift and reliable air freight solutions designed to keep your business moving</h5>
                        )}
                        <p>
                            QFM's air freight services offer unparalleled speed and security, ensuring your shipments reach any destination across Australia with precision and care. <Link to="/our-services">Discover the full scope of our logistics expertise</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content sydney-add">
                        <h2>Melbourne Air Freight by QFM</h2>
                        <ul>
                            <li>
                                <b>Fastest Delivery:</b> Air freight provides the quickest transit times, ensuring your urgent shipments arrive on schedule, no matter the distance.
                            </li>
                            <li>
                                <b>High Security:</b> Enhanced security measures and strict protocols safeguard valuable, high-risk, and time-sensitive shipments throughout transit.
                            </li>
                            <li>
                                <b>Flexible Solutions:</b> Customised air freight services tailored to meet diverse industry needs, from small parcels to large-scale cargo.
                            </li>
                            <li>
                                <b>Nationwide Coverage:</b> Reliable air freight services connecting major cities and regional hubs across Australia for time-sensitive deliveries.
                            </li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-services-air-freight-two.jpg"
                            loading="lazy"
                            alt="Comprehensive air freight solutions ensuring fast and secure deliveries across Australia"
                            title="Air Freight Services – Fast and Reliable Logistics by QFM"
                        />
                        {isMobile && (
                            <div className="default-quote appearing-effect">
                                <div className="default-quote-quote">
                                    <Link to="/our-services"><h4>View all Services</h4></Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="air-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What destinations do your air freight services cover?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our air freight services connect Melbourne to cities and regions across Australia, including Sydney, Brisbane, Perth, Adelaide, and remote areas. We offer nationwide coverage to ensure your shipments reach their destination promptly and securely.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Can you handle urgent or time-sensitive shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Absolutely. Our air freight solutions are designed to accommodate urgent and time-sensitive shipments. We prioritise speed and efficiency, offering fast transit times and reliable delivery for critical cargo.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What types of goods can be transported via air freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "260px" : "0px" }}
                            >
                                <p>
                                    Our air freight services cater to a wide range of goods, including high-value items, perishable products, medical supplies, and electronics. If you have specific cargo requirements, contact us to discuss tailored solutions for your shipment.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How can I request a quote for air freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    Requesting a quote is simple. Visit our <Link to="/get-in-touch">Contact Us</Link> page and provide details such as the pickup and delivery locations, cargo type, weight, and dimensions. Our team will quickly provide a customised quote to suit your requirements.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Do you provide tracking for air freight shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "230px" : "0px" }}
                            >
                                <p>
                                    Yes, all air freight shipments come with real-time tracking. Our advanced logistics technology allows you to monitor your cargo throughout its journey, providing peace of mind and transparency.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>Are there weight or size restrictions for air freight shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "280px" : "0px" }}
                            >
                                <p>
                                    While air freight accommodates a variety of weights and sizes, specific restrictions may apply depending on aircraft capacity and regulations. Contact us with your shipment details, and we’ll provide guidance on the best approach for your cargo.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 7 */}
                        <div
                            className={`faq-item ${openFAQ === 6 ? "active" : ""}`}
                            onClick={() => toggleFAQ(6)}
                        >
                            <h5>How does air freight compare to other transport modes?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 6 ? "300px" : "0px" }}
                            >
                                <p>
                                    Air freight offers unparalleled speed and reliability, making it ideal for urgent or high-value shipments. While it may be more expensive than road or rail options, the efficiency and quick turnaround times can provide significant value for time-sensitive deliveries.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default AirFreight;