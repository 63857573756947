import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Party() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Party Freight Services | Logistics Solutions by QFM</title>
        <meta name="description" content="QFM offers specialised party freight services for transporting event supplies, decorations, and party equipment. Our secure logistics ensure your party essentials arrive safely and on time across Australia." />
        <meta name="keywords" content="Party Freight Services, Event Supplies Transport, Party Equipment Shipping, Party Logistics, Australia Freight, Secure Party Transport, Melbourne Party Freight" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Party Freight Services | Logistics Solutions by QFM" />
        <meta property="og:description" content="Secure and reliable party freight services for event supplies, decorations, and party equipment. Trust QFM to deliver your party essentials safely and on schedule across Australia." />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-party.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/party-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/party-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/party-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/party-freight-services",
                "name": "Party Freight Services | Logistics Solutions by QFM",
                "description": "Specialised freight services for transporting event supplies, decorations, and party equipment. Our secure logistics ensure that your party essentials arrive safely and on time across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/party-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/party-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Party Freight Services",
                    "item": "https://qfmlogistics.com.au/party-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/party-freight-services#service",
                "name": "Party Freight Services",
                "description": "Our party freight services are specifically tailored to transport event supplies, party decorations, and equipment for special occasions. We combine secure handling, customised logistics, and a nationwide network to ensure that your party essentials are delivered with precision and care.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-party.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-party-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your party freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-party.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-party.jpg",
                "description": "Secure transport solutions for event supplies, decorations, and party equipment across Australia.",
                "name": "Party Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/party-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-party-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-party-two.jpg",
                "description": "Specialised logistics for transporting party equipment and event supplies with exceptional care and efficiency.",
                "name": "Party Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/party-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/party-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of party supplies and equipment do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide freight services for a variety of party and event supplies, including event decorations, seating, lighting, audio-visual equipment, catering essentials, and staging materials. Whether you're moving items for a corporate event, wedding, or festival, our logistics ensure smooth and timely transport."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer express delivery for last-minute event orders?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we offer expedited freight solutions for urgent party supply deliveries. Whether you need same-day or next-day service, we prioritise time-sensitive shipments to ensure your event runs as planned without delays."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you handle oversized or bulky party equipment?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely. We manage the transport of large event structures, marquees, temporary fencing, dance floors, and staging materials. Our freight network is equipped to handle bulky and oversized items while ensuring secure and efficient delivery."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you transport fragile party supplies like glassware and decorative pieces?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We do not transport glass products such as drinkware or fragile glass decorations. However, we handle other party essentials, including plastic tableware, LED lighting, drapery, and themed decor with careful loading and handling techniques."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for party freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Requesting a quote is easy. Simply visit our Contact Us page or call our support team. Provide shipment details such as volume, pickup and delivery locations, and any time-sensitive requests, and we’ll offer a tailored freight solution."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Why choose QFM for party and event freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our event freight services are designed to support seamless event logistics. With a reliable nationwide network, flexible scheduling, and real-time tracking, we ensure your party supplies and equipment arrive on time, helping your event go off without a hitch."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/party-freight-services#webpage" }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Party Freight Services</h1>

            <p>
              Planning a corporate function, wedding, or large-scale celebration? Our party freight services provide secure and efficient transport for event essentials, including decorations, staging equipment, and supplies. We recognise the importance of on-time delivery to keep your event running smoothly.
            </p>

            {!isMobile && (
              <p>
                With an extensive logistics network covering both metropolitan areas and remote locations, we ensure your party freight reaches its destination safely and on schedule. Our services are tailored to handle everything from delicate décor to large-scale event infrastructure with precision and care.
              </p>
            )}

          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-party.jpg"
              alt="Secure transport for party supplies, decorations, and equipment across Australia"
              title="Party Freight Services - Reliable and Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Innovative Party Freight Solutions for Memorable Events</h5>
            )}
            <p>
              Our specialised freight services ensure your event supplies and party equipment are transported securely and delivered on time. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive logistics support.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Party Freight by QFM</h2>
            <ul>
              <li>
                <b>Event-Ready Logistics:</b> Reliable transport for decorations, staging equipment, and party supplies, ensuring your event setup is seamless.
              </li>
              <li>
                <b>Timely Delivery:</b> We prioritise on-schedule freight solutions, ensuring your party essentials arrive when and where you need them.
              </li>
              <li>
                <b>Secure Handling:</b> Specialised transport for fragile décor, lighting, and audio-visual equipment to prevent damage in transit.
              </li>
              <li>
                <b>Australia-Wide Reach:</b> Serving Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas with reliable logistics for all event sizes.
              </li>
            </ul>

          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-party-two.jpg"
              loading="lazy"
              alt="Professional freight for party supplies ensuring secure and timely delivery"
              title="Party Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of party supplies and equipment do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We provide freight services for a variety of party and event supplies, including event decorations, seating, lighting, audio-visual equipment, catering essentials, and staging materials. Whether you're moving items for a corporate event, wedding, or festival, our logistics ensure smooth and timely transport.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you offer express delivery for last-minute event orders?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
                <p>
                  Yes, we offer expedited freight solutions for urgent party supply deliveries. Whether you need same-day or next-day service, we prioritise time-sensitive shipments to ensure your event runs as planned without delays.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Do you handle oversized or bulky party equipment?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  Absolutely. We manage the transport of large event structures, marquees, temporary fencing, dance floors, and staging materials. Our freight network is equipped to handle bulky and oversized items while ensuring secure and efficient delivery.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Can you transport fragile party supplies like glassware and decorative pieces?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
                <p>
                  We do not transport glass products such as drinkware or fragile glass decorations. However, we handle other party essentials, including plastic tableware, LED lighting, drapery, and themed decor with careful loading and handling techniques.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I request a quote for party freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  Requesting a quote is easy. Simply visit our <Link to="/get-in-touch">Contact Us</Link> page or call our support team. Provide shipment details such as volume, pickup and delivery locations, and any time-sensitive requests, and we’ll offer a tailored freight solution.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>Why choose QFM for party and event freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '320px' : '0px' }}>
                <p>
                  Our event freight services are designed to support seamless event logistics. With a reliable nationwide network, flexible scheduling, and real-time tracking, we ensure your party supplies and equipment arrive on time, helping your event go off without a hitch.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Party;