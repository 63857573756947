import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Industrial() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Show/hide scroll-up button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Industrial Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM provides specialised industrial freight services for transporting heavy machinery, industrial components, and production parts across Australia. Our customised logistics ensure secure and efficient delivery, minimising downtime."
        />
        <meta
          name="keywords"
          content="Industrial Freight Services, Industrial Equipment Shipping, Heavy Machinery Transport, Production Parts Logistics, Australia Industrial Freight, Reliable Industrial Transport, QFM"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Industrial Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure and customised industrial freight services for transporting heavy machinery, industrial components, and production parts. Trust QFM for reliable, on-schedule delivery across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-industrial.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/industrial-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/industrial-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/industrial-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/industrial-freight-services",
                "name": "Industrial Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised industrial freight services for transporting heavy machinery, industrial components, and production parts across Australia. Our customised logistics ensure secure and efficient delivery.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/industrial-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/industrial-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Industrial Freight Services",
                    "item": "https://qfmlogistics.com.au/industrial-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/industrial-freight-services#service",
                "name": "Industrial Freight Services",
                "description": "Our industrial freight services are engineered to transport heavy machinery, industrial components, and production parts with precision. We offer customised logistics solutions that minimise downtime and ensure secure, on-schedule delivery across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-industrial.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your industrial freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-industrial.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-industrial.jpg",
                "description": "Robust transport solutions for heavy machinery and industrial components, ensuring secure handling and efficient delivery.",
                "name": "Industrial Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/industrial-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "@id": "https://qfmlogistics.com.au/images/qfm-commodity-industrial-two.jpg",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-industrial-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-industrial-two.jpg",
                "description": "Reliable transport solutions for industrial equipment and heavy machinery across Australia",
                "name": "Industrial Freight Services – Secure Logistics for Heavy-Duty Shipments by Quan Freight Management",
                "author": {
                  "@id": "https://qfmlogistics.com.au#organization"
                },
                "license": {
                  "@id": "https://qfmlogistics.com.au#license"
                },
                "creator": {
                  "@id": "https://qfmlogistics.com.au#organization"
                },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/industrial-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of industrial equipment do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of industrial cargo, including heavy machinery, factory components, construction materials, and precision-engineered parts. Our freight solutions accommodate the weight, size, and handling requirements of industrial shipments, ensuring seamless delivery to warehouses, factories, and job sites."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer door-to-door service for industrial freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, our comprehensive door-to-door service ensures that industrial equipment is collected from your facility and delivered directly to its destination. This minimises handling risks and keeps your supply chain efficient. We facilitate deliveries to manufacturing plants, distribution centres, and remote worksites."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you manage bulk shipments for large-scale industrial orders?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely. We specialise in bulk industrial shipments, supporting high-volume freight with heavy-duty transport solutions. Whether moving multiple pallets of raw materials or oversized machinery, our logistics network enables scheduled freight services and multi-location deliveries."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which regions do you service for industrial freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide nationwide freight services, covering Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations across Australia. Our extensive network ensures efficient delivery to industrial hubs, construction sites, and production facilities, regardless of location."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for industrial freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To request a quote, visit our Contact Us page or call our team. Provide shipment volume, weight, pickup and delivery locations, and any special transport requirements. Our logistics experts will tailor a freight solution to meet your business needs."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/industrial-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Industrial Freight Services</h1>

            <p>
              Our industrial freight services are engineered to transport heavy machinery, factory components, and specialised production parts with exceptional care. We utilise precision handling techniques and secure transport methods to safeguard your critical industrial shipments, ensuring they reach their destination in optimal condition.
            </p>

            {!isMobile && (
              <p>
                With a logistics network that spans Australia, we provide seamless freight solutions for manufacturers, construction firms, and industrial suppliers. Whether you're moving oversized equipment, raw materials, or delicate precision instruments, our expertise ensures efficient and timely deliveries to warehouses, job sites, and production facilities—keeping your operations running smoothly and reducing costly delays.
              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-industrial.jpg"
              alt="Secure transport for heavy machinery and industrial components across Australia"
              title="Industrial Freight Services - Reliable and Secure Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Reliable Industrial Freight Solutions Tailored to Your Needs</h5>
            )}
            <p>
              Our customised freight services ensure that your industrial shipments are handled with care and delivered on time. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive logistics support.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Industrial Freight by QFM</h2>
            <ul>
              <li>
                <b>Specialist Transport for Heavy-Duty Cargo:</b> We handle the secure freight of industrial machinery, manufacturing equipment, and raw materials with expert loading techniques.
              </li>
              <li>
                <b>Custom Logistics for Industrial Supply Chains:</b> From single shipments to large-scale distribution, we provide tailored freight solutions for factories, warehouses, and production facilities.
              </li>
              <li>
                <b>Nationwide Industrial Freight Network:</b> Servicing Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations, ensuring seamless deliveries for industrial businesses.
              </li>
              <li>
                <b>Advanced Freight Tracking & Scheduling:</b> Real-time shipment visibility and coordinated transport solutions to keep supply chains running efficiently and minimise downtime.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-industrial-two.jpg"
              loading="lazy"
              alt="Reliable transport solutions for industrial equipment and heavy machinery across Australia"
              title="Industrial Freight Services – Secure Logistics for Heavy-Duty Shipments by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of industrial equipment do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We transport a wide range of industrial cargo, including heavy machinery, factory components, construction materials, and precision-engineered parts. Our freight solutions are designed to accommodate the weight, size, and handling requirements of industrial shipments, ensuring seamless delivery to warehouses, factories, and job sites.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you offer door-to-door service for industrial freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
                <p>
                  Yes, our comprehensive door-to-door service ensures that industrial equipment is collected from your facility and delivered directly to its destination. This service helps reduce unnecessary handling, minimising risks and keeping your supply chain efficient. Whether delivering to manufacturing plants, distribution centres, or remote worksites, we streamline the logistics process.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Can you manage bulk shipments for large-scale industrial orders?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  Absolutely. We handle bulk industrial shipments, offering solutions for high-volume freight that requires specialised transport. Whether moving multiple pallets of raw materials or oversized machinery, our logistics network supports large-scale operations with scheduled freight services, heavy-duty transport, and multi-location deliveries.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Which regions do you service for industrial freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '280px' : '0px' }}>
                <p>
                  We provide nationwide freight services, covering Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations across Australia. Our extensive network ensures efficient delivery to industrial hubs, construction sites, and production facilities, regardless of location.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I request a quote for industrial freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  To request a quote, visit our <Link to="/get-in-touch">Contact Us</Link> page or call our team. Provide details such as shipment volume, weight, pickup and delivery locations, and any special transport requirements. Our logistics experts will offer a customised freight solution tailored to your business needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Industrial;