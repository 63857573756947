import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function DGFreight() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/our-services"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Dangerous Goods Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management provides specialised dangerous goods freight services in Melbourne and across Australia. Our expert handling, compliance with safety regulations, and tailored logistics solutions ensure the secure transport of hazardous materials." />
                <meta name="keywords" content="Dangerous Goods Freight Services, Hazardous Materials Transport, Quan Freight Management, QFM Logistics, Melbourne Dangerous Goods Logistics, Hazardous Freight Australia, Dangerous Goods Compliance, Secure Freight Handling, Safety-Regulated Transport, Hazardous Cargo Logistics, Reliable Freight Solutions, Specialised Freight Management, Cost-Effective Hazardous Freight Services" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Dangerous Goods Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's dangerous goods freight services. Based in Melbourne, we ensure the secure and compliant transport of hazardous materials across Australia with tailored logistics solutions." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-services-dangerous-goods.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/dangerous-goods-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/dangerous-goods-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                            "@context": "https://schema.org",
                            "@graph": [
                                {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services#webpage",
                                    "url": "https://qfmlogistics.com.au/dangerous-goods-freight-services",
                                    "name": "Dangerous Goods Freight Services | Logistics Solutions by QFM",
                                    "description": "Quan Freight Management specialises in the secure and compliant transport of dangerous goods across Melbourne and Australia. From flammable liquids to compressed gases, we ensure safe and reliable delivery with adherence to all regulations.",
                                    "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "inLanguage": "en-AU",
                                    "breadcrumb": { "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services#breadcrumb" }
                                },
                                {
                                    "@type": "Organization",
                                    "@id": "https://qfmlogistics.com.au#organization",
                                    "name": "Quan Freight Management",
                                    "url": "https://qfmlogistics.com.au",
                                    "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                    "contactPoint": {
                                        "@type": "ContactPoint",
                                        "email": "contact@qfmlogistics.com.au",
                                        "contactType": "Customer Service",
                                        "areaServed": "AU"
                                    },
                                    "sameAs": [
                                        "https://www.linkedin.com/company/qfm-logistics"
                                    ]
                                },
                                {
                                    "@type": "BreadcrumbList",
                                    "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services#breadcrumb",
                                    "itemListElement": [
                                        {
                                            "@type": "ListItem",
                                            "position": 1,
                                            "name": "Home",
                                            "item": "https://qfmlogistics.com.au/"
                                        },
                                        {
                                            "@type": "ListItem",
                                            "position": 2,
                                            "name": "Our Services",
                                            "item": "https://qfmlogistics.com.au/our-services"
                                        },
                                        {
                                            "@type": "ListItem",
                                            "position": 3,
                                            "name": "Dangerous Goods Freight Services",
                                            "item": "https://qfmlogistics.com.au/dangerous-goods-freight-services"
                                        }
                                    ]
                                },
                                {
                                    "@type": "Service",
                                    "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services#service",
                                    "name": "Dangerous Goods Freight Services",
                                    "description": "Our Dangerous Goods Freight Services ensure the safe and compliant transportation of hazardous materials across Australia, with a focus on secure handling and adherence to all regulatory requirements.",
                                    "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "areaServed": "Australia",
                                    "availableChannel": {
                                        "@type": "ServiceChannel",
                                        "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                    },
                                    "image": "https://qfmlogistics.com.au/images/qfm-services-dangerous-goods-freight.jpg"
                                },
                                {
                                    "@type": "ImageObject",
                                    "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-dangerous-goods-freight.jpg",
                                    "url": "https://qfmlogistics.com.au/images/qfm-services-dangerous-goods-freight.jpg",
                                    "description": "Secure and compliant transport of dangerous goods including flammable liquids and hazardous materials across Australia.",
                                    "name": "Dangerous Goods Freight Services – Safe and Reliable Logistics by QFM",
                                    "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "license": { "@id": "https://qfmlogistics.com.au#license" },
                                    "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "copyrightNotice": "© Quan Freight Management",
                                    "creditText": "Quan Freight Management",
                                    "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                                },
                                {
                                    "@type": "ImageObject",
                                    "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-dangerous-goods-freight-two.jpg",
                                    "url": "https://qfmlogistics.com.au/images/qfm-services-dangerous-goods-freight-two.jpg",
                                    "description": "Comprehensive logistics solutions for the transport of hazardous and dangerous goods across Australia.",
                                    "name": "Dangerous Goods Freight Solutions – Expert Logistics by QFM",
                                    "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "license": { "@id": "https://qfmlogistics.com.au#license" },
                                    "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "copyrightNotice": "© Quan Freight Management",
                                    "creditText": "Quan Freight Management",
                                    "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                                },
                                {
                                    "@type": "FAQPage",
                                    "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services#faq",
                                    "mainEntity": [
                                        {
                                            "@type": "Question",
                                            "name": "What types of dangerous goods do you transport?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "We handle a wide range of dangerous goods, including flammable liquids, corrosive materials, toxic substances, and compressed gases. All shipments comply with Australian regulations, ensuring safe and secure transport."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services",
                                                "name": "Dangerous Goods Freight Services FAQ"
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "Do you provide dangerous goods transport Australia-wide?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Yes, our Dangerous Goods Freight Services operate from Melbourne and extend to major cities and regional areas across Australia. We ensure reliable and compliant transport solutions for both metropolitan and remote destinations."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services",
                                                "name": "Dangerous Goods Freight Services FAQ"
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "How can I get a quote for dangerous goods transport?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Requesting a quote for dangerous goods transport is easy. Visit our Contact Us page and provide details like the type of goods, pickup and delivery locations, weight, dimensions, and any special requirements. Our logistics experts will promptly review your request and provide a competitive, tailored quote."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services",
                                                "name": "Dangerous Goods Freight Services FAQ"
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "How do I prepare my shipment of dangerous goods?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Ensure your dangerous goods are properly packaged and labelled according to Australian Dangerous Goods (ADG) Code regulations. Include all necessary documentation, such as Safety Data Sheets (SDS). If you're unsure, our team can guide you through the process."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services",
                                                "name": "Dangerous Goods Freight Services FAQ"
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "Do you offer compliance assistance for dangerous goods transport?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Absolutely. Our team ensures that your shipment adheres to all regulatory requirements, including proper documentation and labelling. We provide expert guidance to streamline compliance and minimise risks during transport."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services",
                                                "name": "Dangerous Goods Freight Services FAQ"
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "Can you transport mixed freight that includes dangerous goods?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Yes, we can manage mixed freight that includes dangerous goods, provided the hazardous materials are correctly declared and packed. We ensure all safety and regulatory standards are met to transport your shipment securely."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/dangerous-goods-freight-services",
                                                "name": "Dangerous Goods Freight Services FAQ"
                                            }
                                        }
                                    ]
                                }
                            ]                                                  
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>DG Freight Services</h1>
                        <p>
                            We specialise in the secure and compliant transport of Dangerous Goods. With expertise in handling hazardous materials, we ensure your cargo is managed safely, adhering to all relevant regulations. From chemicals to flammable materials, our DG services provide reliable delivery and peace of mind.
                        </p>
                        {!isMobile && (
                            <p>
                                Leveraging advanced tracking systems and a network of trusted carriers, we streamline the movement of dangerous goods across Melbourne and Australia. Our tailored solutions meet the unique needs of each shipment, ensuring timely and compliant deliveries every time.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-services-dangerous-goods-freight.jpg"
                            loading="lazy"
                            alt="Safe and compliant transport of dangerous goods, including hazardous materials and flammable substances"
                            title="Dangerous Goods Freight Services – Secure and Reliable Logistics by QFM"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Specialised and secure dangerous goods logistics tailored to your safety needs</h5>
                        )}
                        <p>
                            QFM's dangerous goods freight services prioritise safety and compliance, ensuring the secure handling and transport of hazardous materials with expert care. <Link to="/our-services">Explore our full range of logistics solutions</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content sydney-add">
                        <h2>Melbourne DG Freight by QFM</h2>
                        <ul>
                            <li><b>Compliance with Australian Regulations:</b> Adherence to all local, state, and federal regulations governing the transportation of dangerous goods.</li>
                            <li><b>Specialized Handling:</b> Trained personnel equipped with the knowledge and tools to handle hazardous materials safely.</li>
                            <li><b>Reliable Transport Network:</b> Access to a nationwide network of carriers that are certified to handle dangerous goods.</li>
                            <li><b>Custom Solutions:</b> Tailored logistics solutions that meet the unique requirements of each type of dangerous good.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-services-dangerous-goods-freight-two.jpg"
                            loading="lazy"
                            alt="Specialised and secure dangerous goods freight solutions for hazardous materials across Melbourne and Australia"
                            title="Dangerous Goods Freight Services – Safe and Compliant Logistics by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                                <Link to="/our-services"><h4>View all Services</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="dg-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What types of dangerous goods do you transport?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    We handle a wide range of dangerous goods, including flammable liquids, corrosive materials, toxic substances, and compressed gases. Our services are compliant with Australian regulations, ensuring safe and secure transport for all hazardous materials.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Do you provide dangerous goods transport Australia-wide?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, our Dangerous Goods Freight Services operate from Melbourne and extend to major cities and regional areas across Australia. We ensure reliable and compliant transport solutions for both metropolitan and remote destinations.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>How can I get a quote for dangerous goods transport?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "270px" : "0px" }}
                            >
                                <p>
                                    Requesting a quote for dangerous goods transport is easy. Visit our <Link to="/get-in-touch">Contact Us</Link> page and provide details like the type of goods, pickup and delivery locations, weight, dimensions, and any special requirements. Our logistics experts will promptly review your request and provide a competitive, tailored quote.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How do I prepare my shipment of dangerous goods?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    Ensure your dangerous goods are properly packaged and labelled according to Australian Dangerous Goods (ADG) Code regulations. Include all necessary documentation, such as Safety Data Sheets (SDS). If you're unsure, our team can guide you through the process.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Do you offer compliance assistance for dangerous goods transport?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "270px" : "0px" }}
                            >
                                <p>
                                    Absolutely. Our team ensures that your shipment adheres to all regulatory requirements, including proper documentation and labelling. We provide expert guidance to streamline compliance and minimise risks during transport.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>Can you transport mixed freight that includes dangerous goods?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, we can manage mixed freight that includes dangerous goods, provided the hazardous materials are correctly declared and packed. We ensure all safety and regulatory standards are met to transport your shipment securely.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default DGFreight;