import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Carton() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Carton Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides expert carton freight services across Melbourne, Brisbane, and Australia-wide. Our reliable and cost-effective solutions ensure the safe and timely transport of cartons with advanced tracking and professional handling." />
                <meta name="keywords" content="Carton Freight Services, Quan Freight Management, QFM Logistics, Carton Transport Australia, Melbourne Carton Freight, Brisbane Carton Logistics, Freight Solutions, Reliable Logistics, Road Freight, Rail Freight, Air Freight, Carton Delivery, Safe Freight Handling, Cost-Effective Logistics Solutions, Advanced Freight Technology" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Carton Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Explore QFM's carton freight services. Serving Melbourne, Brisbane, and Australia-wide, we provide efficient and secure transport of cartons with advanced logistics solutions and expert care." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-carton.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/carton-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/carton-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/carton-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/carton-freight-services",
                                "name": "Carton Freight Services | Logistics Solutions by QFM",
                                "description": "QFM offers tailored carton freight services from Melbourne to major Australian cities. Providing efficient and secure solutions for transporting cartons of various sizes and goods.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/carton-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/carton-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Carton Freight Services",
                                        "item": "https://qfmlogistics.com.au/carton-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/carton-freight-services#service",
                                "name": "Carton Freight Services",
                                "description": "Reliable and secure carton freight services for various goods, offering transport solutions across Melbourne, Brisbane, Sydney, Perth, and regional areas.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-carton.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-carton.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-carton.jpg",
                                "description": "Handling cartons efficiently across Australia for reliable freight solutions.",
                                "name": "Carton Freight Services - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-carton-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-carton-two.jpg",
                                "description": "Tailored carton freight services designed for secure transport and timely delivery.",
                                "name": "Carton Freight Handling - Reliable Logistics by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/carton-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Which areas do your carton freight services cover?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We provide carton freight services from Melbourne to major cities, including Sydney, Brisbane, Perth, and Adelaide. Our services extend to regional areas and remote locations across Australia."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/carton-freight-services",
                                            "name": "Carton Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can you handle fragile or high-value cartons?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we specialise in handling fragile and high-value items with customised solutions to ensure secure transit. Speak with us about your specific needs."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/carton-freight-services",
                                            "name": "Carton Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of goods can be transported in cartons?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We transport retail items, packaged goods, medical supplies, office materials, and more. Contact us for specific freight requirements."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/carton-freight-services",
                                            "name": "Carton Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are there size or weight limits for cartons?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We handle cartons of various sizes and weights. Contact our team with your specific carton dimensions for the best recommendations."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/carton-freight-services",
                                            "name": "Carton Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What additional services are available for carton freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We offer express delivery, support for bulk shipments, and tailored handling solutions to fit your logistics requirements."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/carton-freight-services",
                                            "name": "Carton Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a quote for carton freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page, share your carton dimensions, weight, and destination, and receive a tailored quote from our team."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/carton-freight-services",
                                            "name": "Carton Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Carton Freight Services</h1>
                        {!isMobile && (
                            <p>
                                At QFM, we recognise the importance of precision and care when it comes to carton freight. Designed for lightweight and medium-sized goods, our carton logistics solutions ensure safe handling and timely delivery. Whether you’re shipping fragile items or high-volume goods, we tailor our services to meet your business’s exact needs while maintaining the highest standards of reliability.
                            </p>
                        )}
                        <p>
                            From Melbourne to Perth and beyond, our carton freight services cover Australia’s major cities and regional areas, including Sydney, Perth, Adelaide, Darwin, Canberra, and Hobart. We utilise road, rail, and air transport to provide versatile solutions that adapt to tight schedules and unique requirements. 

                        </p>
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-item-types-carton.jpg"
                            alt="Reliable carton freight services providing safe and efficient delivery across Melbourne, Brisbane, and Australia-wide"
                            title="Carton Freight Services - Expert logistics by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Customised carton freight solutions designed for secure and timely delivery</h5>
                        )}
                        <p>
                            Our carton freight services from Melbourne ensure smooth connectivity to major hubs like Sydney, Brisbane, Perth, and Adelaide, as well as regional areas throughout Australia. <Link to="/types-of-freight">Check out the full range of freight types we manage</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Carton Freight by QFM</h2>
                        <ul>
                            <li><b>Tailored Solutions:</b> Flexible options designed to handle lightweight and medium-sized carton shipments with care.</li>
                            <li><b>Reliability:</b> Consistent and dependable delivery services, ensuring your cartons arrive on time.</li>
                            <li><b>Secure Handling:</b> Precise packaging and handling to safeguard your cartons during transit.</li>
                            <li><b>Nationwide Reach:</b> Comprehensive coverage extending from Melbourne to all major Australian cities and regional areas.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-item-types-carton-two.jpg"
                            loading="lazy"
                            alt="Expert handling of carton freight services across Melbourne and Australia"
                            title="Carton Freight Services - Reliable Logistics by Quan Freight Management"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="carton-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>Which areas do your carton freight services cover?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    We provide carton freight services from Melbourne to major cities across Australia, including Sydney, Brisbane, Perth, and Adelaide. Our services also extend to regional areas and remote locations, ensuring comprehensive coverage nationwide.
                                    <br />
                                    No matter the destination, we strive for reliable and timely deliveries tailored to your logistics needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Can you handle fragile or high-value cartons?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, we specialise in managing fragile and high-value items. With customised handling and secure transit options, we ensure your goods are transported with care, from delicate electronics to valuable documents.
                                    <br />
                                    Speak with us about specific handling requirements, and we’ll tailor our approach to meet your needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What types of goods can be transported in cartons?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "250px" : "0px" }}
                            >
                                <p>
                                    Our carton freight services are versatile and can accommodate various goods, including retail items, packaged products, medical supplies, and office materials.
                                    <br />
                                    We can also cater to specialised freight requirements—please reach out to discuss your shipment.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>Are there size or weight limits for cartons?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    While we handle cartons of various sizes and weights, specific restrictions may apply depending on the transport mode or regulations. Contact our team with the dimensions and weight of your cartons, and we’ll recommend the best transport solution for your needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>What additional services are available for carton freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "270px" : "0px" }}
                            >
                                <p>
                                    In addition to standard freight services, we offer tailored solutions, including specific handling requirements, express delivery options, and support for bulk carton shipments. Our team works closely with you to design solutions that align with your business goals.
                                    <br />
                                    Contact us to explore how we can customise our services to fit your needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>How can I request a quote for carton freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "230px" : "0px" }}
                            >
                                <p>
                                    To request a quote, simply head over to our <Link to="/get-in-touch">Contact Us</Link> page. Share details like where the cartons need to be picked up and delivered, their dimensions, weight, and any special handling needs. Our experienced team will assess your requirements and get back to you with a personalised quote, crafted to suit your shipment.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Carton;


