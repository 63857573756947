import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Books() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Detect scrolling and show/hide scroll-up icon
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Detect window resize to set isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top of page (desktop only)
  useEffect(() => {
    if (isMobile) return; // skip for mobile
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // initial
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the 'goods-and-products' link in the nav
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Books Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM provides reliable freight solutions for books and publications across Melbourne, Sydney, Brisbane, and Australia-wide, ensuring safe and prompt deliveries to libraries, bookstores, and beyond."
        />
        <meta
          name="keywords"
          content="Books Freight Services, Bookstore Logistics, Library Delivery, Publisher Shipments, Secure Book Shipping, Paper Goods Transport, Melbourne Books Logistics, Australia-Wide Freight"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Books Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Expert freight services for books, magazines, and publications, serving libraries, retailers, and distributors across Australia."
        />
        <meta
          property="og:image"
          content="https://qfmlogistics.com.au/images/qfm-commodity-books.jpg"
        />
        <meta
          property="og:url"
          content="https://qfmlogistics.com.au/books-freight-services"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/books-freight-services" />

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/books-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/books-freight-services",
                "name": "Books Freight Services | Secure Transport by QFM",
                "description":
                  "Reliable shipping for books, magazines, and other print materials. Serving libraries, bookstores, and publishers nationwide.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": {
                  "@id": "https://qfmlogistics.com.au/books-freight-services#breadcrumb"
                }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/books-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Books Freight Services",
                    "item": "https://qfmlogistics.com.au/books-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/books-freight-services#service",
                "name": "Books Freight Services",
                "description":
                  "Professional and secure transport for books, magazines, and publications, ensuring on-time and safe delivery. Ideal for publishers, libraries, and bookstores across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-books.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-books-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your books freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl":
                  "https://qfmlogistics.com.au/images/qfm-commodity-books.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-books.jpg",
                "description":
                  "Secure and efficient book shipments for bookstores, libraries, and publishers throughout Australia.",
                "name": "Books Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage":
                  "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/books-freight-services#webpage"
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl":
                  "https://qfmlogistics.com.au/images/qfm-commodity-books-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-books-two.jpg",
                "description":
                  "Specialised transport solutions for large shipments of books, magazines, and printed media.",
                "name": "Books Freight - Professional Handling by QFM Logistics",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage":
                  "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/books-freight-services#webpage"
                }
              },
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/books-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "Which types of books can you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle all types of books, including paperbacks, hardcovers, textbooks, magazines, and limited-edition collectibles. Whether you’re a publisher, retailer, library, or educational institution, our freight services ensure secure and timely delivery of your book shipments across Australia."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide packaging for books?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We do not provide packaging services. To ensure your books are well-protected during transit, we recommend using sturdy boxes, reinforced corners, and protective wrapping. Our team can offer best-practice guidelines for preparing shipments for transport."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer express freight for urgent book shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide express shipping options for time-sensitive book deliveries. Whether you need last-minute restocking for a bookstore, urgent academic materials for universities, or limited-edition releases for pre-orders, we offer expedited services to meet tight deadlines."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Are there any size or weight limits?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We accommodate a range of shipment sizes, from small parcels containing a few books to full pallet loads for bulk distribution. If you have oversized or high-volume shipments, contact our team for a customised freight solution tailored to your needs."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you handle shipments for regional and remote areas?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we deliver to both metropolitan and regional locations across Australia. Our logistics network ensures that bookstores, libraries, and schools in remote areas receive shipments on time, helping businesses and institutions maintain a steady supply of reading materials."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can I insure my book shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We recommend arranging insurance for valuable or bulk book shipments to provide added protection in transit. While we take every precaution to ensure secure delivery, additional coverage offers peace of mind, especially for rare or high-value books."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for book freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To receive a personalised quote, visit our Contact Us page or call our support team. Provide details such as shipment volume, pickup and delivery locations, and preferred delivery timelines, and we’ll provide a competitive quote suited to your needs."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/books-freight-services#webpage"
                }
              }
            ]
          })}
        </script>

        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />

        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Books Freight Services</h1>
            
              <p>
                Whether you're a publisher distributing bestsellers, a bookstore replenishing inventory, or a library expanding its collection, QFM provides secure and efficient freight solutions tailored for books. Our specialised logistics network ensures careful handling to maintain the integrity of every shipment, from single editions to bulk orders.
              </p>
            {!isMobile && (
            <p>
              Serving Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas, we manage the transportation of books in all formats, safeguarding them against damage from moisture, pressure, and transit shifts. With flexible delivery schedules, express shipping options, and real-time tracking, we guarantee that your literary shipments reach their destination on time and in pristine condition.
            </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-books.jpg"
              alt="Secure shipping for books and publications across Australia"
              title="Books Freight Services - Safe, efficient, and nationwide by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Secure & Efficient Book Freight Solutions Across Australia</h5>
            )}
            <p>
              We provide reliable book freight services for libraries, bookstores, and educational institutions, ensuring timely and careful deliveries nationwide.
              <Link to="/goods-and-products"> Explore our full range of goods and products</Link>.
            </p>
          </div>
        </div>

        {/* Main Content with second image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Book Freight by QFM</h2>
            <ul>
              <li>
                <b>Careful Handling for Printed Materials:</b> Ensuring books, magazines, and educational resources are transported safely, free from creases, bends, or damage.
              </li>
              <li>
                <b>Dependable Distribution Network:</b> Providing secure freight solutions for bookstores, libraries, schools, and publishers with timely deliveries.
              </li>
              <li>
                <b>Flexible Shipping Solutions:</b> From one-time shipments to regular bulk deliveries, we tailor our freight services to meet your specific needs.
              </li>
              <li>
                <b>Australia-Wide Reach:</b> Delivering to major cities and regional areas, including Melbourne, Sydney, Brisbane, Perth, and Adelaide.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-books-two.jpg"
              loading="lazy"
              alt="Efficient transport for larger consignments of books and publications across Melbourne and Australia"
              title="Books Freight Services - Professional and Secure Logistics by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="crate-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div
              className={`faq-item ${openFAQ === 0 ? 'active' : ''}`}
              onClick={() => toggleFAQ(0)}
            >
              <h5>Which types of books can you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We handle all types of books, including paperbacks, hardcovers, textbooks, magazines, and limited-edition collectibles. Whether you’re a publisher, retailer, library, or educational institution, our freight services ensure secure and timely delivery of your book shipments across Australia.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div
              className={`faq-item ${openFAQ === 1 ? 'active' : ''}`}
              onClick={() => toggleFAQ(1)}
            >
              <h5>Do you provide packaging for books?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
                <p>
                  We do not provide packaging services. To ensure your books are well-protected during transit, we recommend using sturdy boxes, reinforced corners, and protective wrapping. Our team can offer best-practice guidelines for preparing shipments for transport.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div
              className={`faq-item ${openFAQ === 2 ? 'active' : ''}`}
              onClick={() => toggleFAQ(2)}
            >
              <h5>Do you offer express freight for urgent book shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '270px' : '0px' }}>
                <p>
                  Yes, we provide express shipping options for time-sensitive book deliveries. Whether you need last-minute restocking for a bookstore, urgent academic materials for universities, or limited-edition releases for pre-orders, we offer expedited services to meet tight deadlines.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div
              className={`faq-item ${openFAQ === 3 ? 'active' : ''}`}
              onClick={() => toggleFAQ(3)}
            >
              <h5>Are there any size or weight limits?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '250px' : '0px' }}>
                <p>
                  We accommodate a range of shipment sizes, from small parcels containing a few books to full pallet loads for bulk distribution. If you have oversized or high-volume shipments, contact our team for a customised freight solution tailored to your needs.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div
              className={`faq-item ${openFAQ === 4 ? 'active' : ''}`}
              onClick={() => toggleFAQ(4)}
            >
              <h5>Do you handle shipments for regional and remote areas?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '250px' : '0px' }}>
                <p>
                  Yes, we deliver to both metropolitan and regional locations across Australia. Our logistics network ensures that bookstores, libraries, and schools in remote areas receive shipments on time, helping businesses and institutions maintain a steady supply of reading materials.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div
              className={`faq-item ${openFAQ === 5 ? 'active' : ''}`}
              onClick={() => toggleFAQ(5)}
            >
              <h5>Can I insure my book shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '200px' : '0px' }}>
                <p>
                  We recommend arranging insurance for valuable or bulk book shipments to provide added protection in transit. While we take every precaution to ensure secure delivery, additional coverage offers peace of mind, especially for rare or high-value books.
                </p>
              </div>
            </div>

            {/* FAQ 7 */}
            <div
              className={`faq-item ${openFAQ === 6 ? 'active' : ''}`}
              onClick={() => toggleFAQ(6)}
            >
              <h5>How can I request a quote for book freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 6 ? '300px' : '0px' }}>
                <p>
                  To receive a personalised quote, visit our <Link to="/get-in-touch">Contact Us</Link> page or call our support team. Provide details such as shipment volume, pickup and delivery locations, and preferred delivery timelines, and we’ll provide a competitive quote suited to your needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Books;