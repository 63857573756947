import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Storage() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding the scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" navigation link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Storage Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM provides secure storage freight services for transporting goods destined for warehouses, distribution centres, and long-term storage facilities. Our tailored logistics ensure safe handling and timely delivery across Australia."
        />
        <meta
          name="keywords"
          content="Storage Freight Services, Warehouse Transport, Distribution Centre Logistics, Secure Storage Freight, Australia Freight, Warehouse Shipping, QFM Storage Logistics"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Storage Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure storage freight services for transporting goods to warehouses and storage facilities. Trust QFM for reliable, tailored logistics solutions across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-storage.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/storage-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/storage-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/storage-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/storage-freight-services",
                "name": "Storage Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised storage freight services for transporting goods destined for warehouses, distribution centres, and long-term storage facilities. Our secure logistics solutions ensure safe handling and timely delivery across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/storage-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/storage-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Storage Freight Services",
                    "item": "https://qfmlogistics.com.au/storage-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/storage-freight-services#service",
                "name": "Storage Freight Services",
                "description": "Our storage freight services provide secure transport solutions for goods destined for warehouses, distribution centres, and long-term storage facilities. We customise our logistics to meet the unique requirements of each shipment, ensuring safety and reliability throughout the journey.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-storage.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-storage-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your storage freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-storage.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-storage.jpg",
                "description": "Robust transport solutions for goods destined for storage, ensuring secure handling and minimal risk during transit.",
                "name": "Storage Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/storage-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "ImageObject",
                "@id": "https://qfmlogistics.com.au/images/qfm-commodity-storage-two.jpg",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-storage-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-storage-two.jpg",
                "description": "Secure and efficient freight solutions for goods destined for storage facilities.",
                "name": "Reliable Storage Freight Services - Seamless Logistics by Quan Freight Management",
                "author": {
                  "@type": "Organization",
                  "name": "Quan Freight Management",
                  "url": "https://qfmlogistics.com.au"
                },
                "license": {
                  "@type": "CreativeWork",
                  "url": "https://qfmlogistics.com.au/terms-and-conditions"
                },
                "creator": {
                  "@type": "Organization",
                  "name": "Quan Freight Management"
                },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/storage-freight-services#webpage"
                }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/storage-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of goods can you transport to storage facilities?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle a wide range of goods for storage, including bulk inventory, retail stock, industrial equipment, raw materials, and non-perishable consumer goods. Whether you're restocking a distribution centre or moving excess stock, we provide secure and efficient transport."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer long-term storage solutions?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "While QFM specialises in freight transport, we work closely with trusted storage partners to assist with long-term warehousing solutions. Contact us to discuss your storage requirements, and we can help coordinate the best solution for your business."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you handle large-scale inventory transfers?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we specialise in bulk freight transport, including palletised shipments and high-volume inventory transfers. Whether you're moving stock between warehouses or consolidating goods for distribution, our logistics network ensures a smooth process."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide tracking for storage freight shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, all storage freight shipments come with real-time tracking, allowing you to monitor progress from pickup to delivery. Our system provides full transparency, ensuring you know exactly when your goods arrive at their storage facility."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which areas do you service for storage freight transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide storage freight transport across Australia, covering major cities such as Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote locations. No matter your storage facility's location, we can deliver efficiently."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for storage freight transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You can request a quote by visiting our Contact Us page at https://qfmlogistics.com.au/get-in-touch. Provide details about your shipment, including pickup and delivery locations, freight volume, and any special handling requirements, and our team will provide a tailored quote."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/storage-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Storage Freight Services</h1>
            {!isMobile && (
            <p>
              Our storage freight services provide reliable and efficient transport for goods destined for warehouses, distribution centres, and long-term storage facilities. We specialise in handling a diverse range of items, including consumer products, industrial materials, and bulk inventory, ensuring they arrive securely and in optimal condition.

            </p>
            )}
            <p>
              Whether you're managing excess stock, seasonal goods, or large-scale storage needs, we offer secure transport solutions tailored to your business requirements.
              Our network spans Australia, delivering to both metropolitan hubs and remote storage facilities with precision and reliability.
            </p>
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-storage-two.jpg"
              loading="lazy"
              alt="Secure and efficient freight solutions for goods destined for storage facilities"
              title="Reliable Storage Freight Services - Seamless Logistics by Quan Freight Management"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Efficient Storage Freight Solutions for Seamless Inventory Management</h5>
            )}
            <p>
              Our specialised freight services ensure the safe and efficient transport of goods to storage facilities, warehouses, and distribution centres. <Link to="/goods-and-products">Explore our full range of freight solutions</Link>.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Storage Freight by QFM</h2>
            <ul>
              <li>
                <b>Secure Warehouse Transport:</b> Reliable freight solutions designed to move goods safely to storage facilities, ensuring inventory protection during transit.
              </li>
              <li>
                <b>Bulk & Palletised Shipments:</b> Efficient handling of large-scale inventory, from individual cartons to full pallet loads, tailored to storage and warehousing needs.
              </li>
              <li>
                <b>Seamless Supply Chain Integration:</b> Coordinated logistics that streamline inventory movement between suppliers, warehouses, and distribution centres.
              </li>
              <li>
                <b>Nationwide Storage Network:</b> Delivering to warehouses across Australia, including metropolitan hubs and remote locations, ensuring timely and reliable service.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-storage-two.jpg"
              loading="lazy"
              alt="Professional freight for warehouse-bound goods ensuring secure delivery"
              title="Storage Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>Frequently Asked Questions</h2>

            <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of goods can you transport to storage facilities?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                <p>
                  We handle a wide range of goods for storage, including bulk inventory, retail stock, industrial equipment, raw materials, and non-perishable consumer goods. Whether you're restocking a distribution centre or moving excess stock, we provide secure and efficient transport.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you offer long-term storage solutions?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "280px" : "0px" }}>
                <p>
                  While QFM specialises in freight transport, we work closely with trusted storage partners to assist with long-term warehousing solutions. Contact us to discuss your storage requirements, and we can help coordinate the best solution for your business.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
              <h5>Can you handle large-scale inventory transfers?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "280px" : "0px" }}>
                <p>
                  Yes, we specialise in bulk freight transport, including palletised shipments and high-volume inventory transfers. Whether you're moving stock between warehouses or consolidating goods for distribution, our logistics network ensures a smooth process.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
              <h5>Do you provide tracking for storage freight shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "280px" : "0px" }}>
                <p>
                  Yes, all storage freight shipments come with real-time tracking, allowing you to monitor progress from pickup to delivery. Our system provides full transparency, ensuring you know exactly when your goods arrive at their storage facility.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
              <h5>Which areas do you service for storage freight transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "260px" : "0px" }}>
                <p>
                  We provide storage freight transport across Australia, covering major cities such as Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote locations. No matter your storage facility's location, we can deliver efficiently.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
              <h5>How can I request a quote for storage freight transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? "260px" : "0px" }}>
                <p>
                  You can request a quote by visiting our <Link to="/get-in-touch">Contact Us</Link> page. Provide details about your shipment, including pickup and delivery locations, freight volume, and any special handling requirements, and our team will provide a tailored quote.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Storage;