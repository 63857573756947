import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Bricks() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Detect scrolling and show/hide scroll-up icon
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Detect window resize to set isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top of page (desktop only)
  useEffect(() => {
    if (isMobile) return; // skip for mobile
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // initial
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the 'goods-and-products' link in the nav
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Bricks Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM provides dedicated freight services for bricks and construction materials, ensuring secure and timely delivery across Melbourne, Sydney, Brisbane, and Australia-wide."
        />
        <meta
          name="keywords"
          content="Bricks Freight Services, Construction Materials Transport, Building Supplies Logistics, Secure Brick Shipping, Road Freight, Bulk Freight, Australia Construction Supply"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Bricks Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Reliable freight services for bricks and building materials, covering major Australian cities and regional areas."
        />
        <meta
          property="og:image"
          content="https://qfmlogistics.com.au/images/qfm-commodity-bricks.jpg"
        />
        <meta
          property="og:url"
          content="https://qfmlogistics.com.au/bricks-freight-services"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/bricks-freight-services" />

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/bricks-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/bricks-freight-services",
                "name": "Bricks Freight Services | Secure Transport by QFM",
                "description":
                  "Specialised logistics solutions for bricks and other heavy construction materials. Reliable deliveries across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": {
                  "@id": "https://qfmlogistics.com.au/bricks-freight-services#breadcrumb"
                }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/bricks-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Bricks Freight Services",
                    "item": "https://qfmlogistics.com.au/bricks-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/bricks-freight-services#service",
                "name": "Bricks Freight Services",
                "description":
                  "Expert transport for bricks, concrete blocks, and other heavy building materials. Ensuring prompt, secure deliveries across Melbourne, Sydney, Brisbane, Perth, and beyond.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-bricks.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-bricks-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your bricks freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl":
                  "https://qfmlogistics.com.au/images/qfm-commodity-bricks.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-bricks.jpg",
                "description":
                  "Secure transport solutions for bricks and construction materials across Australia.",
                "name": "Bricks Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage":
                  "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/bricks-freight-services#webpage"
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl":
                  "https://qfmlogistics.com.au/images/qfm-commodity-bricks-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-bricks-two.jpg",
                "description":
                  "Reliable freight for bricks, concrete blocks, and other heavy goods serving the construction industry.",
                "name": "Bricks Freight - Professional Handling by QFM Logistics",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage":
                  "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/bricks-freight-services#webpage"
                }
              },
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "@id": "https://www.qfmlogistics.com.au/bricks-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "Which building materials do you handle besides bricks?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "While our primary focus is transporting bricks, we also handle other masonry and construction materials, including concrete blocks, pavers, and stone products. Our freight solutions are designed to accommodate a variety of building supplies, ensuring secure and efficient delivery to construction sites."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you deliver bricks to remote or regional worksites?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide freight services to both metro and regional areas, ensuring construction sites in remote locations receive materials without unnecessary delays. Whether your project is in a rural town or a hard-to-reach job site, our logistics team will coordinate the best transport solutions for your needs."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide any packaging for bricks?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "No, we do not provide packaging. Bricks and masonry must be bundled or palletised before collection to ensure safe transport. If you're unsure about proper packaging, we’re happy to offer guidance on securing loads for transit."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Are there specific weight limits or quantity restrictions?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We accommodate shipments of all sizes, from small batches to full truckloads. The maximum allowable weight depends on transport regulations and vehicle capacity. If you have large or oversized freight, contact us for a customised logistics plan that meets your requirements."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer expedited delivery for urgent construction projects?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we understand that construction timelines can be tight, which is why we offer priority freight options for urgent shipments. Whether you need same-day or next-day delivery, our team will work to get your materials on-site as quickly as possible."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for bricks freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Requesting a quote is easy! Simply visit our Contact Us page or call our support team. To provide an accurate estimate, we’ll need details such as the pickup and delivery locations, the type and quantity of bricks, packaging method (palletised or bundled), and any special handling requirements. Our team will respond with a competitive freight solution tailored to your needs."
                    }
                  }
                ],
                "isPartOf": {
                  "@type": "Service",
                  "@id": "https://www.qfmlogistics.com.au/bricks-freight-services",
                  "name": "Bricks Freight Services",
                  "url": "https://www.qfmlogistics.com.au/bricks-freight-services"
                }
              }
            ]
          })}
        </script>

        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />

        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Bricks Freight Services</h1>
            
              <p>
                Moving bricks and masonry demands careful planning and reliable logistics to manage their weight and bulk effectively. QFM offers secure and efficient freight solutions, ensuring construction materials are transported safely and arrive on time, minimising delays and keeping projects on schedule.
              </p>
            {!isMobile && (
            <p>
              With a strong logistics network spanning Melbourne, Sydney, Brisbane, Perth, and regional construction zones, we specialise in heavy material transport. Our advanced load management techniques, dedicated freight solutions, and real-time tracking help reduce site delays and streamline supply chain operations, ensuring maximum efficiency.
            </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-bricks.jpg"
              alt="Professional bricks freight transport for construction materials across Australia"
              title="Bricks Freight Services - Reliable and efficient logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Reliable Freight Solutions for Bricks & Masonry</h5>
            )}
            <p>
              Our specialised bricks freight services ensure safe, efficient, and on-time delivery for construction sites, suppliers, and developers nationwide.
              <Link to="/goods-and-products"> Explore our full range of freight solutions</Link>.
            </p>
          </div>
        </div>

        {/* Main Content with second image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Bricks Freight by QFM</h2>
            <ul>
              <li>
                <b>Reliable Brick & Masonry Freight:</b> Safe transport of bricks, pavers, concrete blocks, and masonry materials with expert load management to prevent damage.
              </li>
              <li>
                <b>Heavy-Duty Freight Solutions:</b> Secure transport of bulk shipments using reinforced pallets, load-securing techniques, and high-capacity freight vehicles.
              </li>
              <li>
                <b>Versatile Transport Methods:</b> Offering road, rail, and intermodal logistics to provide efficient and cost-effective freight solutions tailored to construction demands.
              </li>
              <li>
                <b>Nationwide Construction Freight Services:</b> Delivering bricks and masonry supplies to job sites across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-bricks-two.jpg"
              loading="lazy"
              alt="Freight solutions for bricks and other heavy construction supplies across Melbourne and Australia"
              title="Bricks Freight Services - Secure and Reliable Logistics by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>Which building materials do you handle besides bricks?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  While our primary focus is transporting bricks, we also handle other masonry and construction materials, including concrete blocks, pavers, and stone products. Our freight solutions are designed to accommodate a variety of building supplies, ensuring secure and efficient delivery to construction sites.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>Can you deliver bricks to remote or regional worksites?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
                <p>
                  Yes. We provide freight services to both metro and regional areas, ensuring construction sites in remote locations receive materials without unnecessary delays. Whether your project is in a rural town or a hard-to-reach job site, our logistics team will coordinate the best transport solutions for your needs.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Do you provide any packaging for bricks?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '240px' : '0px' }}>
                <p>
                  No, we do not provide packaging. Bricks and masonry must be bundled or palletised before collection to ensure safe transport. If you're unsure about proper packaging, we’re happy to offer guidance on securing loads for transit.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Are there specific weight limits or quantity restrictions?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '250px' : '0px' }}>
                <p>
                  We accommodate shipments of all sizes, from small batches to full truckloads. The maximum allowable weight depends on transport regulations and vehicle capacity. If you have large or oversized freight, contact us for a customised logistics plan that meets your requirements.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>Do you offer expedited delivery for urgent construction projects?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '270px' : '0px' }}>
                <p>
                  Yes. We understand that construction timelines can be tight, which is why we offer priority freight options for urgent shipments. Whether you need a same-day or next-day delivery, our team will work to get your materials on-site as quickly as possible.
                </p>
              </div>
            </div>

            {/* FAQ 6 - Quote Request */}
            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>How can I request a quote for bricks freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '250px' : '0px' }}>
                <p>
                  Requesting a quote is easy! Simply visit our <Link to="/get-in-touch">Contact Us</Link> page or call our support team. To provide an accurate estimate, we’ll need details such as the pickup and delivery locations, the type and quantity of bricks, packaging method (palletised or bundled), and any special handling requirements. Our team will respond with a competitive freight solution tailored to your needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Bricks;