import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function ProjectFreight() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/our-services"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Project Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management specialises in tailored project freight services across Melbourne and Australia-wide. From large-scale equipment to complex oversized loads, we provide reliable and efficient logistics solutions to meet your project needs." />
                <meta name="keywords" content="Project Freight Services, Quan Freight Management, QFM Logistics, Melbourne Project Freight, Oversized Loads Transport, Equipment Logistics, Freight Solutions, Reliable Project Logistics, Heavy Haulage, Specialised Freight Services, Complex Freight Transport, Cost-Effective Freight Management, Melbourne Logistics Solutions" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Project Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's specialised project freight services. We manage large-scale and oversized freight across Melbourne and Australia-wide, providing efficient and reliable solutions tailored to your unique project needs." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-services-project-freight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/project-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/project-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/project-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/project-freight-services",
                                "name": "Project Freight Services | Logistics Solutions by QFM",
                                "description": "Quan Freight Management specialises in end-to-end project freight services, offering tailored logistics solutions for complex and large-scale projects in Melbourne and across Australia.",
                                "inLanguage": "en-AU",
                                "breadcrumb": {
                                    "@id": "https://qfmlogistics.com.au/project-freight-services#breadcrumb"
                                },
                                "publisher": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                }
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/project-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Our Services",
                                        "item": "https://qfmlogistics.com.au/our-services"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Project Freight Services",
                                        "item": "https://qfmlogistics.com.au/project-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/project-freight-services#service",
                                "name": "Project Freight Services",
                                "description": "Comprehensive project freight logistics tailored to manage large-scale and specialised requirements across Australia.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Project Freight",
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-services-project-freight.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-project-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-project-freight.jpg",
                                "description": "End-to-end project freight solutions tailored for Melbourne and nationwide logistics requirements.",
                                "name": "Project Freight Services - Tailored Logistics by QFM",
                                "author": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "license": {
                                    "@id": "https://qfmlogistics.com.au#license"
                                },
                                "creator": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-project-freight-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-project-freight-two.jpg",
                                "description": "Comprehensive project freight logistics for large-scale and specialised projects across Australia.",
                                "name": "Reliable Project Freight Solutions - QFM Logistics",
                                "author": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "license": {
                                    "@id": "https://qfmlogistics.com.au#license"
                                },
                                "creator": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/project-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What types of projects does QFM handle?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "QFM manages a variety of projects, including construction logistics, event equipment transportation, and large-scale industrial shipments. Our tailored solutions adapt to your project's unique requirements."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/project-freight-services",
                                            "name": "Project Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide end-to-end logistics for projects?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, our Project Freight services include end-to-end logistics, from planning and route optimisation to final delivery, ensuring seamless execution at every stage."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/project-freight-services",
                                            "name": "Project Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can QFM handle oversized or heavy shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Absolutely. We specialise in transporting oversized and heavy shipments using specialised equipment and experienced carriers for safe handling."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/project-freight-services",
                                            "name": "Project Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you offer customised project logistics solutions?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we collaborate with clients to design customised logistics solutions that align with project timelines and objectives."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/project-freight-services",
                                            "name": "Project Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What industries can benefit from QFM’s project freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our services cater to industries like construction, mining, events, manufacturing, and energy, ensuring efficient execution of complex supply chains."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/project-freight-services",
                                            "name": "Project Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I get a quote for my project freight needs?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page and provide details about your project, including freight type, volume, and destination. Our team will respond promptly with a tailored quote."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/project-freight-services",
                                            "name": "Project Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]

                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Project Freight Services</h1>
                        {!isMobile && (
                            <p>
                                QFM offers specialised Project Logistics services designed to handle the complex and challenging demands of large-scale projects. From the initial planning phase to the final delivery, we provide end-to-end logistics solutions tailored to meet the specific requirements of your project.
                            </p>
                        )}

                        <p>
                            Our expertise extends to transporting oversized loads, heavy machinery, and time-critical equipment across Melbourne and Australia-wide. With meticulous planning, advanced tracking technology, and a dedicated team, we ensure every aspect of your project freight is executed seamlessly, safely, and on schedule.
                        </p>
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-services-project-freight.jpg"
                            loading="lazy"
                            alt="Comprehensive project freight solutions for complex and large-scale logistics demands across Melbourne and Australia"
                            title="Project Freight Services – Expert Large-Scale Logistics by QFM"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Comprehensive and reliable project freight solutions designed for complex logistics demands</h5>
                        )}
                        <p>
                            QFM's project freight services deliver seamless logistics support for large-scale and challenging projects, ensuring precision from start to finish. <Link to="/our-services">View all our services</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect appearing-effect">
                    <div className="default-section-content-left-text-content sydney-add">
                        <h2>Melbourne Project Freight by QFM</h2>
                        <ul>
                            <li><b>Comprehensive Planning:</b> Detailed logistics planning to coordinate all aspects of your project, including route surveys, risk assessments, and compliance with local regulations.</li>
                            <li><b>Specialized Equipment:</b> Access to a wide range of specialized equipment and vehicles, including heavy-lift cranes, low loaders, and multi-axle trailers, to handle oversized or heavy cargo.</li>
                            <li><b>End-to-End Management:</b> Comprehensive management of the entire logistics process, from procurement and transportation to on-site delivery and installation.</li>
                            <li><b>Safety & Compliance:</b> Strict adherence to safety standards and regulatory compliance.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container">
                        <img
                            src="images/qfm-services-project-freight-two.jpg"
                            loading="lazy"
                            alt="Comprehensive project freight solutions managing large-scale logistics with precision and efficiency"
                            title="Project Freight Services – Expert Solutions for Complex Logistics by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                                <Link to="/our-services"><h4>View all Services</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="project-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What types of projects does QFM handle?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    QFM specialises in managing a wide range of projects, including construction logistics, event equipment transportation, large-scale industrial shipments, and customised project-specific freight. Our solutions are designed to adapt to the unique requirements of your project, ensuring seamless execution from start to finish.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Do you provide end-to-end logistics for projects?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "300px" : "0px" }}
                            >
                                <p>
                                    Yes, our Project Freight services include comprehensive end-to-end logistics. From initial planning and route optimisation to transportation, unloading, and final delivery, our team handles every stage with precision and expertise.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>Can QFM handle oversized or heavy shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "300px" : "0px" }}
                            >
                                <p>
                                    Absolutely. We specialise in handling oversized, heavy, and high-value shipments. With access to specialised equipment and a network of experienced carriers, we ensure the safe and efficient transport of your project's unique freight requirements.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>Do you offer customised project logistics solutions?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "300px" : "0px" }}
                            >
                                <p>
                                    Yes, our logistics services are fully customisable to meet the specific demands of your project. From tailored transport routes to specialised handling requirements, we collaborate with you to design solutions that align with your objectives and timelines.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>What industries can benefit from QFM’s project freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our Project Freight services cater to a diverse range of industries, including construction, mining, events, manufacturing, and energy. We have experience working with complex supply chains, ensuring that your project logistics are executed efficiently and professionally.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>How can I get a quote for my project freight needs?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "300px" : "0px" }}
                            >
                                <p>
                                    To request a quote, simply visit our <Link to="/get-in-touch">Contact Us</Link> page. Provide details about your project, including freight type, volume, and destination. Our expert team will assess your requirements and provide a tailored quote promptly.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default ProjectFreight;