import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Automotive() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
    const [openFAQ, setOpenFAQ] = useState(null);

    // Detect scrolling and show/hide scroll-up icon
    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Scroll-to-top function
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Detect window resize to set isMobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Hide scrollbar at top of the page (desktop only)
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    // Show floating scroll-up button on desktop
    useEffect(() => {
        if (isMobile) return;
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    // Intersection Observer for fade-in effects
    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Highlight the 'goods-and-products' link in the nav
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/goods-and-products"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);

    // FAQ toggle
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Automotive Freight Services | Logistics Solutions by QFM</title>
                <meta
                    name="description"
                    content="QFM provides specialised logistics solutions for automotive parts and equipment, ensuring secure transport across Melbourne, Sydney, Brisbane, and Australia-wide."
                />
                <meta
                    name="keywords"
                    content="Automotive Freight Services, Car Parts Transport, Engine Shipping, Automotive Logistics, Vehicle Components Freight, Secure Automotive Handling, Australia-wide Car Freight, Melbourne Automotive Logistics"
                />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Automotive Freight Services | Logistics Solutions by QFM" />
                <meta
                    property="og:description"
                    content="Expert freight solutions for automotive parts, from engines and transmissions to delicate components. Nation-wide coverage for workshops, manufacturers, and dealerships."
                />
                <meta
                    property="og:image"
                    content="https://qfmlogistics.com.au/images/qfm-commodity-automotive.jpg"
                />
                <meta
                    property="og:url"
                    content="https://qfmlogistics.com.au/automotive-freight-services"
                />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link
                    rel="canonical"
                    href="https://qfmlogistics.com.au/automotive-freight-services"
                />

                {/* Structured Data (JSON-LD) */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/automotive-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/automotive-freight-services",
                                "name": "Automotive Freight Services | Secure Transport by QFM",
                                "description":
                                    "Specialised transport solutions for automotive parts, engines, and accessories. Trusted logistics provider across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": {
                                    "@id": "https://qfmlogistics.com.au/automotive-freight-services#breadcrumb"
                                }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/automotive-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Goods and Products",
                                        "item": "https://qfmlogistics.com.au/goods-and-products"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Automotive Freight Services",
                                        "item": "https://qfmlogistics.com.au/automotive-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/automotive-freight-services#service",
                                "name": "Automotive Freight Services",
                                "description":
                                    "Professional and secure transport for automotive parts, from engines and transmissions to panels and accessories. Reliable solutions across Melbourne, Sydney, Brisbane, and regional Australia.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": [
                                    "https://qfmlogistics.com.au/images/qfm-commodity-automotive.jpg",
                                    "https://qfmlogistics.com.au/images/qfm-commodity-automotive-two.jpg"
                                ],
                                "potentialAction": {
                                    "@type": "Action",
                                    "name": "Request a Quote",
                                    "url": "https://qfmlogistics.com.au/get-in-touch",
                                    "description": "Get a custom quote for your automotive freight needs."
                                }
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl":
                                    "https://qfmlogistics.com.au/images/qfm-commodity-automotive.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-commodity-automotive.jpg",
                                "description":
                                    "Transporting automotive parts, engines, and accessories across Australia with secure, reliable logistics.",
                                "name": "Automotive Freight Services - Secure Transport by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage":
                                    "https://qfmlogistics.com.au/terms-and-conditions",
                                "isPartOf": {
                                    "@id": "https://qfmlogistics.com.au/automotive-freight-services#webpage"
                                }
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl":
                                    "https://qfmlogistics.com.au/images/qfm-commodity-automotive-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-commodity-automotive-two.jpg",
                                "description":
                                    "Specialised logistics for large automotive components including transmissions, engines, and chassis.",
                                "name": "Professional Automotive Freight Handling - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage":
                                    "https://qfmlogistics.com.au/terms-and-conditions",
                                "isPartOf": {
                                    "@id": "https://qfmlogistics.com.au/automotive-freight-services#webpage"
                                }
                            },


                            {
                                "@context": "https://schema.org",
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/automotive-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Which automotive parts can you transport?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We transport a wide range of automotive parts, including engines, transmissions, body panels, tyres, exhaust systems, and suspension components. Our freight solutions handle both standard and oversized items with care, ensuring safe and timely delivery."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you offer express shipping for urgent automotive deliveries?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer express freight services for urgent shipments. Whether you need a critical part for a repair, an urgent delivery for a dealership, or parts for a motorsport event, we provide fast and reliable solutions with real-time tracking."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are there restrictions on automotive fluids or hazardous materials?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, hazardous materials such as automotive oils, batteries, and flammable liquids are subject to transport regulations. We can arrange compliant freight solutions for certain items. Please contact our team with specific details for guidance."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How do I package automotive parts for safe transit?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Proper packaging is essential to prevent damage. We recommend using sturdy, cushioned boxes or wooden crates, securing loose components, and draining fluids from mechanical parts if needed. Please note that QFM does not provide packaging services but can advise on best practices."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Which regions do you service?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We provide automotive freight solutions across Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional areas. Whether you're shipping locally or interstate, we ensure smooth and efficient transport for your automotive parts."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I get a quote for automotive freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Getting a quote is simple! Visit our Contact Us page or call our team directly. Provide details such as pickup and delivery locations, the type of automotive parts, dimensions, weight, and any special handling requirements. Our team will tailor a quote to suit your freight needs."
                                        }
                                    }
                                ],
                                "isPartOf": {
                                    "@type": "Service",
                                    "name": "Automotive Freight Services",
                                    "url": "https://www.qfmlogistics.com.au/automotive-freight-services"
                                }
                            }
                        ]
                    })}
                </script>

                {/* Google Analytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
                </script>
            </Helmet>

            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />

                {!isMobile && <div className="vertical-line"></div>}

                {/* Intro Section */}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Automotive Freight Services</h1>
                        
                            <p>
                                Transporting automotive parts demands precision and secure logistics. QFM provides reliable freight solutions for engines, transmissions, chassis components, and specialised vehicle parts. Whether delivering to dealerships, workshops, or large-scale manufacturers, we ensure safe and efficient transport, minimising delays and protecting valuable inventory.
                            </p>
                        {!isMobile && (
                        <p>
                            From performance car parts to commercial vehicle spares, our tailored freight solutions cover Melbourne, Sydney, Brisbane, Perth, and beyond. We provide express transport, reliable handling, and real-time tracking for a seamless logistics experience—ensuring your automotive goods reach their destination securely and on schedule.
                        </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-commodity-automotive.jpg"
                            alt="Secure automotive freight transport for engines, parts, and accessories across Australia"
                            title="Automotive Freight Services - Reliable transport for car components by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>

                {/* Quote/Call-to-Action Section */}
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch">
                            <h4>Request a Quote</h4>
                        </Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Reliable Freight Solutions for Automotive Parts</h5>
                        )}
                        <p>
                            We specialise in the secure transport of automotive components, ensuring everything from engine parts to performance upgrades reaches its destination safely and on time.
                            <Link to="/goods-and-products"> Discover more about our freight capabilities</Link>.
                        </p>
                    </div>
                </div>

                {/* Main Content with second image */}
                <div className="default-section-content-left">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Automotive Freight by QFM</h2>
                        <ul>
                            <li>
                                <b>Secure Freight for Automotive Parts:</b> From engines and transmissions to precision sensors and body panels, we provide safe, damage-free transport tailored to industry requirements.
                            </li>
                            <li>
                                <b>Fast & Dependable Delivery:</b> Whether shipping urgent repair parts or bulk inventory for dealerships, our streamlined logistics ensure prompt arrivals with real-time tracking.
                            </li>
                            <li>
                                <b>Nationwide Reach & Reliable Transport:</b> Connecting manufacturers, suppliers, and workshops across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas with seamless freight solutions.
                            </li>
                            <li>
                                <b>Flexible Freight Services:</b> Offering express shipping, scheduled deliveries, and heavy-duty transport to accommodate the diverse needs of the automotive sector.
                            </li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-commodity-automotive-two.jpg"
                            loading="lazy"
                            alt="Specialised transport for automotive parts including engines, transmissions, and body panels across Melbourne and Australia"
                            title="Automotive Freight Services - Secure and Reliable Logistics by Quan Freight Management"
                        />
                        {isMobile && (
                            <div className="default-quote appearing-effect">
                                <div className="default-quote-quote">
                                    <Link to="/goods-and-products">
                                        <h4>View all Commodities</h4>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* FAQ Section */}
                <div className="express-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>Which automotive parts can you transport?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    We transport a wide range of automotive parts, including engines, transmissions, body panels, tyres, exhaust systems, and suspension components. Our freight solutions are designed to handle both standard and oversized items with the utmost care, ensuring safe and timely delivery.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Do you offer express shipping for urgent automotive deliveries?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "260px" : "0px" }}
                            >
                                <p>
                                    Yes, we offer express freight services for urgent automotive shipments. Whether you need a critical part for a repair, an urgent delivery for a dealership, or parts for an upcoming motorsport event, we provide fast and reliable solutions with real-time tracking for peace of mind.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>Are there restrictions on automotive fluids or hazardous materials?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "280px" : "0px" }}
                            >
                                <p>
                                    Yes, hazardous materials such as automotive oils, batteries, and flammable liquids are subject to strict transport regulations. While we can arrange compliant freight solutions for certain items, please reach out to our team with specific details so we can advise on the best course of action.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How do I package automotive parts for safe transit?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "280px" : "0px" }}
                            >
                                <p>
                                    Proper packaging is essential for preventing damage during transit. We recommend using sturdy, well-cushioned boxes or wooden crates, securing loose components, and draining any fluids from mechanical parts when necessary. Please note that QFM does not provide packaging services, but we’re happy to advise on best practices to ensure your items are safely prepared for transport.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Which regions do you service?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "240px" : "0px" }}
                            >
                                <p>
                                    We provide automotive freight solutions across all major Australian cities, including Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional areas. Whether you're shipping locally or interstate, we ensure smooth and efficient transport for your automotive parts.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>How can I get a quote for automotive freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "230px" : "0px" }}
                            >
                                <p>
                                    Getting a quote is simple! Visit our <Link to="/get-in-touch">Contact Us</Link> page or call our team directly. To provide an accurate estimate, we'll need details such as the pickup and delivery locations, the type of automotive parts being shipped, their dimensions and weight, and any special handling requirements. Our team will then tailor a quote to suit your specific freight needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Scroll Up Buttons */}
                {isVisible && (
                    <div className="scroll-up" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}

                <Footer />
            </div>
        </div>
    );
}

export default Automotive;