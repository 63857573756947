import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/OurServices.css';
import { Link } from 'react-router-dom';

function ItemTypes() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.05 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="ourservices-main-content">
            <Helmet>
                <title>Item Types | Logistics Solutions by QFM</title>
                <meta name="description" content="Discover QFM's comprehensive freight item types. We transport pallets, cartons, crates, satchels, and oversized items like containers and stillages. Reliable logistics services across Melbourne and all major Australian cities." />
                <meta name="keywords" content="freight item types, pallets, cartons, crates, satchels, envelopes, drums, IBCs, panels, stillages, semi-trailers, B-Doubles, containers, logistics solutions, Quan Freight Management" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Item Types | Logistics Solutions by QFM" />
                <meta property="og:description" content="Explore QFM's freight services for various item types, including pallets, cartons, crates, satchels, and more. Reliable logistics across Australia." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types.jpg" />
                <meta property="og:image:alt" content="Different freight item types by QFM Logistics" />
                <meta property="og:url" content="https://qfmlogistics.com.au/types-of-freight" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/types-of-freight" />
                {/* Need to udpate the JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/item-types#webpage",
                                "url": "https://qfmlogistics.com.au/item-types",
                                "name": "Freight Item Types | Logistics Solutions by QFM",
                                "description": "Explore tailored logistics solutions for various freight types including pallets, cartons, and oversized goods. Discover Quan Freight Management's expertise in reliable and efficient freight services.",
                                "publisher": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "inLanguage": "en-AU",
                                "breadcrumb": {
                                    "@id": "https://qfmlogistics.com.au/item-types#breadcrumb"
                                }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/item-types#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Item Types",
                                        "item": "https://qfmlogistics.com.au/item-types"
                                    }
                                ]
                            },
                            {
                                "@type": "ImageObject",
                                "@id": "https://qfmlogistics.com.au/images/qfm-item-types.png",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types.png",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types.png",
                                "description": "Comprehensive freight services specialising in pallets, cartons, satchels, and oversized goods across Australia.",
                                "name": "Freight Item Types - Logistics Solutions by QFM",
                                "author": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "license": {
                                    "@id": "https://qfmlogistics.com.au#license"
                                },
                                "creator": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#pallet-freight",
                                "name": "Melbourne Pallet Freight Services",
                                "description": "Reliable pallet freight services across Melbourne and Australia, ensuring secure transport for stacked goods.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Pallet Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/pallet-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-pallet.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#carton-freight",
                                "name": "Melbourne Carton Freight Services",
                                "description": "Comprehensive carton freight services for lightweight goods, providing safe and reliable transport across Australia.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Carton Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/carton-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-carton.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#crate-freight",
                                "name": "Melbourne Crate Freight Services",
                                "description": "Expert handling of wooden or metal crate shipments, ideal for fragile or high-value items, with reliable logistics solutions.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Crate Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/crate-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-crate.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#satchel-freight",
                                "name": "Melbourne Satchel Freight Services",
                                "description": "Fast, reliable shipping for small and lightweight parcels, perfect for urgent documents and compact goods.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Satchel Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/satchel-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-satchel.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#envelope-freight",
                                "name": "Melbourne Envelope Freight Services",
                                "description": "Secure handling for essential documents and smaller shipments, ensuring quick and protected delivery.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Envelope Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/envelope-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-envelope.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#drum-freight",
                                "name": "Melbourne Drum Freight Services",
                                "description": "Safe and compliant transport for cylindrical containers of liquids, chemicals, or industrial materials.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Drum Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/drum-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-drum.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#ibc-freight",
                                "name": "Melbourne IBC Freight Services",
                                "description": "Efficient handling of Intermediate Bulk Containers for liquids or granular materials, ensuring careful and compliant shipping.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "IBC Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/ibc-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-ibc.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#panel-freight",
                                "name": "Melbourne Panel Freight Services",
                                "description": "Secure transport for flat, oversized panels, suitable for construction or industrial applications.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Panel Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/panel-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-panel.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#stillage-freight",
                                "name": "Melbourne Stillage Freight Services",
                                "description": "Handling framed loads or goods stored in cages, ensuring safe transport and secure logistics solutions.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Stillage Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/stillage-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-stillage.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#semi-freight",
                                "name": "Melbourne Semi Freight Services",
                                "description": "Transport using semi-trailers for large or heavy shipments, providing robust and flexible logistic solutions.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Semi-Trailer Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/semi-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-semi.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#b-double-freight",
                                "name": "Melbourne B-Double Freight Services",
                                "description": "Double-trailer transport for extensive freight capacity and reliability across Australia.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "B-Double Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/b-double-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-bdouble.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/item-types#container-freight",
                                "name": "Melbourne Container Freight Services",
                                "description": "Container-based shipments suitable for large-scale or international transport, ensuring secure and efficient operations.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Container Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/container-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-container.jpg"
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Types of Freight</h1>
                        {!isMobile && (
                            <p>
                                QFM offers specialised logistics solutions tailored to the diverse freight needs of businesses and individuals. With extensive experience and a commitment to excellence, QFM ensures your items are handled with care and delivered safely across Australia, no matter the size or complexity of your shipment.
                            </p>
                        )}
                        <p>
                            Our expertise covers an extensive range of freight types—from small parcels like satchels and envelopes to large-scale shipments such as pallets, crates, drums, and containers. Whether you’re transporting delicate materials or bulk goods, we integrate cutting-edge tracking systems with a dedicated support team to ensure maximum reliability and transparency at every stage. This commitment helps businesses of all sizes streamline operations, reduce costs, and achieve timely deliveries across Australia.
                        </p>
                    </div>
                    <div className="ourservices-intro-section-image-container default-image-container-add">
                        {!isMobile && (
                            <img
                                src="images/qfm-item-types.jpg"
                                alt="Comprehensive freight services specialising in pallets, cartons, satchels, and oversized goods across Australia"
                                title="QFM - Expert logistics solutions for diverse freight types across Australia"
                                loading="lazy"
                            />
                        )}
                        <div className="overlay-grid-types">
                            <Link className="grid-button-types" to="/pallet-freight-services">
                                <h4>Pallet</h4>
                            </Link>
                            <Link className="grid-button-types" to="/carton-freight-services">
                                <h4>Carton</h4>
                            </Link>
                            <Link className="grid-button-types" to="/crate-freight-services">
                                <h4>Crate</h4>
                            </Link>
                            <Link className="grid-button-types" to="/satchel-freight-services">
                                <h4>Satchel</h4>
                            </Link>
                            <Link className="grid-button-types" to="/envelope-freight-services">
                                <h4>Envelope</h4>
                            </Link>
                            <Link className="grid-button-types" to="/drum-freight-services">
                                <h4>Drum</h4>
                            </Link>
                            <Link className="grid-button-types" to="/ibc-freight-services">
                                <h4>IBC</h4>
                            </Link>
                            <Link className="grid-button-types" to="/panel-freight-services">
                                <h4>Panel</h4>
                            </Link>
                            <Link className="grid-button-types" to="/stillage-freight-services">
                                <h4>Stillage</h4>
                            </Link>
                            <Link className="grid-button-types" to="/semi-freight-services">
                                <h4>Semi</h4>
                            </Link>
                            <Link className="grid-button-types" to="/bdouble-freight-services">
                                <h4>B-Double</h4>
                            </Link>
                            <Link className="grid-button-types" to="/container-freight-services">
                                <h4>Container</h4>
                            </Link>
                        </div>
                    </div>
                </div>
                {!isMobile && (
                    <div className="default-quote appearing-effect">
                        <Link to="/get-in-touch">
                            <div className="default-quote-quote">
                                <h4>Get In Touch</h4>
                            </div>
                        </Link>
                        {!isMobile && (
                            <div className="default-quote-text-content">
                                <h5>Experience Seamless Freight Operations Across Australia</h5>
                                <p>
                                    From pickup to delivery, we prioritise efficiency, reliability, and customer satisfaction at every stage.
                                </p>
                            </div>
                        )}
                    </div>
                )}

                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content add-a-little">
                        {!isMobile && (
                            <p>
                                At QFM, we understand that each freight type has unique handling requirements. Whether you’re shipping small parcels, oversized goods, or industrial containers, our tailored logistics solutions ensure every item is transported with care and precision. Below, you’ll find detailed insights into the specific services we offer for each freight type, reflecting our expertise and commitment to excellence.
                            </p>
                        )}
                        <ul className='appearing-effect'>
                            <li>
                                <b>Pallet Freight:</b> We offer reliable and efficient <Link to="/pallet-freight-services">Melbourne Pallet Freight Services</Link>, ensuring secure and timely transport for stacked goods, ideal for bulk shipments and storage efficiency.
                            </li>
                            <li>
                                <b>Carton Freight:</b> Our <Link to="/carton-freight-services">Cartons from Melbourne Freight Services</Link> are designed for lightweight and medium-sized goods, providing safe packaging and handling throughout transit.
                            </li>
                            <li>
                                <b>Crate Freight:</b> Specialising in sturdy wooden or metal crate shipments, our <Link to="/crate-freight-services">Melbourne Crate Freight Services</Link> are ideal for fragile or high-value items.
                            </li>
                        </ul>
                        {isMobile && (
                            <div className="default-section-content-left item-types-image appearing-effect">
                                <div className="default-section-content-left-image-container">
                                    <img
                                        src="images/qfm-item-types-one.jpg"
                                        loading="lazy"
                                        alt="Efficient semi freight logistics for heavy load transportation across Australia"
                                        title="Australia-Wide Semi Freight Solutions – Reliable and Efficient Services by QFM"
                                    />
                                </div>
                            </div>
                        )}
                        <ul className='appearing-effect'>
                            <li>
                                <b>Satchel Freight:</b> Our <Link to="/satchel-freight-services">Melbourne Satchel Freight Services</Link> ensure fast and reliable delivery for small, lightweight parcels, perfect for urgent documents and compact goods.
                            </li>
                            <li>
                                <b>Envelope Freight:</b> With <Link to="/envelope-freight-services">Envelopes from Melbourne Freight Services</Link>, we provide secure handling for essential documents and smaller shipments.
                            </li>
                            <li>
                                <b>Drum Freight:</b> Our <Link to="/drum-freight-services">Drum Freight Services</Link> cater to cylindrical containers used for liquids, chemicals, or industrial materials, ensuring safe and compliant transport.
                            </li>
                        </ul>
                        {isMobile && (
                            <div className="default-section-content-left appearing-effect item-types-image">
                                <div className="default-section-content-left-image-container" style={{ width: 'calc(100vw + 20px) !important' }}>
                                    <img
                                        src="images/qfm-item-types-two.jpg"
                                        loading="lazy"
                                        alt="Reliable pallet freight services for efficient transport across Australia"
                                        title="Australia-Wide Pallet Freight Solutions – Dependable and Cost-Effective by QFM"
                                    />
                                </div>
                            </div>
                        )}

                        <ul className='appearing-effect'>
                            <li>
                                <b>IBC Freight:</b> We specialise in transporting Intermediate Bulk Containers with our <Link to="/ibc-freight-services">Melbourne IBC Freight Services</Link>, ideal for bulk liquid or granular materials.
                            </li>
                            <li>
                                <b>Panel Freight:</b> Our <Link to="/panel-freight-services">Panels from Melbourne Freight Services</Link> handle flat and oversized panels, ensuring secure transport for construction and industrial applications.
                            </li>
                            <li>
                                <b>Stillage Freight:</b> With <Link to="/stillage-freight-services">Melbourne Stillage Freight Services</Link>, we ensure the safe handling and transport of framed loads or goods stored in cages.
                            </li>
                        </ul>
                        {isMobile && (
                            <div className="default-section-content-left appearing-effect appearing-effect item-types-image">
                                <div className="default-section-content-left-image-container ">
                                    <img
                                        src="images/qfm-item-types-three.jpg"
                                        loading="lazy"
                                        alt="Efficient carton freight solutions for safe and timely delivery across Australia"
                                        title="Melbourne Freight Handling – Expert Carton Freight Services by QFM"
                                    />
                                </div>
                            </div>
                        )}
                        <ul className='appearing-effect'>
                            <li>
                                <b>Semi-Trailer Freight:</b> Our <Link to="/semi-freight-services">Semi-Trailer Freight Services</Link> are ideal for large and heavy loads, ensuring optimal transport efficiency across Australia.
                            </li>
                            <li>
                                <b>B-Double Freight:</b> For extensive freight requirements, our <Link to="/bdouble-freight-services">B-Doubles from Melbourne Freight Services</Link> provide exceptional capacity and long-haul reliability.
                            </li>
                            <li>
                                <b>Container Freight:</b> We handle containerised shipments through our <Link to="/container-freight-services">Melbourne Container Freight Services</Link>, suitable for international or large-scale transport needs.
                            </li>
                        </ul>
                        <p>
                            With each freight type, our goal is to provide unmatched reliability and efficiency, ensuring that your business's logistics needs are met with precision and care.
                        </p>
                    </div>
                </div>

                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}

                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}

                <Footer />
            </div>
        </div >
    );
}

export default ItemTypes;





