import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Packaging() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Show/hide scroll-up button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Packaging Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised packaging freight services for transporting high-quality packaging materials. Our secure logistics solutions ensure your packaging shipments are delivered safely and on time across Australia."
        />
        <meta
          name="keywords"
          content="Packaging Freight Services, Packaging Materials Transport, Corrugated Boxes Shipping, Pallet Logistics, Secure Packaging Freight, Australia Freight, Reliable Packaging, QFM"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Packaging Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure packaging freight services for transporting premium packaging materials. Trust QFM for customised logistics solutions that ensure timely and safe delivery across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-packaging.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/packaging-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/packaging-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/packaging-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/packaging-freight-services",
                "name": "Packaging Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised packaging freight services for transporting premium packaging materials. Our secure logistics ensure your packaging shipments are delivered safely and on time across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/packaging-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/packaging-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Packaging Freight Services",
                    "item": "https://qfmlogistics.com.au/packaging-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/packaging-freight-services#service",
                "name": "Packaging Freight Services",
                "description": "Our packaging freight services specialise in the secure transport of premium packaging materials including corrugated boxes, pallets, and protective packaging. We provide customised logistics solutions that ensure your materials are handled safely and delivered on time across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-packaging.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-packaging-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your packaging freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-packaging.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-packaging.jpg",
                "description": "Secure transport for premium packaging materials, ensuring robust protection throughout transit.",
                "name": "Packaging Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/packaging-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "ImageObject",
                "@id": "https://qfmlogistics.com.au/images/qfm-commodity-packaging-two.jpg",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-packaging-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-packaging-two.jpg",
                "description": "Professional freight solutions for packaging materials, ensuring secure and timely delivery across Australia.",
                "name": "Packaging Freight Services - Secure Transport by Quan Freight Management",
                "author": {
                  "@id": "https://qfmlogistics.com.au#organization"
                },
                "license": {
                  "@id": "https://qfmlogistics.com.au#license"
                },
                "creator": {
                  "@id": "https://qfmlogistics.com.au#organization"
                },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/packaging-freight-services#webpage"
                }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/packaging-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of packaging materials do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of packaging materials, including cardboard boxes, shrink wrap, protective foam, plastic packaging, and bulk rolls of wrapping supplies. Our freight solutions cater to manufacturers, distributors, and warehouses needing secure packaging transport."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide transport for bulk packaging shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we accommodate bulk packaging freight for businesses handling large-scale production and distribution. Our logistics network ensures reliable, high-volume transport solutions tailored to meet your supply chain requirements."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you transport fragile packaging products?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle various types of packaging materials, but we do not transport glass containers, glass packaging, or fragile glass-related products. If you require transport for other packaging materials such as foam inserts, plastic wrapping, or custom cardboard solutions, we offer secure and efficient freight options."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which regions do you service for packaging freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide nationwide delivery across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations. Whether delivering to manufacturing facilities, retail suppliers, or distribution centres, our freight services ensure timely and reliable transport."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer express freight for urgent packaging shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide express freight solutions for businesses requiring fast turnaround times. Whether you need to meet production deadlines or restock supplies quickly, our expedited freight services ensure rapid and efficient delivery."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for packaging freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To request a quote, please visit our Contact Us page or call our team. Provide details such as shipment size, pickup and delivery locations, and any special handling needs, and we’ll tailor a competitive freight solution to meet your requirements."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/packaging-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Packaging Freight Services</h1>

            <p>
              Ensuring the seamless transport of packaging materials is crucial for supply chains across various industries. Our freight solutions are designed to handle corrugated boxes, cartons, and protective packaging with the utmost care and efficiency, catering to manufacturers, wholesalers, and distributors.
            </p>
            {!isMobile && (
              <p>
                With a well-established logistics network spanning both major metropolitan hubs and remote locations, we provide reliable delivery tailored to your operational needs. Whether you require bulk transportation for large-scale production or flexible shipments for on-demand supply, we offer precision and dependability in every delivery.
              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-packaging.jpg"
              alt="Secure transport for high-quality packaging materials across Australia"
              title="Packaging Freight Services - Secure & Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5> Transport Solutions for Packaging Materials</h5>
            )}
            <p>
              We ensure the safe and efficient delivery of packaging materials, handling shipments with precision to maintain their integrity during transit. <Link to="/goods-and-products">Explore our full range of freight solutions</Link> to optimise your logistics operations.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Packaging Freight by QFM</h2>
            <ul>
              <li>
                <b>Reliable Transport for Packaging Materials:</b> We handle everything from cartons and wrapping supplies to industrial packaging, ensuring safe and efficient delivery.
              </li>
              <li>
                <b>Tailored Freight Solutions:</b> Whether moving bulk shipments for manufacturers or smaller consignments for distributors, we customise logistics to match your supply chain needs.
              </li>
              <li>
                <b>Nationwide Coverage with Timely Deliveries:</b> Servicing Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations, providing seamless transport for packaging products.
              </li>
              <li>
                <b>Advanced Shipment Tracking:</b> Stay informed with real-time tracking and scheduled deliveries, ensuring full visibility and reliability from pickup to final destination.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-packaging-two.jpg"
              loading="lazy"
              alt="Professional freight for packaging materials ensuring secure and timely delivery"
              title="Packaging Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of packaging materials do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We transport a wide range of packaging materials, including cardboard boxes, shrink wrap, protective foam, plastic packaging, and bulk rolls of wrapping supplies. Our freight solutions cater to manufacturers, distributors, and warehouses needing secure packaging transport.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you provide transport for bulk packaging shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
                <p>
                  Yes, we accommodate bulk packaging freight for businesses handling large-scale production and distribution. Our logistics network ensures reliable, high-volume transport solutions tailored to meet your supply chain requirements.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Can you transport fragile packaging products?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  We handle various types of packaging materials, but we do not transport glass containers, glass packaging, or fragile glass-related products. If you require transport for other packaging materials such as foam inserts, plastic wrapping, or custom cardboard solutions, we offer secure and efficient freight options.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Which regions do you service for packaging freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
                <p>
                  We provide nationwide delivery across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations. Whether delivering to manufacturing facilities, retail suppliers, or distribution centres, our freight services ensure timely and reliable transport.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>Do you offer express freight for urgent packaging shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  Yes, we provide express freight solutions for businesses requiring fast turnaround times. Whether you need to meet production deadlines or restock supplies quickly, our expedited freight services ensure rapid and efficient delivery.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>How can I request a quote for packaging freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '300px' : '0px' }}>
                <p>
                  To request a quote, please visit our <Link to="/get-in-touch">Contact Us</Link> page or call our team. Provide details such as shipment size, pickup and delivery locations, and any special handling needs, and we’ll tailor a competitive freight solution to meet your requirements.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Packaging;