import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '../css/Technology.css';

function Technology() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="technology-main-content">
            <Helmet>
                <title>Freight Management Technology | Logistics Solutions by QFM</title>
                <meta name="description" content="Explore advanced freight management technology by Quan Freight Management. From real-time tracking to efficient route optimisation, our innovative solutions ensure seamless logistics, cost savings, and reliable delivery across Australia." />
                <meta name="keywords" content="Freight Management Technology, Logistics Solutions, Quan Freight Management, QFM, QFM Logistics, Real-Time Tracking, Route Optimisation, Seamless Logistics, Cost Savings, Reliable Delivery, Advanced Freight Solutions, Freight Technology Australia, Freight Tracking Systems, Efficient Logistics, Freight Software Solutions" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Freight Management Technology | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover how Quan Freight Management's cutting-edge technology enhances freight services from Melbourne to all major Australian cities. Our innovative digital systems ensure precise, efficient, and on-time deliveries for pallets, cartons, and oversized goods." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-freight-management-technology.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/freight-management-technology" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/freight-management-technology" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/freight-management-technology#webpage",
                                "url": "https://qfmlogistics.com.au/freight-management-technology",
                                "name": "Freight Management Technology | Logistics Solutions by QFM",
                                "description": "Explore advanced freight management technology by Quan Freight Management, enabling efficient transport of pallets, cartons, and oversized goods across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/freight-management-technology#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "CreativeWork",
                                "@id": "https://qfmlogistics.com.au#license",
                                "name": "Terms and Conditions",
                                "url": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/freight-management-technology#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Management Technology",
                                        "item": "https://qfmlogistics.com.au/freight-management-technology"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/freight-management-technology#technology-service",
                                "name": "Freight Management Technology",
                                "description": "Advanced freight management technology enabling efficient transport of pallets, cartons, and oversized goods across Australia, optimizing logistics for timely delivery.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "serviceType": "Logistics Technology Solutions",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-freight-management-technology.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-freight-management-technology.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-freight-management-technology.jpg",
                                "description": "Advanced freight management technology enabling efficient transport of pallets, cartons, and oversized goods across Australia",
                                "name": "Freight Management Technology - Efficient Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="ourstory-intro-section-text-content">
                        <h1>Logistics Technology</h1>
                        {!isMobile && (
                            <p>
                                At Quan Freight Management, we leverage cutting-edge technology to deliver reliable freight services, ensuring your goods are transported safely and efficiently across Australia. With a dedicated expert team and state-of-the-art systems, we provide timely delivery, cost-effective solutions, and real-time tracking for complete peace of mind.
                            </p>
                        )}
                        <p>
                            Our advanced tracking systems offer real-time updates, giving you full visibility of your shipments from pickup to delivery. By leveraging data-driven insights and digital tools, we optimise routes, enhance efficiency, and minimise transit times. Trust QFM to handle your freight with precision, supported by a seamless blend of technology and expertise.
                        </p>

                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-freight-management-technology.jpg"
                            alt="Advanced freight management technology enabling efficient transport of pallets, cartons, and oversized goods across Australia"
                            title="Freight Management Technology - Logistics Solutions by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect" style={isMobile ? { marginBottom: "10px" } : {}}>
                    
                        <div className="default-quote-quote">
                        <Link to="/integrated-freight-solutions">
                            <h4>Website Integration</h4>
                            </Link>
                        </div>
                    
                    {!isMobile && (
                        <div className="default-quote-text-content">
                            <h5>Our advanced solutions ensure seamless operations across Australia</h5>
                            <p>
                                By integrating advanced tracking systems and predictive analytics, we ensure proactive handling of your freight.
                            </p>
                        </div>
                    )}
                </div>
                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content">
                        <h2>Machship</h2>
                        <p>
                            At Quan Freight Management, we leverage the power of Machship, a cutting-edge cloud-based freight management platform, to streamline logistics and elevate service quality. Machship empowers us with advanced tools to efficiently handle every aspect of freight operations, from order creation to final delivery, ensuring a seamless experience for our clients.
                        </p>
                        <ul>
                            <li>
                                <b>End-to-End Visibility:</b> Offers real-time tracking and comprehensive visibility of freight movements, enabling proactive management and timely updates for our clients, reducing uncertainty.
                            </li>
                            <li>
                                <b>Automated Processes:</b> Automates key logistics tasks like carrier selection, rate comparison, and consignment creation, minimising manual effort and eliminating common errors while enhancing operational efficiency.
                            </li>
                            <li>
                                <b>Seamless Integration:</b> Easily integrates with our existing systems and technology, enabling smooth data sharing across departments and ensuring a unified logistics workflow.
                            </li>
                            <li>
                                <b>Customised Reporting:</b> Generates detailed reports and insights, helping us optimise routes, assess performance, and make data-driven decisions to improve efficiency and customer satisfaction.
                            </li>
                            <li>
                                <b>Scalability:</b> Supports growing logistics needs, ensuring we can manage increasing freight volumes while maintaining top-tier service delivery standards.
                            </li>
                        </ul>
                        <p>
                            By utilising Machship, we deliver a technology-driven approach that enhances transparency, efficiency, and reliability, ensuring your freight reaches its destination safely and on time.
                        </p>
                    </div>
                </div>
                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Technology;