import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Electronics() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Show/hide the scroll-up button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at the top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Electronics Freight Services | Logistics Solutions by QFM</title>
        <meta name="description" content="QFM offers secure and reliable electronics freight services for IT equipment, servers, and electronic components. Our climate-controlled, specialised transport solutions ensure your high-value electronics arrive safely across Australia." />
        <meta name="keywords" content="Electronics Freight Services, IT Equipment Transport, Secure Electronics Shipping, Temperature Controlled Freight, Electronics Logistics, Australia Electronics Freight, Reliable IT Transport, Melbourne Electronics Freight" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Electronics Freight Services | Logistics Solutions by QFM" />
        <meta property="og:description" content="Secure and reliable freight solutions for electronic equipment and IT components. Trust QFM to deliver your sensitive electronics safely and efficiently across Australia." />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-electronics.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/electronics-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/electronics-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/electronics-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/electronics-freight-services",
                "name": "Electronics Freight Services | Logistics Solutions by QFM",
                "description": "Secure and specialised freight services for transporting electronic equipment, IT components, and delicate devices across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/electronics-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/electronics-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Electronics Freight Services",
                    "item": "https://qfmlogistics.com.au/electronics-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/electronics-freight-services#service",
                "name": "Electronics Freight Services",
                "description": "Our electronics freight services are designed to protect sensitive IT equipment and electronic components during transit. With climate-controlled transport and specialised handling, we ensure that your high-value electronics are delivered securely across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-electronics.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-electronics-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your electronics freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-electronics.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-electronics.jpg",
                "description": "Secure transport solutions for high-value electronics and IT equipment across Australia.",
                "name": "Electronics Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/electronics-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-electronics-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-electronics-two.jpg",
                "description": "Specialised logistics for delicate electronic components, ensuring protection and timely delivery.",
                "name": "Electronics Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/electronics-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "@id": "https://www.qfmlogistics.com.au/electronics-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of electronics do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of electronics, including IT hardware, consumer devices, telecommunications equipment, and industrial electronic components. Our freight solutions cater to manufacturers, retailers, and businesses requiring secure and efficient deliveries."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you ensure the safety of electronic shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We use specialised loading techniques and secure positioning methods to prevent movement and impact damage during transit. Our experienced handling team ensures electronics are carefully transported to maintain their integrity."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer express delivery for urgent electronic shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide express freight options for time-sensitive electronic deliveries. Whether you need urgent transport for IT equipment, retail stock, or industrial components, we offer priority shipping solutions to meet tight deadlines."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which regions do you service for electronics freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide electronics freight services across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas. Our extensive logistics network ensures reliable and timely deliveries nationwide."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I track my electronics shipment?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our real-time tracking system allows you to monitor your shipment’s progress from pickup to final delivery. You’ll receive updates on estimated arrival times and delivery confirmations."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do I request a quote for electronics freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To receive a quote, visit our Contact Us page or call our team. Please provide details such as pickup and delivery locations, shipment size, weight, and any special handling requirements, and we’ll tailor a competitive freight solution for you."
                    }
                  }
                ],
                "isPartOf": {
                  "@type": "Service",
                  "@id": "https://www.qfmlogistics.com.au/electronics-freight-services",
                  "name": "Electronics Freight Services",
                  "url": "https://www.qfmlogistics.com.au/electronics-freight-services"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Electronics Freight Services</h1>
            {!isMobile && (
              <p>
                QFM provides expert freight solutions for IT hardware, circuit boards, consumer electronics, and industrial components. With specialised handling and protective packaging guidance, we ensure that sensitive electronics are transported securely to prevent damage during transit.
              </p>
            )}
            <p>
              Our nationwide coverage enables seamless deliveries to retail stores, technology hubs, and distribution centers with real-time tracking and optimised routing. We minimise transit risks by using professional load management and secure handling procedures, ensuring shipments arrive safely and on time. Whether you require scheduled deliveries, express shipping, or large-scale distribution, QFM provides reliable logistics to keep your supply chain moving efficiently.
            </p>
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-electronics.jpg"
              alt="Secure transport for high-value electronics and IT equipment across Australia"
              title="Electronics Freight Services - Secure and Reliable Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Innovative Electronics Freight Solutions for Your IT Assets</h5>
            )}
            <p>
              Our secure electronics freight services ensure that your sensitive equipment is delivered safely and efficiently. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for a comprehensive overview of our logistics solutions.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Electronics Freight by QFM</h2>
            <ul>
              <li>
                <b>Secure Transport for Fragile Electronics:</b> We move IT hardware, consumer devices, and industrial components with precision handling to prevent damage in transit.
              </li>
              <li>
                <b>Careful Loading & Transit Stability:</b> Our freight solutions ensure electronics are securely positioned during transport, reducing movement and impact risks.
              </li>
              <li>
                <b>Tailored Logistics for Technology Shipments:</b> Whether moving bulk orders, high-value electronics, or sensitive components, we provide transport solutions designed for the industry's needs.
              </li>
              <li>
                <b>Reliable Nationwide Delivery:</b> Servicing Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas with efficient and timely freight solutions.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-electronics-two.jpg"
              loading="lazy"
              alt="Professional freight for electronics ensuring secure transport of IT equipment"
              title="Electronics Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of electronics do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We transport a wide range of electronics, including IT hardware, consumer devices, telecommunications equipment, and industrial electronic components. Our freight solutions cater to manufacturers, retailers, and businesses requiring secure and efficient deliveries.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>How do you ensure the safety of electronic shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
                <p>
                  We use specialised loading techniques and secure positioning methods to prevent movement and impact damage during transit. Our experienced handling team ensures electronics are carefully transported to maintain their integrity.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Do you offer express delivery for urgent electronic shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  Yes, we provide express freight options for time-sensitive electronic deliveries. Whether you need urgent transport for IT equipment, retail stock, or industrial components, we offer priority shipping solutions to meet tight deadlines.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Which regions do you service for electronics freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '280px' : '0px' }}>
                <p>
                  We provide electronics freight services across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas. Our extensive logistics network ensures reliable and timely deliveries nationwide.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I track my electronics shipment?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '250px' : '0px' }}>
                <p>
                  Our real-time tracking system allows you to monitor your shipment’s progress from pickup to final delivery. You’ll receive updates on estimated arrival times and delivery confirmations.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>How do I request a quote for electronics freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '300px' : '0px' }}>
                <p>
                  To receive a quote, visit our <Link to="/get-in-touch">Contact Us</Link> page or call our team. Please provide details such as pickup and delivery locations, shipment size, weight, and any special handling requirements, and we’ll tailor a competitive freight solution for you.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Electronics;