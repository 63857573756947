import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/OurServices.css';
import { Link } from 'react-router-dom';


function Commodity() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.05 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="ourservices-main-content">
            <Helmet>
                <title>Goods and Products | Logistics Solutions by QFM</title>
                <meta name="description" content="Explore QFM's logistics solutions for various goods and products. We transport pallets, cartons, crates, satchels, drums, and oversized freight like containers and stillages. Reliable delivery across Melbourne and Australia-wide." />
                <meta name="keywords" content="goods and products, freight types, pallets, cartons, crates, satchels, envelopes, drums, IBCs, panels, stillages, semi-trailers, B-Doubles, containers, QFM Logistics, Quan Freight Management, shipping solutions, freight transport" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Goods and Products | Logistics Solutions by QFM" />
                <meta property="og:description" content="QFM Logistics provides reliable transport for various goods and products, including pallets, cartons, satchels, and oversized freight. Serving Melbourne and Australia-wide." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-goods-products.jpg" />
                <meta property="og:image:alt" content="Various goods and products transported by QFM Logistics" />
                <meta property="og:url" content="https://qfmlogistics.com.au/goods-and-products" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/goods-and-products" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#webpage",
                                "url": "https://qfmlogistics.com.au/goods-and-products",
                                "name": "Goods and Products | Logistics Solutions by QFM",
                                "description": "Explore QFM's logistics solutions for various goods and products. We transport pallets, cartons, crates, satchels, drums, and oversized freight like containers and stillages. Reliable delivery across Melbourne and Australia-wide.",
                                "publisher": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "inLanguage": "en-AU",
                                "breadcrumb": {
                                    "@id": "https://qfmlogistics.com.au/goods-and-products#breadcrumb"
                                }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Goods and Products",
                                        "item": "https://qfmlogistics.com.au/goods-and-products"
                                    }
                                ]
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-one.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-commodity-one.jpg",
                                "description": "Automotive and construction freight solutions",
                                "name": "Specialised Freight Services for Automotive and Construction Industries by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-commodity-two.jpg",
                                "description": "Secure transport for electronics and packaging materials",
                                "name": "Electronics and Packaging Freight Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-three.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-commodity-three.jpg",
                                "description": "Furniture and industrial goods transport solutions",
                                "name": "Reliable Freight Services for Furniture and Industrial Equipment by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-commodity.jpg",
                                "description": "Comprehensive freight services for a wide range of commodities - firearms, solar panels, machinery, construction etc.",
                                "name": "Freight Services for Various Commodities - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-four.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-commodity-four.jpg",
                                "description": "Retail and metal freight solutions",
                                "name": "Reliable Retail and Metal Freight Transport by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#audio-freight",
                                "name": "Audio Freight Services",
                                "description": "Reliable transportation of audio equipment, ensuring safe and timely delivery across Melbourne and Australia-wide.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Audio Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/audio-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-audio.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#automotive-freight",
                                "name": "Automotive Freight Services",
                                "description": "Expert transportation of automotive parts and vehicles, ensuring secure and efficient delivery across Australia.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Automotive Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/automotive-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-automotive.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#books-freight",
                                "name": "Books Freight Services",
                                "description": "Ensuring libraries, bookstores, and publishers receive their stock in pristine condition with our Books Freight Services.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Books Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/books-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-books.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#bricks-freight",
                                "name": "Bricks Freight Services",
                                "description": "Heavy-duty transportation of bricks and building materials, facilitating construction projects across Australia.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Bricks Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/bricks-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-bricks.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#camping-freight",
                                "name": "Camping Freight Services",
                                "description": "Transportation of camping equipment and outdoor gear, ensuring retailers and distributors are well-stocked.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Camping Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/camping-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-camping.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#caravan-freight",
                                "name": "Caravan Freight Services",
                                "description": "Specialised transportation for caravans, trailers, and campers, ensuring safe delivery without damage.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Caravan Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/caravan-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-caravan.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#cardboard-freight",
                                "name": "Cardboard Freight Services",
                                "description": "Essential for businesses relying on eco-friendly shipping, transporting everything from flat-packed boxes to bulk packaging.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Cardboard Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/cardboard-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-cardboard.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#cement-freight",
                                "name": "Cement Freight Services",
                                "description": "Supporting major infrastructure projects by transporting dry bags, pre-mixed loads, and construction-grade materials.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Cement Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/cement-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-cement.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#cleaning-freight",
                                "name": "Cleaning Freight Services",
                                "description": "Transporting industrial chemicals, household supplies, and regulated liquids, aerosols, and powders safely.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Cleaning Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/cleaning-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-cleaning.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#construction-freight",
                                "name": "Construction Freight Services",
                                "description": "Moving heavy machinery, scaffolding, and site tools to keep construction projects running smoothly.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Construction Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/construction-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-construction.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#electronics-freight",
                                "name": "Electronics Freight Services",
                                "description": "Providing shock-proof and secure transport for household devices and industrial-grade technology.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Electronics Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/electronics-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-electronics.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#energy-freight",
                                "name": "Energy Freight Services",
                                "description": "Handling solar panels, batteries, and power units, supporting the renewable energy sector with precision logistics.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Energy Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/energy-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-energy.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#fitness-freight",
                                "name": "Fitness Freight Services",
                                "description": "Ensuring gym owners and retailers receive weights, cardio machines, and fitness gear safely.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Fitness Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/fitness-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-fitness.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#furniture-freight",
                                "name": "Furniture Freight Services",
                                "description": "Transporting high-end office desks and bulk household furniture with secure wrapping and handling.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Furniture Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/furniture-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-furniture.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#garden-freight",
                                "name": "Garden Freight Services",
                                "description": "Delivering plants, soil, tools, and decorative materials to nurseries and landscaping companies.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Garden Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/garden-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-garden.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#industrial-freight",
                                "name": "Industrial Freight Services",
                                "description": "Handling factory components, assembly parts, and industrial raw materials to keep manufacturing lines moving.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Industrial Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/industrial-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-industrial.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#machinery-freight",
                                "name": "Machinery Freight Services",
                                "description": "Transporting complex and oversized industrial machines with seamless logistics for heavy industries.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Machinery Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/machinery-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-machinery.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#medical-freight",
                                "name": "Medical Freight Services",
                                "description": "Providing temperature-controlled and compliant transport for pharmaceuticals and medical devices.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Medical Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/medical-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-medical.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#metal-freight",
                                "name": "Metal Freight Services",
                                "description": "Secure transportation of aluminium, steel, and alloy materials supporting metalworking industries.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Metal Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/metal-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-metal.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#office-freight",
                                "name": "Office Freight Services",
                                "description": "Helping businesses relocate or restock with secure transport of IT gear, furniture, and accessories.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Office Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/office-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-office.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#packaging-freight",
                                "name": "Packaging Freight Services",
                                "description": "Transporting packaging materials and solutions tailored to business needs, ensuring efficient logistics.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Packaging Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/packaging-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-packaging.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#party-freight",
                                "name": "Party Freight Services",
                                "description": "Ensuring safe and timely transport of party supplies, decorations, and equipment for events.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Party Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/party-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-party.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#pet-freight",
                                "name": "Pet Freight Services",
                                "description": "Safe and comfortable transportation solutions for pets, ensuring their well-being during transit.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Pet Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/pet-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-pet.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#retail-freight",
                                "name": "Retail Freight Services",
                                "description": "Fast-moving consumer goods (FMCG) transport directly to stores and distribution centres with efficiency.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Retail Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/retail-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-retail.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#signage-freight",
                                "name": "Signage Freight Services",
                                "description": "Ensuring delicate and oversized promotional materials reach their destination intact with secure handling.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Signage Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/signage-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-signage.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#storage-freight",
                                "name": "Storage Freight Services",
                                "description": "Streamlining bulk inventory movement to and from warehouses with efficient logistics solutions.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Storage Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/storage-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-storage.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#textiles-freight",
                                "name": "Textiles Freight Services",
                                "description": "Transporting designer fashion, industrial fabrics, and ensuring climate-protected delivery.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Textiles Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/textiles-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-textiles.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#timber-freight",
                                "name": "Timber Freight Services",
                                "description": "Moving construction-grade and specialty woods with secure handling for various industries.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Timber Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/timber-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-timber.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#firerarms-freight",
                                "name": "Firearms Freight Services",
                                "description": "Secure and compliant transport of firearms for licensed businesses, dealers, and law enforcement agencies across Australia.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Firearms Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/firearms-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-firearms.jpg"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/goods-and-products#warehouse-freight",
                                "name": "Warehouse Freight Services",
                                "description": "Efficient logistics for moving inventory to and from warehouses, ensuring timely and accurate deliveries.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "serviceType": "Warehouse Freight",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/warehouse-freight-services"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-commodity-warehouse.jpg"
                            }
                        ]

                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Goods and Products</h1>
                        {!isMobile && (
                            <p>
                                QFM specialises in the transportation of a wide range of goods, from essential raw materials to finished consumer products. Our logistics solutions are designed to handle diverse commodities with precision, ensuring safe and timely delivery across Australia.
                            </p>
                        )}
                        <p>
                            We facilitate the movement of various commodities, including construction materials, industrial equipment, and retail goods. Whether you're shipping heavy machinery, fragile electronics, or bulk supplies, our expertise in freight management guarantees a seamless transport process. With real-time tracking, optimised routing, and a dedicated support team, QFM ensures your goods reach their destination efficiently and securely.
                        </p>
                    </div>
                    <div className="ourservices-intro-section-image-container default-image-container-add">
                        {!isMobile && (
                            <img
                                src="images/qfm-commodity.jpg"
                                alt="Comprehensive freight services for a wide range of commodities - firearms, solar panels, machinery, construction etc."
                                title="QFM - Expert Freight Solutions for Various Commodities Across Australia"
                                loading="lazy"
                            />
                        )}
                        <div className="overlay-grid-commodity">
                            <Link className="grid-button-commodity" to="/audio-freight-services">
                                <h4>Audio</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/automotive-freight-services">
                                <h4>Automotive</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/books-freight-services">
                                <h4>Books</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/bricks-freight-services">
                                <h4>Bricks</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/camping-freight-services">
                                <h4>Camping</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/caravan-freight-services">
                                <h4>Caravan</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/cardboard-freight-services">
                                <h4>Cardboard</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/cement-freight-services">
                                <h4>Cement</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/chemical-freight-services">
                                <h4>Chemical</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/cleaning-freight-services">
                                <h4>Cleaning</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/construction-freight-services">
                                <h4>Construction</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/electronics-freight-services">
                                <h4>Electronics</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/energy-freight-services">
                                <h4>Energy</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/firearms-freight-services">
                                <h4>Firearms</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/fitness-freight-services">
                                <h4>Fitness</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/furniture-freight-services">
                                <h4>Furniture</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/garden-freight-services">
                                <h4>Garden</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/industrial-freight-services">
                                <h4>Industrial</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/machinery-freight-services">
                                <h4>Machinery</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/medical-freight-services">
                                <h4>Medical</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/metal-freight-services">
                                <h4>Metal</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/office-freight-services">
                                <h4>Office</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/packaging-freight-services">
                                <h4>Packaging</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/party-freight-services">
                                <h4>Party</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/pet-freight-services">
                                <h4>Pet</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/retail-freight-services">
                                <h4>Retail</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/signage-freight-services">
                                <h4>Signage</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/storage-freight-services">
                                <h4>Storage</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/textiles-freight-services">
                                <h4>Textiles</h4>
                            </Link>
                            <Link className="grid-button-commodity" to="/timber-freight-services">
                                <h4>Timber</h4>
                            </Link>


                        </div>
                    </div>
                </div>
                {!isMobile && (
                    <div className="default-quote appearing-effect">

                        <div className="default-quote-quote">
                            <Link to="/get-in-touch">
                                <h4>Get In Touch</h4>
                            </Link>
                        </div>

                        {!isMobile && (
                            <div className="default-quote-text-content">
                                <h5>Efficient Freight Solutions for Most Commodities</h5>
                                <p>
                                    Whether transporting raw materials, retail goods, or industrial equipment, QFM ensures seamless logistics tailored to your specific commodity needs. Our expertise spans across diverse freight categories.
                                </p>
                            </div>
                        )}
                    </div>
                )}


                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content add-a-little">
                        {!isMobile && (
                            <p>
                                At QFM, we handle a diverse range of commodities, ensuring tailored transport solutions that meet the demands of businesses across Australia. Whether you're moving raw materials, consumer goods, or industry-specific freight, we provide specialised logistics to ensure efficiency and reliability.
                            </p>
                        )}

                        <ul className='appearing-effect'>
                            <li>
                                <b>Automotive Parts:</b> From delicate engine components to bulky vehicle frames, our <Link to="/automotive-freight-services">Automotive Freight Services</Link> cater to workshops, manufacturers, and dealerships requiring safe and efficient transport.
                            </li>
                            <li>
                                <b>Books & Publications:</b> Ensuring libraries, bookstores, and publishers receive their stock in pristine condition, our <Link to="/books-freight-services">Books Freight Services</Link> specialise in bulk shipments with protective handling.
                            </li>
                            <li>
                                <b>Bricks & Building Materials:</b> Heavy-duty and built for construction, our <Link to="/bricks-freight-services">Bricks Freight Services</Link> facilitate the movement of bricks, concrete blocks, and other essential building materials.
                            </li>
                            <li>
                                <b>Camping Equipment:</b> Whether it’s tents, outdoor gear, or survival kits, our <Link to="/camping-freight-services">Camping Gear Freight Services</Link> help retailers and distributors keep adventure seekers well-equipped.
                            </li>
                            <li>
                                <b>Caravans & Trailers:</b> We offer specialised transportation for mobile homes, trailers, and campers through our <Link to="/caravan-freight-services">Caravan Freight Services</Link>, ensuring safe delivery without damage.
                            </li>
                        </ul>

                        {isMobile && (
                            <div className="default-section-content-left item-types-image appearing-effect">
                                <div className="default-section-content-left-image-container">
                                    <img src="images/qfm-commodity-one.jpg" loading="lazy" alt="Automotive and construction freight solutions" title="Specialised Freight Services for Automotive and Construction Industries by QFM" />
                                </div>
                            </div>
                        )}

                        <ul className='appearing-effect'>
                            <li>
                                <b>Cardboard & Packaging:</b> Essential for businesses that rely on eco-friendly shipping, our <Link to="/cardboard-freight-services">Cardboard Freight Services</Link> transport everything from flat-packed boxes to bulk packaging.
                            </li>
                            <li>
                                <b>Cement & Concrete:</b> Supporting major infrastructure projects, our <Link to="/cement-freight-services">Cement Freight Services</Link> handle dry bags, pre-mixed loads, and construction-grade materials.
                            </li>
                            <li>
                                <b>Cleaning Products:</b> From industrial chemicals to household supplies, our <Link to="/cleaning-freight-services">Cleaning Supplies Freight Services</Link> ensure regulated transport for liquids, aerosols, and powders.
                            </li>
                            <li>
                                <b>Construction Equipment:</b> Moving heavy machinery, scaffolding, and site tools, our <Link to="/construction-freight-services">Construction Freight Services</Link> keep job sites running smoothly.
                            </li>
                            <li>
                                <b>Electronics & Appliances:</b> Our <Link to="/electronics-freight-services">Electronics Freight Services</Link> provide shock-proof and secure transport for everything from household devices to industrial-grade tech.
                            </li>
                        </ul>

                        {isMobile && (
                            <div className="default-section-content-left appearing-effect item-types-image">
                                <div className="default-section-content-left-image-container">
                                    <img src="images/qfm-commodity-two.jpg" loading="lazy" alt="Secure transport for electronics and packaging materials" title="Electronics and Packaging Freight Solutions by QFM" />
                                </div>
                            </div>
                        )}

                        <ul className='appearing-effect'>
                            <li>
                                <b>Energy & Renewable Products:</b> Handling solar panels, batteries, and power units, our <Link to="/energy-freight-services">Energy Freight Services</Link> support the renewable energy sector with precision logistics.
                            </li>
                            <li>
                                <b>Firearms:</b> Our <Link to="/firearms-freight-services">Firearms Freight Services</Link> ensure compliant and secure transport for licensed firearm dealers and suppliers.
                            </li>
                            <li>
                                <b>Fitness Equipment:</b> We ensure gym owners and retailers receive weights, cardio machines, and fitness gear safely through our <Link to="/fitness-freight-services">Fitness Freight Services</Link>.
                            </li>
                            <li>
                                <b>Furniture & Interiors:</b> From high-end office desks to bulk household furniture, our <Link to="/furniture-freight-services">Furniture Freight Services</Link> offer damage-free transport with secure wrapping and handling.
                            </li>
                            <li>
                                <b>Garden Supplies:</b> Our <Link to="/garden-freight-services">Garden Freight Services</Link> deliver plants, soil, tools, and decorative materials, catering to nurseries and landscaping companies.
                            </li>

                        </ul>

                        {isMobile && (
                            <div className="default-section-content-left appearing-effect item-types-image">
                                <div className="default-section-content-left-image-container">
                                    <img src="images/qfm-commodity-three.jpg" loading="lazy" alt="Furniture and industrial goods transport solutions" title="Reliable Freight Services for Furniture and Industrial Equipment by QFM" />
                                </div>
                            </div>
                        )}

                        <ul className='appearing-effect'>
                            <li>
                                <b>Industrial Goods:</b> With expertise in factory components, assembly parts, and industrial raw materials, our <Link to="/industrial-freight-services">Industrial Freight Services</Link> keep manufacturing lines moving.
                            </li>
                            <li>
                                <b>Machinery & Heavy Equipment:</b> Transporting complex and oversized industrial machines, our <Link to="/machinery-freight-services">Machinery Freight Services</Link> ensure seamless logistics for heavy industry.
                            </li>
                            <li>
                                <b>Medical Supplies:</b> We provide temperature-controlled and compliant transport for pharmaceuticals and medical devices via our <Link to="/medical-freight-services">Medical Freight Services</Link>.
                            </li>
                            <li>
                                <b>Metal & Raw Materials:</b> Supporting metalworking industries, our <Link to="/metal-freight-services">Metal Freight Services</Link> deliver aluminium, steel, and alloy materials securely.
                            </li>
                            <li>
                                <b>Office Equipment:</b> Our <Link to="/office-freight-services">Office Freight Services</Link> help businesses relocate or restock with secure transport of IT gear, furniture, and accessories.
                            </li>

                        </ul>

                        {isMobile && (
                            <div className="default-section-content-left appearing-effect item-types-image">
                                <div className="default-section-content-left-image-container">
                                    <img src="images/qfm-commodity-four.jpg" loading="lazy" alt="Retail and metal freight solutions" title="Reliable Retail and Metal Freight Transport by QFM" />
                                </div>
                            </div>
                        )}

                        <ul className='appearing-effect'>
                            <li>
                                <b>Retail & Consumer Goods:</b> With fast-moving consumer goods (FMCG) in mind, our <Link to="/retail-freight-services">Retail Freight Services</Link> deliver directly to stores and distribution centres.
                            </li>
                            <li>
                                <b>Signage & Display Items:</b> We ensure delicate and oversized promotional materials reach their destination intact with our <Link to="/signage-freight-services">Signage Freight Services</Link>.
                            </li>
                            <li>
                                <b>Storage & Warehousing:</b> Our <Link to="/storage-freight-services">Storage Freight Services</Link> streamline bulk inventory movement to and from warehouses.
                            </li>
                            <li>
                                <b>Textiles & Apparel:</b> From designer fashion to industrial fabrics, our <Link to="/textiles-freight-services">Textiles Freight Services</Link> ensure damage-free and climate-protected delivery.
                            </li>
                            <li>
                                <b>Timber & Wood Products:</b> Our <Link to="/timber-freight-services">Timber Freight Services</Link> move construction-grade and specialty woods with secure handling.
                            </li>
                        </ul>
                        <p>With QFM, every commodity moves securely, efficiently, and with tailored logistics to meet your needs.</p>
                    </div>
                </div>
                
                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}

                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}

                <Footer />
            </div>
        </div >
    );
}

export default Commodity;
