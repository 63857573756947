import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Caravan() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener to show/hide scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Caravan Freight Services | Logistics Solutions by QFM</title>
        <meta name="description" content="Specialised freight services for caravan parts including engine modules, body panels, and other essential components. QFM delivers secure and timely transport across Australia." />
        <meta name="keywords" content="Caravan Parts Freight Services, Caravan Parts Transport, Engine Modules Freight, Caravan Components Shipping, Secure Caravan Parts, Australia Freight, Melbourne Caravan Parts, Reliable Transport" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Caravan Parts Freight Services | Logistics Solutions by QFM" />
        <meta property="og:description" content="Expert freight solutions for caravan parts. Our services ensure that critical components are securely transported with real-time tracking and nationwide coverage." />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-caravan.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/caravan-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/caravan-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/caravan-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/caravan-freight-services",
                "name": "Caravan Parts Freight Services | Secure Transport by QFM",
                "description": "Tailored freight solutions for caravan parts, including engine modules, body panels, and other critical components. We provide secure and timely delivery across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/caravan-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/caravan-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Caravan Parts Freight Services",
                    "item": "https://qfmlogistics.com.au/caravan-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/caravan-freight-services#service",
                "name": "Caravan Parts Freight Services",
                "description": "Specialised freight services for transporting caravan parts, including engine modules, body panels, and other essential components. Our secure and efficient logistics solutions ensure that every component is delivered safely and on time across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-caravan-parts.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-caravan-parts-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your caravan parts freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-caravan-parts.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-caravan.jpg",
                "description": "Secure transport solutions for critical caravan parts, ensuring components are delivered intact.",
                "name": "Caravan Parts Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/caravan-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-caravan-parts-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-caravan-two.jpg",
                "description": "Specialised logistics for large or delicate caravan parts, ensuring robust protection during transit.",
                "name": "Caravan Parts Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/caravan-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/caravan-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of caravan parts do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle a wide range of caravan parts, including chassis components, body panels, axles, windows, and interior fittings. Whether you need heavy-duty structural components or delicate accessories, our freight solutions ensure secure and efficient transport."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you ensure the safety of caravan parts during transit?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our team employs expert loading techniques, secure tie-downs, and reinforced transport methods to minimise movement and prevent damage. Additionally, we offer real-time tracking so you can monitor your shipment throughout its journey."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer expedited delivery for urgent caravan parts?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide express freight solutions for time-sensitive shipments. Our priority services ensure that urgent caravan components reach their destination quickly, minimising downtime for repairs or production."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How should caravan parts be packaged for transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "While we take every precaution to handle your shipments securely, proper packaging is crucial. We recommend using sturdy crates or reinforced boxes for fragile items, securing all parts to prevent movement, and labeling packages appropriately. Well-packaged shipments reduce the risk of damage during transit."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for caravan freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You can request a quote by visiting our Contact Us page or calling our team. Provide details about the shipment, including pickup and delivery locations, dimensions, and any special handling requirements, and we’ll offer a tailored freight solution."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What sets QFM’s caravan freight services apart?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our extensive logistics network, expert handling, and advanced tracking systems make QFM a trusted partner for transporting caravan parts. We provide tailored solutions, ensuring that your components arrive safely and on time across Australia."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/caravan-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Caravan Freight Services</h1>
            
              <p>
                The right transport solution makes all the difference when moving caravan parts. From structural frames to intricate fittings, QFM delivers reliable freight services that keep supply chains running smoothly. Whether you're a manufacturer, retailer, or repair specialist, we ensure your parts arrive safely, ready for installation.
              </p>
            {!isMobile && (
            <p>
              Covering major cities and remote regions across Australia, our logistics network offers seamless door-to-door service with real-time tracking for complete visibility. With expert load management and timely deliveries, QFM keeps your supply chain efficient and ensures your caravan components arrive ready for installation.
            </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-caravan.jpg"
              alt="Secure transport for caravan parts including engine modules, body panels, and accessories"
              title="Caravan Parts Freight Services - Reliable Transport by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Reliable Freight Solutions for Caravan Parts & Accessories</h5>
            )}
            <p>
              Whether you're shipping structural components, electrical systems, or interior fittings, our specialised freight services ensure safe and efficient transport.
              <Link to="/goods-and-products"> Discover our full range of logistics solutions </Link>.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Caravan Parts Freight by QFM</h2>
            <ul>
              <li>
                <b>Precision Handling for Caravan Components:</b> Expert freight solutions designed for oversized, fragile, and high-value parts, ensuring safe transport.
              </li>
              <li>
                <b>Seamless Door-to-Door Logistics:</b> From collection to final delivery, we provide a hassle-free service tailored to manufacturers, repairers, and retailers.
              </li>
              <li>
                <b>Australia-Wide Freight Network:</b> Servicing metropolitan hubs and remote destinations, we ensure reliable transport wherever you need it.
              </li>
              <li>
                <b>Live Shipment Monitoring:</b> Track your caravan parts in real time with our advanced logistics technology for complete transparency.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-caravan-two.jpg"
              loading="lazy"
              alt="Professional freight for caravan parts ensuring secure transport of components"
              title="Caravan Parts Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of caravan parts do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We handle a wide range of caravan parts, including chassis components, body panels, axles, windows, and interior fittings. Whether you need heavy-duty structural components or delicate accessories, our freight solutions ensure secure and efficient transport.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>How do you ensure the safety of caravan parts during transit?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
                <p>
                  Our team employs expert loading techniques, secure tie-downs, and reinforced transport methods to minimise movement and prevent damage. Additionally, we offer real-time tracking so you can monitor your shipment throughout its journey.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Do you offer expedited delivery for urgent caravan parts?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  Yes, we provide express freight solutions for time-sensitive shipments. Our priority services ensure that urgent caravan components reach their destination quickly, minimising downtime for repairs or production.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>How should caravan parts be packaged for transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
                <p>
                  While we take every precaution to handle your shipments securely, proper packaging is crucial. We recommend using sturdy crates or reinforced boxes for fragile items, securing all parts to prevent movement, and labeling packages appropriately. Well-packaged shipments reduce the risk of damage during transit.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I request a quote for caravan freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  You can request a quote by visiting our <Link to="/get-in-touch">Contact Us</Link> page or calling our team. Provide details about the shipment, including pickup and delivery locations, dimensions, and any special handling requirements, and we’ll offer a tailored freight solution.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>What sets QFM’s caravan freight services apart?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '300px' : '0px' }}>
                <p>
                  Our extensive logistics network, expert handling, and advanced tracking systems make QFM a trusted partner for transporting caravan parts. We provide tailored solutions, ensuring that your components arrive safely and on time across Australia.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Caravan;