import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Medical() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Show/hide the scroll-up button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at the top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" navigation link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Medical Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised medical freight services for transporting critical medical equipment, supplies, and devices. Our secure, temperature-regulated logistics ensure timely delivery to hospitals, clinics, and research facilities across Australia."
        />
        <meta
          name="keywords"
          content="Medical Freight Services, Medical Equipment Transport, Healthcare Logistics, Medical Supplies Shipping, Temperature-Controlled Freight, Australia Medical Logistics, Secure Medical Transport, Hospital Equipment Freight"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Medical Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure and specialised freight services for medical equipment and supplies. Trust QFM to transport your critical healthcare assets safely and on schedule across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-medical.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/medical-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/medical-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/medical-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/medical-freight-services",
                "name": "Medical Freight Services | Logistics Solutions by QFM",
                "description": "Specialised freight services for transporting critical medical equipment, supplies, and devices. Our secure, temperature-regulated logistics ensure that your healthcare assets are delivered safely and on schedule across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/medical-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/medical-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Medical Freight Services",
                    "item": "https://qfmlogistics.com.au/medical-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/medical-freight-services#service",
                "name": "Medical Freight Services",
                "description": "Our medical freight services provide secure and specialised transport for critical healthcare equipment, diagnostic devices, and essential medical supplies. With rigorous temperature control and compliance with healthcare standards, we ensure that your shipments reach hospitals, clinics, and research facilities safely and on schedule.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-medical.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-medical-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your medical freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-medical.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-medical.jpg",
                "description": "Secure transport solutions for critical medical equipment and supplies across Australia.",
                "name": "Medical Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/medical-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-medical-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-medical-two.jpg",
                "description": "Specialised logistics for transporting high-value medical equipment and critical healthcare supplies with stringent care.",
                "name": "Medical Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/medical-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/medical-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of medical equipment do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle a comprehensive range of medical freight, including diagnostic imaging machines, surgical instruments, rehabilitation equipment, and hospital furnishings. Whether you're transporting delicate medical devices or bulk healthcare supplies, we provide secure logistics solutions tailored to the healthcare sector."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you accommodate urgent deliveries for critical healthcare supplies?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide priority freight services for urgent medical shipments, ensuring fast and secure deliveries to hospitals, clinics, and laboratories. Our expedited options help minimise delays, allowing healthcare providers to receive essential supplies precisely when they need them."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer door-to-door service for medical freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely. Our door-to-door medical freight service ensures that healthcare shipments are collected from the supplier and delivered directly to hospitals, clinics, research labs, or distribution centres. This reduces handling risks and streamlines supply chain efficiency."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What compliance standards do your medical freight services adhere to?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our medical freight solutions adhere to strict industry regulations, ensuring that healthcare equipment and supplies are transported in compliance with safety and quality assurance standards. We work closely with medical suppliers and healthcare providers to meet regulatory requirements for secure and efficient transportation."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for medical freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To receive a tailored quote, visit our Contact Us page or call our team with details about your shipment, including pickup and delivery locations, volume, handling requirements, and delivery timeframes. Our specialists will provide a cost-effective solution to match your logistics needs."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/medical-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Medical Freight Services</h1>

            <p>
              Whether you need to transport advanced diagnostic machines or critical care supplies, our medical freight services offer secure and specialised logistics for the healthcare sector. We ensure that every shipment is handled with the utmost precision and care.

            </p>

            {!isMobile && (
              <p>
                With a comprehensive network spanning major cities and remote regions across Australia, we guarantee timely delivery to hospitals, clinics, and research facilities, supporting the seamless operation of healthcare services.
                We specialise in secure transport of critical medical equipment and supplies.
                Our network spans Australia, ensuring reliable delivery to healthcare facilities.
              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-medical.jpg"
              alt="Secure transport for medical equipment and supplies across Australia"
              title="Medical Freight Services - Secure and Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Customised Medical Freight Solutions for the Healthcare Sector</h5>
            )}
            <p>
              Our tailored logistics ensure that your medical equipment and supplies are transported securely and efficiently. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive healthcare logistics support.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Medical Freight by QFM</h2>
            <ul>
              <li>
                <b>Specialist Transport for Medical Equipment:</b> Reliable freight solutions for diagnostic machines, surgical instruments, hospital beds, and rehabilitation devices, ensuring safe and secure delivery.
              </li>
              <li>
                <b>Timely & Priority Medical Freight:</b> Expedited shipping options for urgent deliveries to hospitals, clinics, laboratories, and pharmacies, helping healthcare providers maintain essential operations.
              </li>
              <li>
                <b>Nationwide Coverage for Healthcare Logistics:</b> Serving Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas with tailored medical transport solutions for seamless supply chain efficiency.
              </li>
              <li>
                <b>Real-Time Tracking & Dedicated Support:</b> Stay informed with live shipment tracking and expert customer service, ensuring full visibility and peace of mind for sensitive medical freight.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-medical-two.jpg"
              loading="lazy"
              alt="Professional freight for medical equipment ensuring secure and timely delivery"
              title="Medical Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
  <div className="faq-text-content">
    <h2>FAQ</h2>

    
    <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
      <h5>What types of medical equipment do you transport?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
        <p>
          We handle a comprehensive range of medical freight, including diagnostic imaging machines, surgical instruments, rehabilitation equipment, and hospital furnishings. Whether you're transporting delicate medical devices or bulk healthcare supplies, we provide secure logistics solutions tailored to the healthcare sector.
        </p>
      </div>
    </div>

    
    <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
      <h5>Can you accommodate urgent deliveries for critical healthcare supplies?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
        <p>
          Yes, we provide priority freight services for urgent medical shipments, ensuring fast and secure deliveries to hospitals, clinics, and laboratories. Our expedited options help minimise delays, allowing healthcare providers to receive essential supplies precisely when they need them.
        </p>
      </div>
    </div>

    
    <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
      <h5>Do you offer door-to-door service for medical freight?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
        <p>
          Absolutely. Our door-to-door medical freight service ensures that healthcare shipments are collected from the supplier and delivered directly to hospitals, clinics, research labs, or distribution centres. This reduces handling risks and streamlines supply chain efficiency.
        </p>
      </div>
    </div>

  
    <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
      <h5>What compliance standards do your medical freight services adhere to?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '320px' : '0px' }}>
        <p>
          Our medical freight solutions adhere to strict industry regulations, ensuring that healthcare equipment and supplies are transported in compliance with safety and quality assurance standards. We work closely with medical suppliers and healthcare providers to meet regulatory requirements for secure and efficient transportation.
        </p>
      </div>
    </div>

    
    <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
      <h5>How can I request a quote for medical freight services?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
        <p>
          To receive a tailored quote, visit our <Link to="/get-in-touch">Contact Us</Link> page or call our team with details about your shipment, including pickup and delivery locations, volume, handling requirements, and delivery timeframes. Our specialists will provide a cost-effective solution to match your logistics needs.
        </p>
      </div>
    </div>
  </div>
</div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Medical;