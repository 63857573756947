import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Energy() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Energy Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised energy freight services for transporting renewable and conventional energy equipment such as solar panels, batteries, and turbine components. Our secure, climate-controlled solutions ensure safe and timely deliveries across Australia."
        />
        <meta
          name="keywords"
          content="Energy Freight Services, Renewable Energy Transport, Solar Panel Shipping, Battery Logistics, Wind Turbine Freight, Energy Equipment Transport, Australia Energy Freight, Secure Energy Logistics"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Energy Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure and specialised freight solutions for energy equipment. Trust QFM for climate-controlled, reliable transport of solar panels, batteries, and turbine components across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-energy.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/energy-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/energy-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/energy-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/energy-freight-services",
                "name": "Energy Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised freight services for transporting renewable and conventional energy equipment such as solar panels, batteries, and turbine components. Our secure, climate-controlled logistics ensure safe and timely delivery across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/energy-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/energy-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Energy Freight Services",
                    "item": "https://qfmlogistics.com.au/energy-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/energy-freight-services#service",
                "name": "Energy Freight Services",
                "description": "Our energy freight services are designed to securely transport renewable and conventional energy equipment. We specialise in handling solar panels, battery systems, and turbine components with climate-controlled, robust logistics solutions to ensure safe delivery across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-energy.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-energy-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your energy freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-energy.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-energy.jpg",
                "description": "Secure transport solutions for energy equipment including solar panels and battery systems across Australia.",
                "name": "Energy Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/energy-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-energy-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-energy-two.jpg",
                "description": "Specialised logistics for heavy energy equipment ensuring reliable and timely delivery.",
                "name": "Energy Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/energy-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "@id": "https://www.qfmlogistics.com.au/energy-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of energy equipment do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of energy-related equipment, including solar panels, battery storage systems, transformers, wind turbine components, and industrial power units. Our logistics solutions are designed to handle both fragile and heavy-duty energy assets with precision."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you ensure the safety of energy equipment in transit?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our freight services include expert load securing techniques, reinforced transport solutions, and careful route planning to prevent damage. We ensure that large or fragile components remain stable throughout transit, minimizing movement and impact risks."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you handle large-scale renewable energy shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we specialize in logistics solutions for renewable energy projects, including bulk transport of solar panels, wind turbine components, and energy storage systems. Our services support commercial and industrial projects requiring specialized freight handling."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which areas do you service for energy freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide energy freight services across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas. Our extensive transport network ensures reliable and timely deliveries for energy infrastructure projects nationwide."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do I track my energy equipment shipment?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our real-time tracking system provides full visibility of your shipment from pickup to delivery. You can monitor progress, estimated arrival times, and receive status updates throughout the transport process."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for energy freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To receive a quote, visit our Contact Us page or call our team. Provide details such as pickup and delivery locations, shipment size, weight, and any special handling requirements, and we’ll tailor a competitive freight solution for you."
                    }
                  }
                ],
                "isPartOf": {
                  "@type": "Service",
                  "@id": "https://www.qfmlogistics.com.au/energy-freight-services",
                  "name": "Energy Freight Services",
                  "url": "https://www.qfmlogistics.com.au/energy-freight-services"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Energy Freight Services</h1>
            <p>
              Whether you require secure transport for renewable or conventional energy equipment, our specialised freight solutions guarantee the utmost care and efficiency. We are experts in handling solar panels, battery systems, and turbine components with advanced climate control.
            </p>
            {!isMobile && (
            <p>
              With a robust logistics network covering metropolitan and remote areas across Australia, we ensure that your critical energy assets are delivered safely and on schedule, supporting your sustainable projects.
              We specialise in secure transport of energy equipment using climate-controlled solutions.              Our network spans Australia, ensuring reliable delivery from urban centres to remote regions.
            </p>
            )}

          </div>

          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-energy.jpg"
              alt="Secure transport for energy equipment including solar panels and battery systems across Australia"
              title="Energy Freight Services - Secure and Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>


        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Innovative Energy Freight Solutions for Your Critical Assets</h5>
            )}
            <p>
              Our tailored freight services ensure that your energy equipment is transported securely and efficiently. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for a comprehensive overview of our logistics solutions.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Energy Freight by QFM</h2>
            <ul>
              <li>
                <b>Secure Transport for Energy Equipment:</b> Reliable freight solutions for solar panels, battery systems, transformers, and turbine components, ensuring safe and efficient delivery.
              </li>
              <li>
                <b>Expert Handling for Fragile and Heavy Loads:</b> Our team ensures that energy infrastructure components are properly secured during transit to prevent movement or damage.
              </li>
              <li>
                <b>Tailored Logistics for Energy Shipments:</b> Custom transport plans designed to support renewable energy projects, industrial applications, and large-scale installations.
              </li>
              <li>
                <b>Nationwide Delivery Network:</b> Servicing major cities and remote regions across Australia with reliable, on-time transport for energy sector needs.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-energy-two.jpg"
              loading="lazy"
              alt="Professional freight for energy equipment ensuring secure and timely delivery"
              title="Energy Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
    <div className="faq-text-content">
        <h2>FAQ</h2>

        {/* FAQ 1 */}
        <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
            <h5>What types of energy equipment do you transport?</h5>
            <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                    We transport a wide range of energy-related equipment, including solar panels, battery storage systems, transformers, wind turbine components, and industrial power units. Our logistics solutions are designed to handle both fragile and heavy-duty energy assets with precision.
                </p>
            </div>
        </div>

        {/* FAQ 2 */}
        <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
            <h5>How do you ensure the safety of energy equipment in transit?</h5>
            <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
                <p>
                    Our freight services include expert load securing techniques, reinforced transport solutions, and careful route planning to prevent damage. We ensure that large or fragile components remain stable throughout transit, minimizing movement and impact risks.
                </p>
            </div>
        </div>

        {/* FAQ 3 */}
        <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
            <h5>Do you handle large-scale renewable energy shipments?</h5>
            <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                    Yes, we specialize in logistics solutions for renewable energy projects, including bulk transport of solar panels, wind turbine components, and energy storage systems. Our services support commercial and industrial projects requiring specialized freight handling.
                </p>
            </div>
        </div>

        {/* FAQ 4 */}
        <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
            <h5>Which areas do you service for energy freight?</h5>
            <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '280px' : '0px' }}>
                <p>
                    We provide energy freight services across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas. Our extensive transport network ensures reliable and timely deliveries for energy infrastructure projects nationwide.
                </p>
            </div>
        </div>

        {/* FAQ 5 */}
        <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
            <h5>How do I track my energy equipment shipment?</h5>
            <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '250px' : '0px' }}>
                <p>
                    Our real-time tracking system provides full visibility of your shipment from pickup to delivery. You can monitor progress, estimated arrival times, and receive status updates throughout the transport process.
                </p>
            </div>
        </div>

        {/* FAQ 6 */}
        <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
            <h5>How can I request a quote for energy freight services?</h5>
            <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '300px' : '0px' }}>
                <p>
                    To receive a quote, visit our <Link to="/get-in-touch">Contact Us</Link> page or call our team. Provide details such as pickup and delivery locations, shipment size, weight, and any special handling requirements, and we’ll tailor a competitive freight solution for you.
                </p>
            </div>
        </div>
    </div>
</div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Energy;