import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function IBC() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>IBC Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management (QFM) offers specialised IBC freight services across Melbourne, Brisbane, and Australia-wide. We provide secure and efficient transport solutions for bulk liquid and granular materials, ensuring compliance and reliability." />
                <meta name="keywords" content="IBC Freight Services, Quan Freight Management, QFM Logistics, Intermediate Bulk Container Transport, Melbourne IBC Freight, Brisbane IBC Logistics, Bulk Liquid Transport, Granular Material Transport, Freight Solutions, Road Freight, Rail Freight, Air Freight, Safe Freight Handling, Cost-Effective Logistics Solutions, Advanced Freight Technology" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="IBC Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's IBC freight services. From Melbourne to Brisbane and Australia-wide, we ensure the safe and efficient transport of Intermediate Bulk Containers (IBCs) for liquids and granular materials with advanced logistics solutions." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-ibc.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/ibc-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/ibc-freight-services" />
                {/* NEED TO UPDATE THE JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/ibc-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/ibc-freight-services",
                                "name": "IBC Freight Services | Logistics Solutions by QFM",
                                "description": "QFM provides expert IBC freight services, ensuring the safe and efficient transport of industrial bulk containers across Australia, including hazardous materials.",
                                "publisher": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "inLanguage": "en-AU",
                                "breadcrumb": {
                                    "@id": "https://qfmlogistics.com.au/ibc-freight-services#breadcrumb"
                                }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/ibc-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "IBC Freight Services",
                                        "item": "https://qfmlogistics.com.au/ibc-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/ibc-freight-services#service",
                                "name": "IBC Freight Services",
                                "description": "Specialised logistics for Industrial Bulk Containers (IBCs), including hazardous materials and liquids, with secure handling and efficient delivery across Australia.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfmlogistics-qfm-item-types-ibc.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfmlogistics-qfm-item-types-ibc.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfmlogistics-qfm-item-types-ibc.jpg",
                                "description": "Reliable logistics for Industrial Bulk Containers, including hazardous materials.",
                                "name": "IBC Freight Services - Expert Handling by QFM",
                                "author": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "license": {
                                    "@id": "https://qfmlogistics.com.au#license"
                                },
                                "creator": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfmlogistics-qfm-item-types-ibc-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfmlogistics-qfm-item-types-ibc-two.jpg",
                                "description": "Efficient management of IBC freight shipments across Australia.",
                                "name": "IBC Freight Services - Comprehensive Logistics by QFM",
                                "author": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "license": {
                                    "@id": "https://qfmlogistics.com.au#license"
                                },
                                "creator": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/ibc-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Which areas do your IBC freight services cover?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our IBC freight services cover Melbourne and all major cities across Australia, including Sydney, Brisbane, Perth, Adelaide, and regional areas. We also extend our services to remote locations."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/ibc-freight-services",
                                            "name": "IBC Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can you transport hazardous materials in IBCs?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we are equipped to handle hazardous materials within IBCs, adhering to all safety and regulatory standards to ensure secure transport."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/ibc-freight-services",
                                            "name": "IBC Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of goods can be transported in IBCs?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "IBCs are ideal for liquids, chemicals, food-grade products, and other bulk materials. Contact us for tailored advice on your specific shipment needs."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/ibc-freight-services",
                                            "name": "IBC Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are there size or weight restrictions for IBC shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "While we handle a wide range of IBC sizes and weights, some restrictions may apply based on transport mode and regulatory requirements. Contact us with your details for tailored guidance."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/ibc-freight-services",
                                            "name": "IBC Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a quote for IBC freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page and provide details such as pickup and delivery locations, IBC dimensions, weight, and any specific handling requirements. Our team will promptly provide a tailored quote."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/ibc-freight-services",
                                            "name": "IBC Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-C758R2N8C5');
                    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>IBC Freight Services</h1>
                        <p>
                            At QFM, we specialise in the secure and efficient handling of Intermediate Bulk Containers (IBCs). Our dedicated IBC freight solutions are designed to transport bulk liquids and granular materials safely, utilising rigorous handling protocols and state‑of‑the‑art technology. From ensuring compliance with industry regulations to implementing advanced tracking systems, every aspect of our service is tailored to guarantee the integrity of your shipment.
                        </p>
                        {!isMobile && (
                            <p>
                                From Melbourne to every major hub – including Brisbane, Sydney, Perth, and Adelaide – our IBC services extend nationwide, reaching even remote and regional areas. By integrating road, rail, and air transport, we offer adaptable solutions that meet strict delivery schedules and diverse regulatory requirements.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-item-types-ibc.jpg"
                            alt="Secure IBC freight services ensuring efficient transport of bulk liquids and granular materials across Australia"
                            title="IBC Freight Services – Expert, Reliable Logistics by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Customised IBC freight solutions engineered for safety and rapid delivery</h5>
                        )}
                        <p>
                            Our IBC freight services from Melbourne ensure robust connectivity to key logistics hubs, offering dependable transport of bulk goods with transparency and precision across Australia. <Link to="/types-of-freight">View the complete list of freight services we handle</Link>.

                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne IBC Freight by QFM</h2>
                        <ul>
                            <li><b>Specialised Handling:</b> Tailored logistics for bulk liquids and granular materials, ensuring each IBC is managed with expert care.</li>
                            <li><b>Dependable Delivery:</b> Consistent, on‑schedule transport that guarantees your shipments arrive intact.</li>
                            <li><b>Regulatory Excellence:</b> Adherence to strict safety and compliance standards throughout every phase of transit.</li>
                            <li><b>Nationwide Reach:</b> A comprehensive network that spans from Melbourne to Australia’s major cities and regional centres.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-item-types-ibc-two.jpg"
                            loading="lazy"
                            alt="Efficient management of IBC freight shipments across Australia"
                            title="Melbourne Freight Handling – Comprehensive IBC Logistics by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="ibc-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What areas do your IBC freight services cover?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our IBC freight services cover Melbourne, Sydney, Brisbane, Perth, Adelaide, and key regional locations across Australia. We also deliver to remote areas, ensuring your shipments are reliably managed wherever they need to go.
                                    <br />
                                    With comprehensive coverage, we aim to provide efficient, nationwide delivery solutions for your IBC logistics.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>What types of goods can be transported in IBCs?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    IBCs are ideal for transporting liquid, semi-liquid, and bulk goods such as chemicals, food products, oils, and industrial materials. We offer secure handling and compliance with all regulatory requirements for both hazardous and non-hazardous substances.
                                    <br />
                                    Let us know your specific requirements, and we’ll ensure the right solutions for your goods.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>Are there specific size or weight limits for IBC shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "250px" : "0px" }}
                            >
                                <p>
                                    Standard IBCs typically hold up to 1,000 litres, but we can accommodate varying sizes and weights based on your requirements. Please provide details of your IBC specifications, and our team will ensure compatibility with our freight services.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How do you ensure the safety of IBC shipments during transit?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "270px" : "0px" }}
                            >
                                <p>
                                    Safety is our priority. We use secure strapping, specialised handling equipment, and robust transport protocols to minimise risks. For hazardous materials, we ensure compliance with Australian safety regulations, providing peace of mind that your goods are handled responsibly.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Can I request express delivery for my IBC shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "230px" : "0px" }}
                            >
                                <p>
                                    Yes, we offer express delivery options for time-sensitive IBC shipments. Contact us to discuss your timelines, and we’ll tailor a solution to ensure your goods arrive on schedule.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>How can I get a quote for IBC freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "250px" : "0px" }}
                            >
                                <p>
                                    Requesting a quote is straightforward. Visit our <Link to="/get-in-touch">Contact Us</Link> page and provide key details such as pickup and delivery locations, IBC size, weight, and the type of goods. Our team will review your information and provide a competitive, tailored quote promptly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default IBC;


