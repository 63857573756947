import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Bdouble() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>B-Double Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides specialised B-Double freight services across Melbourne, Brisbane, and Australia-wide. Our reliable and cost-effective logistics solutions ensure the safe and efficient transport of large-scale shipments using advanced tracking and professional handling." />
                <meta name="keywords" content="B-Double Freight Services, Quan Freight Management, QFM Logistics, B-Double Transport Australia, Melbourne B-Double Freight, Brisbane B-Double Logistics, Freight Solutions, Reliable Logistics, Road Freight, Heavy Haulage, Large Shipment Transport, Safe Freight Handling, Cost-Effective Freight Solutions, Advanced Freight Technology, Long-Haul Freight" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="B-Double Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Explore QFM's B-Double freight services. Serving Melbourne, Brisbane, and Australia-wide, we provide efficient and secure transport for large shipments with advanced logistics solutions and expert care." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-bdouble.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/bdouble-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/bdouble-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/bdouble-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/bdouble-freight-services",
                                "name": "B-Double Freight Services | High-Capacity Logistics Solutions by QFM",
                                "description": "Discover efficient and high-capacity B-Double freight services by QFM. Based in Melbourne, we provide nationwide logistics solutions tailored for large-scale shipments to key destinations across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/bdouble-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/bdouble-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "B-Double Freight Services",
                                        "item": "https://qfmlogistics.com.au/bdouble-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/bdouble-freight-services#service",
                                "name": "B-Double Freight Services",
                                "description": "Our B-Double freight services offer high-capacity logistics solutions tailored for large-scale shipments. Operating from Melbourne, we ensure efficient and reliable transportation to major cities and regional hubs across Australia.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-bdouble.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-bdouble.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-bdouble.jpg",
                                "description": "Efficiently managing high-capacity B-Double freight shipments across Australia",
                                "name": "B-Double Freight Services - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-bdouble-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-bdouble-two.jpg",
                                "description": "Efficient handling and secure logistics for high-capacity B-Double shipments across Australia",
                                "name": "Melbourne Freight Handling - High-Capacity B-Double Solutions",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/bdouble-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What regions do your B-Double freight services cover?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our B-Double freight services operate from Melbourne, providing nationwide coverage, including key cities like Sydney, Brisbane, Perth, and Adelaide, as well as regional hubs across Australia."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/bdouble-freight-services",
                                            "name": "B-Double Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of goods can be transported using B-Double freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "B-Double freight is ideal for transporting high-capacity and bulk goods, including industrial supplies, construction materials, agricultural products, and oversized items requiring secure handling."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/bdouble-freight-services",
                                            "name": "B-Double Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do your B-Double freight services include direct routes?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we provide direct, streamlined routes for B-Double freight to minimise transit times and ensure fast, reliable delivery to your destination."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/bdouble-freight-services",
                                            "name": "B-Double Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How do you ensure the safety of B-Double freight shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We prioritise safety through rigorous protocols, secure load management, and specialised equipment, ensuring your freight remains intact and protected throughout transit."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/bdouble-freight-services",
                                            "name": "B-Double Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a quote for B-Double freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Requesting a quote is easy. Visit our Contact Us page, provide details such as pickup and delivery locations, load dimensions, and any special requirements, and our team will deliver a tailored quote promptly."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/bdouble-freight-services",
                                            "name": "B-Double Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>B‑Double Freight Services</h1>
                        <p>
                            When it comes to high-capacity freight transport, QFM’s B‑Double freight services offer unmatched efficiency and reliability. Our solutions are specifically designed to handle large-scale shipments, leveraging optimised logistics strategies to ensure secure handling and precise delivery schedules.
                        </p>
                        {!isMobile && (
                            <p>
                                Based in Melbourne, our B‑Double freight network extends seamlessly to Australia’s major logistics hubs, including Sydney, Brisbane, Perth, and Adelaide, as well as key regional locations. With advanced tracking technology and a dedicated team, we ensure your bulk freight requirements are met with consistency and care, no matter the scale or destination.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-item-types-bdouble.jpg"
                            alt="Robust B‑Double freight services delivering high‑capacity shipments safely across Australia"
                            title="B‑Double Freight Services – Comprehensive, Reliable Logistics by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Customised B‑Double freight solutions designed for large‑scale, efficient transport</h5>
                        )}
                        <p>
                            Our services seamlessly connect Melbourne with major logistic hubs, ensuring high‑volume shipments are delivered on time. Discover how our tailored B‑Double solutions can meet your business’s unique requirements. <Link to="/types-of-freight">Explore all our freight handling options</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne B‑Double Freight by QFM</h2>
                        <ul>
                            <li><b>High Capacity:</b> Our B‑Double solutions are engineered for transporting large volumes of freight reliably.</li>
                            <li><b>Robust Performance:</b> Designed for long-haul journeys, we ensure consistent and on‑schedule deliveries.</li>
                            <li><b>Operational Efficiency:</b> Streamlined logistics and reduced transit times help lower costs and boost productivity.</li>
                            <li><b>Nationwide Coverage:</b> Our extensive network covers major cities and regional centres across Australia.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-item-types-bdouble-two.jpg"
                            loading="lazy"
                            alt="Efficiently managing high‑capacity B‑Double freight shipments with reliable logistics solutions across Australia"
                            title="Melbourne Freight Handling – High‑Capacity B‑Double Freight Solutions by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="bdouble-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What areas do your B‑Double freight services cover?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our B‑Double freight services operate from Melbourne and extend across Australia, connecting to major cities such as Sydney, Brisbane, Perth, and Adelaide, as well as key regional centres.
                                    <br />
                                    Whether your freight needs are urban, regional, or remote, our expansive network ensures timely and efficient transport solutions.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>What types of goods are suitable for B‑Double freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    B‑Double freight is ideal for high-capacity and bulk shipments, including construction materials, packaged goods, industrial equipment, and agricultural products.
                                    <br />
                                    If you have large loads requiring secure and efficient transport, B‑Double solutions are an excellent choice.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>How do you ensure the safe handling of large B‑Double shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "260px" : "0px" }}
                            >
                                <p>
                                    Safety is a top priority for our B‑Double freight services. We employ advanced load securing methods, rigorous safety protocols, and well-maintained vehicles to ensure shipments remain intact throughout transit.
                                    <br />
                                    Our experienced team follows best practices and adheres to all transport regulations to guarantee the secure handling of your goods.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>Are there weight or size restrictions for B‑Double freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    While B‑Double vehicles are designed for high-capacity loads, weight and size restrictions depend on transport routes and regulatory requirements.
                                    <br />
                                    Contact us with your shipment details, and we’ll provide tailored guidance to ensure compliance and efficient transport.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>What industries benefit from B‑Double freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "270px" : "0px" }}
                            >
                                <p>
                                    Industries such as manufacturing, construction, agriculture, and retail rely on B‑Double freight for their large-scale logistics needs. The ability to transport bulk goods efficiently makes it a preferred choice for businesses with high-volume shipping requirements.
                                    <br />
                                    We tailor our services to align with your industry-specific demands.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>How can I request a quote for B‑Double freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "230px" : "0px" }}
                            >
                                <p>
                                    Requesting a quote is simple. Visit our <Link to="/get-in-touch">Contact Us</Link> page and share details such as pickup and delivery locations, dimensions, weight, and any specific requirements.
                                    <br />
                                    Our team will respond with a tailored, competitive quote to meet your freight needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Bdouble;


