import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function GeneralFreight() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/our-services"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Express Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management provides fast and reliable express freight services across Melbourne and Australia-wide. Specialising in both express road freight and air freight, we ensure timely and secure delivery tailored to your urgent logistics needs." />
                <meta name="keywords" content="Express Freight Services, Air Freight Services, Road Freight Solutions, Fast Delivery Logistics, QFM Logistics, Quan Freight Management, Melbourne Express Freight, Urgent Freight Solutions, Australia-Wide Express Delivery, Time-Sensitive Freight, Reliable Express Logistics, Efficient Freight Transport, Priority Freight Services, Same-Day Freight Delivery" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Express Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's express freight services. Serving Melbourne and Australia-wide, we offer time-sensitive road and air freight solutions to ensure your shipments arrive securely and on time." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-services-express-freight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/express-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/express-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                            "@context": "https://schema.org",
                            "@graph": [
                                {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/express-freight-services#webpage",
                                    "url": "https://qfmlogistics.com.au/express-freight-services",
                                    "name": "Express Freight Services | Logistics Solutions by QFM",
                                    "description": "Quan Freight Management provides fast and reliable express freight services, including same-day and overnight road and air freight solutions across Australia. Tailored to your logistics needs, we ensure speed and precision.",
                                    "inLanguage": "en-AU",
                                    "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "breadcrumb": { "@id": "https://qfmlogistics.com.au/express-freight-services#breadcrumb" }
                                },
                                {
                                    "@type": "BreadcrumbList",
                                    "@id": "https://qfmlogistics.com.au/express-freight-services#breadcrumb",
                                    "itemListElement": [
                                        {
                                            "@type": "ListItem",
                                            "position": 1,
                                            "name": "Home",
                                            "item": "https://qfmlogistics.com.au/"
                                        },
                                        {
                                            "@type": "ListItem",
                                            "position": 2,
                                            "name": "Our Services",
                                            "item": "https://qfmlogistics.com.au/our-services"
                                        },
                                        {
                                            "@type": "ListItem",
                                            "position": 3,
                                            "name": "Express Freight Services",
                                            "item": "https://qfmlogistics.com.au/express-freight-services"
                                        }
                                    ]
                                },
                                {
                                    "@type": "Organization",
                                    "@id": "https://qfmlogistics.com.au#organization",
                                    "name": "Quan Freight Management",
                                    "url": "https://qfmlogistics.com.au",
                                    "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                    "contactPoint": {
                                        "@type": "ContactPoint",
                                        "email": "contact@qfmlogistics.com.au",
                                        "contactType": "Customer Service",
                                        "areaServed": "AU"
                                    },
                                    "sameAs": [
                                        "https://www.linkedin.com/company/qfm-logistics"
                                    ]
                                },
                                {
                                    "@type": "Service",
                                    "@id": "https://qfmlogistics.com.au/express-freight-services#service",
                                    "name": "Express Freight Services",
                                    "description": "Our express freight services offer fast and efficient solutions for time-critical shipments, including same-day and overnight delivery via road and air freight across Australia.",
                                    "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "areaServed": "Australia",
                                    "availableChannel": {
                                        "@type": "ServiceChannel",
                                        "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                    },
                                    "image": [
                                        "https://qfmlogistics.com.au/images/qfm-services-express-freight.jpg",
                                        "https://qfmlogistics.com.au/images/qfm-services-express-freight-two.jpg"
                                    ]
                                },
                                {
                                    "@type": "ImageObject",
                                    "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-express-freight.jpg",
                                    "url": "https://qfmlogistics.com.au/images/qfm-services-express-freight.jpg",
                                    "description": "Efficient and reliable express freight services for urgent shipments across Australia",
                                    "name": "Express Freight Services – Fast and Secure Logistics by QFM",
                                    "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "license": { "@id": "https://qfmlogistics.com.au#license" },
                                    "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "copyrightNotice": "© Quan Freight Management",
                                    "creditText": "Quan Freight Management",
                                    "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                                },
                                {
                                    "@type": "ImageObject",
                                    "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-express-freight-two.jpg",
                                    "url": "https://qfmlogistics.com.au/images/qfm-services-express-freight-two.jpg",
                                    "description": "Seamless and timely express freight services connecting key locations across Australia",
                                    "name": "Express Freight Services – Trusted and Fast Logistics by QFM",
                                    "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "license": { "@id": "https://qfmlogistics.com.au#license" },
                                    "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                    "copyrightNotice": "© Quan Freight Management",
                                    "creditText": "Quan Freight Management",
                                    "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                                },
                                {
                                    "@type": "FAQPage",
                                    "@id": "https://qfmlogistics.com.au/express-freight-services#faq",
                                    "mainEntity": [
                                        {
                                            "@type": "Question",
                                            "name": "What types of shipments can I send via express freight?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Our express freight services accommodate urgent shipments of all sizes, including parcels, documents, and bulk goods. We provide both air and road freight options to meet your time-sensitive needs."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/express-freight-services",
                                                "name": "Express Freight Services FAQ"
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "Are express freight services available Australia-wide?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Yes, we offer express freight services across Australia, connecting all major cities and regional areas efficiently and reliably."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/express-freight-services",
                                                "name": "Express Freight Services FAQ"
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "How do I request a quote for express freight?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Requesting a quote is easy. Visit our Contact Us page, provide your shipment details such as pickup and delivery locations, size, weight, and urgency, and our team will offer a tailored quote promptly."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/express-freight-services",
                                                "name": "Express Freight Services FAQ"
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "What are the delivery options for express freight?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "We offer same-day and overnight delivery options through road and air freight to ensure your shipments reach their destinations as quickly as possible."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/express-freight-services",
                                                "name": "Express Freight Services FAQ"
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "How do you ensure reliability in express freight services?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "We utilise advanced tracking technology, experienced logistics professionals, and a comprehensive network of trusted carriers to ensure timely and reliable delivery for all shipments."
                                            },
                                            "isPartOf": {
                                                "@type": "WebPage",
                                                "@id": "https://qfmlogistics.com.au/express-freight-services",
                                                "name": "Express Freight Services FAQ"
                                            }
                                        }
                                    ]
                                }
                            ]
                        })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Express Freight Services</h1>
                        <p>
                            Perfect for businesses requiring swift delivery, our overnight services guarantee timely shipments across major cities, including Melbourne to Sydney and other key urban centers. With an extensive network and strategic partnerships, we ensure seamless and efficient transportation.
                        </p>
                        {!isMobile && (
                            <p>
                                Our commitment to reliability means your time-sensitive freight is handled with precision, providing peace of mind and maintaining the momentum of your operations. Trust QFM to deliver speed and dependability in every shipment.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-services-express-freight.jpg"
                            loading="lazy"
                            alt="Fast and reliable express freight solutions for urgent shipments across Australia"
                            title="Express Freight Services – Swift and Secure Logistics by QFM"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Fast and dependable express freight solutions to meet your urgent delivery needs</h5>
                        )}
                        <p>
                            QFM's express freight services offer rapid and reliable transport options, ensuring your time-sensitive shipments are delivered with precision and care. <Link to="/our-services">Explore our full range of logistics solutions</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content sydney-add">
                        <h2>Melbourne Express Freight by QFM</h2>
                        <ul>
                            <li>
                                <b>Overnight Delivery:</b> Ensure your urgent shipments arrive by the next business day, providing unparalleled speed and efficiency for time-sensitive deliveries.
                            </li>
                            <li>
                                <b>Reliability:</b> Trust in our proven track record of consistent and dependable express services, ensuring your goods are delivered on schedule.
                            </li>
                            <li>
                                <b>Flexible Scheduling:</b> Adaptable delivery options that cater to your specific timeline requirements, offering convenience and reliability for your express logistics needs.
                            </li>
                            <li>
                                <b>Secure Handling:</b> Advanced security measures and meticulous handling protocols ensure the safe transport of your valuable and sensitive shipments.
                            </li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-services-express-freight-two.jpg"
                            loading="lazy"
                            alt="Fast and reliable express freight solutions for urgent deliveries across Melbourne and Australia"
                            title="Express Freight Services – Swift and Dependable Logistics by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                                <Link to="/our-services"><h4>View all Services</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="express-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What types of shipments are eligible for express freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our express freight services are designed for time-sensitive shipments, including small parcels, urgent documents, and larger goods. Whether by road or air, we ensure your cargo reaches its destination quickly and securely.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Do you provide express freight services Australia-wide?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, we offer express freight services across Australia, connecting major cities like Melbourne, Sydney, Brisbane, and Perth, as well as regional and remote locations.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What are the delivery timeframes for express freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "250px" : "0px" }}
                            >
                                <p>
                                    Delivery times depend on the service level chosen. Our same-day and overnight options ensure rapid transit, while our dedicated air freight services provide the fastest possible delivery for urgent shipments.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How do I request a quote for express freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    To request a quote, visit our <Link to="/get-in-touch">Contact Us</Link> page and provide details like the type of goods, pickup and delivery locations, weight, and urgency. Our team will provide a tailored solution promptly.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Are express freight services suitable for oversized or bulky items?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "250px" : "0px" }}
                            >
                                <p>
                                    Absolutely. We handle express shipments of all sizes, including oversized or bulky items. Whether by road or air, we ensure your freight is transported securely and efficiently.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>What makes QFM’s express freight services stand out?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "250px" : "0px" }}
                            >
                                <p>
                                    Our express freight services are tailored to meet your specific needs, combining speed, reliability, and advanced tracking technology. With our extensive network and dedicated team, we guarantee seamless and timely deliveries every time.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default GeneralFreight;