import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Construction() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Construction Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM provides specialised freight solutions for transporting heavy building materials, equipment, and industrial supplies. Our secure logistics ensure safe, timely delivery across Australia."
        />
        <meta
          name="keywords"
          content="Construction Freight Services, Building Materials Transport, Heavy Equipment Freight, Concrete Shipping, Steel Beams Transport, Construction Logistics, Australia Freight, Melbourne Construction Freight"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Construction Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure freight solutions for heavy construction materials and equipment. QFM’s specialised logistics ensure reliable delivery to construction sites across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-construction.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/construction-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/construction-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/construction-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/construction-freight-services",
                "name": "Construction Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised freight solutions for transporting heavy building materials, equipment, and industrial supplies. Our secure logistics ensure that your construction materials are delivered safely and on time across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/construction-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/construction-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Construction Freight Services",
                    "item": "https://qfmlogistics.com.au/construction-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/construction-freight-services#service",
                "name": "Construction Freight Services",
                "description": "Our construction freight services offer secure transport for heavy building materials, construction equipment, and industrial supplies. We manage large-scale shipments with specialised equipment and expert handling to ensure that your materials arrive safely and on schedule.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-construction.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-construction-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your construction freight needs."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-construction.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-construction.jpg",
                "description": "Secure transport solutions for heavy construction materials and industrial supplies.",
                "name": "Construction Freight Services - Reliable Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/construction-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-construction-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-construction-two.jpg",
                "description": "Efficient freight management for large shipments of construction materials, ensuring safe and timely delivery.",
                "name": "Construction Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/construction-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/construction-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of construction materials do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle a broad range of construction materials including steel beams, concrete, aggregates, and heavy equipment. Our freight solutions are customised to securely manage both bulk shipments and specialised orders."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you ensure the safety of heavy construction shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our experienced team employs specialised loading equipment, secure tie-down methods, and real-time tracking to safeguard your shipments. We adhere to rigorous safety protocols to minimise any risk of damage."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer door-to-door service for construction freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide a comprehensive door-to-door service that includes pickup, secure transit, and direct delivery to your construction site, regardless of its location."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you accommodate expedited shipments for urgent construction projects?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely. We offer expedited freight options for time-sensitive shipments, ensuring your critical materials arrive promptly to keep your project on schedule."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for construction freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To request a quote, please visit our Contact Us page or call our support line with details such as pickup and delivery locations, shipment volume, and any special requirements. We will work with you to provide a competitive, tailored quote."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What distinguishes QFM's construction freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our services are uniquely tailored to the construction industry. We combine specialised handling, a robust door-to-door network, and advanced tracking technology to ensure your materials are delivered securely and efficiently, providing a seamless logistics experience."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/construction-freight-services#webpage" }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Construction Freight Services</h1>            
                <p>
                  Whether you need to move bulk building materials or specialised equipment, our secure freight solutions guarantee efficiency and safety. Our construction freight services are tailored for the unique demands of the building industry. From structural steel and concrete panels to scaffolding and site machinery, we ensure that your shipments are handled with expert precision.
                </p>
            {!isMobile && (
            <p>
              With a vast logistics network spanning both metropolitan and remote regions of Australia, we provide seamless deliveries to construction sites, warehouses, and distribution centres. Our flexible transport solutions help contractors, suppliers, and developers keep their projects on schedule, minimising delays and maximising efficiency.
            </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-construction.jpg"
              alt="Secure transport for construction materials and heavy equipment across Australia"
              title="Construction Freight Services - Efficient and Reliable Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
          {!isMobile && (
  <h5>Reliable Construction Freight Solutions for Your Building Projects</h5>
)}
<p>
  Our freight services provide secure and efficient transport for construction materials and equipment, ensuring timely and dependable deliveries. <Link to="/goods-and-products">Explore our full range of goods and products</Link> to see how our logistics solutions support your supply chain.
</p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Construction Freight by QFM</h2>
            <ul>
  <li>
    <b>Heavy-Duty Transport for Construction Materials:</b> We specialise in the secure freight of steel beams, precast concrete, scaffolding, and large structural components, ensuring safe handling and on-time delivery.
  </li>
  <li>
    <b>Tailored Logistics for Construction Sites:</b> Whether it’s time-sensitive site deliveries or bulk material transport, our flexible freight solutions keep your projects moving without disruption.
  </li>
  <li>
    <b>Nationwide Freight Solutions:</b> Providing seamless transport across Melbourne, Sydney, Brisbane, Perth, Adelaide, and remote locations, supporting construction supply chains with reliable service.
  </li>
  <li>
    <b>Live Tracking & Logistics Management:</b> Gain real-time shipment visibility with proactive tracking and coordination, ensuring materials arrive exactly when needed to avoid costly delays.
  </li>
</ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-construction-two.jpg"
              loading="lazy"
              alt="Efficient transport for heavy construction materials ensuring safe delivery"
              title="Construction Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div
              className={`faq-item ${openFAQ === 0 ? 'active' : ''}`}
              onClick={() => toggleFAQ(0)}
            >
              <h5>What types of construction materials do you transport?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}
              >
                <p>
                  We specialise in transporting a diverse range of construction materials, including steel beams, concrete, aggregates, and other heavy supplies. Our solutions are designed to handle bulk shipments and ensure materials arrive intact.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div
              className={`faq-item ${openFAQ === 1 ? 'active' : ''}`}
              onClick={() => toggleFAQ(1)}
            >
              <h5>How do you ensure the safe transport of heavy construction shipments?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}
              >
                <p>
                  Our experienced team uses specialised loading equipment, secure tie-down methods, and real-time tracking to protect your shipments. We follow rigorous safety protocols to minimise risks during transit.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div
              className={`faq-item ${openFAQ === 2 ? 'active' : ''}`}
              onClick={() => toggleFAQ(2)}
            >
              <h5>Do you provide door-to-door service for construction freight?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 2 ? '280px' : '0px' }}
              >
                <p>
                  Yes, our door-to-door service ensures that your materials are picked up from your location and delivered directly to the construction site, reducing handling and ensuring timely delivery.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div
              className={`faq-item ${openFAQ === 3 ? 'active' : ''}`}
              onClick={() => toggleFAQ(3)}
            >
              <h5>Can you expedite shipments for urgent construction projects?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}
              >
                <p>
                  Absolutely. For time-sensitive projects, we offer expedited freight options that prioritise your shipment, ensuring that critical materials arrive promptly to keep your project on schedule.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div
              className={`faq-item ${openFAQ === 4 ? 'active' : ''}`}
              onClick={() => toggleFAQ(4)}
            >
              <h5>How can I request a quote for construction freight services?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}
              >
                <p>
                  To request a quote, please visit our <Link to="/get-in-touch">Contact Us</Link> page or call our support line. Provide details such as pickup and delivery locations, shipment volume, and any specific requirements, and we’ll offer a competitive, tailored quote.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div
              className={`faq-item ${openFAQ === 5 ? 'active' : ''}`}
              onClick={() => toggleFAQ(5)}
            >
              <h5>What distinguishes QFM's construction freight services?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 5 ? '320px' : '0px' }}
              >
                <p>
                  Our construction freight services are uniquely tailored to the building industry. We combine specialised handling, a comprehensive door-to-door network, and advanced tracking technology to ensure that your heavy materials and equipment are transported securely and efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Construction;