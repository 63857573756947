import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Camping() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener to display the scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Camping Freight Services | Logistics Solutions by QFM</title>
        <meta name="description" content="Secure freight solutions for camping gear and outdoor equipment. QFM ensures safe, timely delivery of tents, sleeping bags, and other essentials across Australia." />
        <meta name="keywords" content="Camping Freight Services, Outdoor Gear Transport, Tent Shipping, Camping Equipment Logistics, Australia Freight, Melbourne Camping Freight, Secure Outdoor Shipping" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Camping Freight Services | Logistics Solutions by QFM" />
        <meta property="og:description" content="Expert freight solutions for camping gear, tents, and outdoor equipment, delivered securely and on time across Australia." />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-camping.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/camping-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/camping-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/camping-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/camping-freight-services",
                "name": "Camping Freight Services | Secure Transport by QFM",
                "description": "Specialised logistics solutions for camping gear and outdoor equipment. Reliable deliveries across major Australian cities and regional areas.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/camping-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/camping-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Camping Freight Services",
                    "item": "https://qfmlogistics.com.au/camping-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/camping-freight-services#service",
                "name": "Camping Freight Services",
                "description": "Expert transport for camping gear, including tents, sleeping bags, backpacks, and outdoor essentials. Our secure logistics ensure your equipment is delivered safely and on time, whether for retail or personal use.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-camping.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-camping-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/get-in-touch",
                  "description": "Get a tailored quote for your camping freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-camping.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-camping.jpg",
                "description": "Safe transport solutions for tents, backpacks, and outdoor gear across Australia.",
                "name": "Camping Freight Services - Reliable Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/camping-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-camping-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-camping-two.jpg",
                "description": "Specialised logistics for bulky camping gear and outdoor equipment, ensuring secure transport across Australia.",
                "name": "Camping Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/camping-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/camping-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of camping gear do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a diverse range of camping equipment including tents, sleeping bags, backpacks, and portable stoves. Our solutions are designed to accommodate both compact and bulky items, ensuring they arrive safely for your outdoor adventures."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you deliver to remote campsites and regional areas?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, our extensive logistics network covers all major Australian cities as well as remote and regional locations. We are committed to ensuring your camping gear reaches even the most secluded campsites on schedule."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What if my camping equipment is unusually large or oddly shaped?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We are experienced in handling items of various sizes and shapes. Simply provide detailed dimensions and weight information, and we will tailor a freight solution to ensure safe transport with minimal risk of damage."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do I request a quote for camping freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To request a quote, please visit our Contact Us page or call our support line with details about your shipment, including pickup and delivery locations, dimensions, and any specific requirements. Our team will work with you to develop a cost-effective and reliable freight solution."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What makes your camping freight services unique?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our camping freight services are specifically tailored for outdoor enthusiasts and retailers. We offer flexible shipping options, real-time tracking, and a nationwide network that covers both urban and remote areas. This commitment to service ensures that your camping gear is delivered safely and efficiently, keeping your outdoor operations running smoothly."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/camping-freight-services#webpage" }
              }
            ]
          })}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Camping Freight Services</h1>
            {!isMobile && (
              <p>
                Getting camping gear to the right place at the right time requires reliability and care. QFM specialises in the secure transport of outdoor equipment, from compact hiking packs to large expedition gear. Whether it’s a shipment for a retailer or supplies for an outdoor event, we ensure safe and efficient delivery, even to remote destinations.
              </p>
            )}
            <p>
              From tents and sleeping bags to portable stoves and adventure gear, we provide nationwide freight solutions tailored to the outdoor industry. With transport options by road, rail, and air, we accommodate everything from bulk inventory to urgent one-off deliveries—helping adventurers and businesses stay prepared for their next journey.
            </p>
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-camping.jpg"
              alt="Professional freight for camping gear and outdoor equipment across Australia"
              title="Camping Freight Services - Reliable and efficient logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/get-in-touch">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Freight Solutions for Camping & Outdoor Gear</h5>
            )}
            <p>
              Whether you're stocking a retail store or gearing up for an outdoor adventure, our specialised freight services ensure safe and timely delivery of your camping essentials.
              <Link to="/goods-and-products"> Explore our full range of logistics solutions.</Link>
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Camping Freight by QFM</h2>
            <ul>
              <li>
                <b>Careful Handling for Outdoor Gear:</b> From tents and backpacks to cooking equipment, we ensure your camping essentials are securely transported without damage.
              </li>
              <li>
                <b>Flexible Freight Solutions:</b> Whether you're shipping a single item or stocking up for an outdoor event, we offer customised transport options to fit your needs.
              </li>
              <li>
                <b>Australia-Wide Reach:</b> We deliver across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations, ensuring access to even remote adventure spots.
              </li>
              <li>
                <b>Reliable & On-Time Delivery:</b> With real-time tracking and efficient scheduling, we make sure your camping gear arrives when and where you need it.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-camping-two.jpg"
              loading="lazy"
              alt="Secure transport for bulky camping gear and outdoor essentials across Australia"
              title="Camping Freight Services - Professional and Reliable Logistics by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>
            {/* FAQ 1 */}
            <div
              className={`faq-item ${openFAQ === 0 ? 'active' : ''}`}
              onClick={() => toggleFAQ(0)}
            >
              <h5>What types of camping gear do you transport?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}
              >
                <p>
                  We transport a diverse range of camping gear including tents, sleeping bags, backpacks, portable stoves, and outdoor furniture. Our solutions are designed to securely handle both compact and bulky items.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div
              className={`faq-item ${openFAQ === 1 ? 'active' : ''}`}
              onClick={() => toggleFAQ(1)}
            >
              <h5>Do you deliver to remote campsites and regional areas?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}
              >
                <p>
                  Yes, our extensive logistics network covers all major Australian cities as well as remote and regional locations, ensuring your camping gear reaches even the most secluded campsites on time.
                </p>
              </div>
            </div>
            {/* FAQ 3 */}
            <div
              className={`faq-item ${openFAQ === 2 ? 'active' : ''}`}
              onClick={() => toggleFAQ(2)}
            >
              <h5>What if my camping equipment is unusually large or irregularly shaped?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 2 ? '240px' : '0px' }}
              >
                <p>
                  We specialise in handling items of all sizes and shapes. Provide us with detailed dimensions and weight, and we will tailor a freight solution to ensure safe transport with minimal risk of damage.
                </p>
              </div>
            </div>
            {/* FAQ 4 */}
            <div
              className={`faq-item ${openFAQ === 3 ? 'active' : ''}`}
              onClick={() => toggleFAQ(3)}
            >
              <h5>How do I request a quote for camping freight services?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}
              >
                <p>
                  To request a quote, please visit our <Link to="/get-in-touch">Contact Us</Link> page or call our support line. Provide details about your shipment, such as pickup and delivery locations, item dimensions, and any special requirements, and we will work with you to develop a cost-effective and reliable freight solution.
                </p>
              </div>
            </div>
            {/* FAQ 5 */}
            <div
              className={`faq-item ${openFAQ === 4 ? 'active' : ''}`}
              onClick={() => toggleFAQ(4)}
            >
              <h5>What makes your camping freight services unique?</h5>
              <div
                className="faq-content"
                style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}
              >
                <p>
                  Our camping freight services are tailored specifically for outdoor equipment. We offer flexible shipping options, real-time tracking, and a nationwide network that covers both urban and remote areas. Our commitment to secure handling and prompt delivery sets us apart, ensuring your camping gear is always ready for adventure.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Camping;