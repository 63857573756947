import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/TermsandConditions.css';
import { FaFilePdf } from 'react-icons/fa';

function TandC() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return; // skip for mobile if not desired
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  const scrollToTop = () => {
    if (isMobile) return; // skip for mobile if we don't want the scroll-up
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (isMobile) return; // skip for mobile
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // initial
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ourstory-main-content">
      <Helmet>
        <title>Terms and Conditions | Logistics Solutions by QFM</title>
        <meta name="description" content="Review the terms and conditions for Quan Freight Management's freight services. Learn about our policies, commitments, and guidelines for providing seamless logistics solutions across Australia, including Melbourne, Sydney, Brisbane, and beyond." />
        <meta name="keywords" content="Terms and Conditions, Logistics Solutions, Freight Services, Quan Freight Management, QFM, QFM Logistics, Policies and Commitments, Seamless Logistics, Australia-wide Freight Services, Freight Delivery Melbourne, Freight Delivery Sydney, Freight Delivery Brisbane, Freight Guidelines, Reliable Freight Services, Transport Policies Australia, Freight Management Solutions" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Terms and Conditions | Logistics Solutions by QFM" />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-terms-and-conditions.jpg" />
        <meta property="og:description" content="Explore our commitments to reliability, transparency, and seamless freight operations throughout Australia." />
        <meta property="og:url" content="https://qfmlogistics.com.au/terms-and-conditions" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/terms-and-conditions" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/terms-and-conditions#webpage",
                "url": "https://qfmlogistics.com.au/terms-and-conditions",
                "name": "Terms and Conditions | Quan Freight Management",
                "description": "Quan Freight Management's policies and terms, detailing reliable freight services across Australia for pallets, cartons, and project logistics.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/terms-and-conditions#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "CreativeWork",
                "@id": "https://qfmlogistics.com.au#license",
                "name": "Terms and Conditions",
                "url": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/terms-and-conditions#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Terms and Conditions",
                    "item": "https://qfmlogistics.com.au/terms-and-conditions"
                  }
                ]
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-terms-and-conditions.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-terms-and-conditions.jpg",
                "description": "Terms and conditions of Quan Freight Management outlining reliable freight services across Australia, including pallets, cartons, and project logistics",
                "name": "Terms and Conditions | Logistics Solutions by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "DigitalDocument",
                "@id": "https://qfmlogistics.com.au/documents/QFM_Policy_ChainofResponsibility.pdf",
                "name": "Chain of Responsibility Policy",
                "description": "QFM's Chain of Responsibility policy for safe freight operations across Australia.",
                "fileFormat": "application/pdf",
                "inLanguage": "en-AU",
                "url": "https://qfmlogistics.com.au/documents/QFM_Policy_ChainofResponsibility.pdf"
              },
              {
                "@type": "DigitalDocument",
                "@id": "https://qfmlogistics.com.au/documents/QFM_Policy_Health&Safety.pdf",
                "name": "Health & Safety Policy",
                "description": "QFM's Health & Safety policy for protecting staff, partners, and clients.",
                "fileFormat": "application/pdf",
                "inLanguage": "en-AU",
                "url": "https://qfmlogistics.com.au/documents/QFM_Policy_Health&Safety.pdf"
              },
              {
                "@type": "DigitalDocument",
                "@id": "https://qfmlogistics.com.au/documents/QFM_Policy_FatigueManagement.pdf",
                "name": "Fatigue Management Policy",
                "description": "QFM's Fatigue Management policy to ensure safe and alert driving practices.",
                "fileFormat": "application/pdf",
                "inLanguage": "en-AU",
                "url": "https://qfmlogistics.com.au/documents/QFM_Policy_FatigueManagement.pdf"
              },
              {
                "@type": "DigitalDocument",
                "@id": "https://qfmlogistics.com.au/documents/QFM_Policy_SpeedManagement.pdf",
                "name": "Speed Management Policy",
                "description": "QFM's Speed Management policy for responsible and compliant freight operations.",
                "fileFormat": "application/pdf",
                "inLanguage": "en-AU",
                "url": "https://qfmlogistics.com.au/documents/QFM_Policy_SpeedManagement.pdf"
              },
              {
                "@type": "DigitalDocument",
                "@id": "https://qfmlogistics.com.au/documents/QFM_Policy_Mass&Dimension.pdf",
                "name": "Mass and Dimension Policy",
                "description": "QFM's Mass and Dimension policy for load compliance and safe haulage.",
                "fileFormat": "application/pdf",
                "inLanguage": "en-AU",
                "url": "https://qfmlogistics.com.au/documents/QFM_Policy_Mass&Dimension.pdf"
              },
              {
                "@type": "DigitalDocument",
                "@id": "https://qfmlogistics.com.au/documents/QFM_Policy_Drug&Alcohol.pdf",
                "name": "Drug and Alcohol Policy",
                "description": "QFM's Drug and Alcohol policy ensuring a safe working environment.",
                "fileFormat": "application/pdf",
                "inLanguage": "en-AU",
                "url": "https://qfmlogistics.com.au/documents/QFM_Policy_Drug&Alcohol.pdf"
              },
              {
                "@type": "DigitalDocument",
                "@id": "https://qfmlogistics.com.au/documents/QFM_Introduction.pdf",
                "name": "QFM Introduction",
                "description": "Introduction to Quan Freight Management’s services and logistics solutions.",
                "fileFormat": "application/pdf",
                "inLanguage": "en-AU",
                "url": "https://qfmlogistics.com.au/documents/QFM_Introduction.pdf"
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
        </script>
      </Helmet>
      <div className="terms-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && (
          <div className="vertical-line"></div>
        )}
        <div className="terms-intro-section terms-intro-section-add appearing-effect">
          <div className="terms-intro-section-text-content">
            <h1>Terms and Conditions</h1>
            <p>
              At Quan Freight Management, we are committed to providing seamless freight solutions that meet the highest standards of reliability and professionalism. These terms and conditions outline the framework within which we deliver our services, ensuring transparency and mutual understanding with our clients.
            </p>
            {!isMobile && (
            <p>
              By partnering with us, you agree to our policies and practices designed to streamline logistics and protect your interests. From freight handling to delivery timelines, every aspect of our service reflects our dedication to innovation, customer satisfaction, and excellence in logistics across Australia.
            </p>
            )}
          </div>
          <div className="terms-intro-section-image-container">
            <img
              src="images/qfm-terms-and-conditions.jpg"
              alt="Terms and conditions of Quan Freight Management outlining reliable freight services across Australia, including pallets, cartons, and project logistics"
              title="Terms and Conditions | Logistics Solutions by QFM"
              loading="lazy"
            />
          </div>
        </div>
        <div className="terms-section-2 appearing-effect">
          <div className="terms-section-2-text-content">
            <h2>Policies</h2>
            <div className="policies-grid">
              <div>
                <a
                  href="/documents/QFM_Policy_ChainofResponsibility.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf style={{ color: 'red', fontSize: '40px' }} />
                  Chain of Responsibility
                </a>
              </div>
              <div>
                <a
                  href="/documents/QFM_Policy_Health&Safety.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf style={{ color: 'red', fontSize: '40px' }} />
                  Health & Safety
                </a>
              </div>
              <div>
                <a
                  href="/documents/QFM_Policy_FatigueManagement.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf style={{ color: 'red', fontSize: '40px' }} />
                  Fatigue Management
                </a>
              </div>
              <div>
                <a
                  href="/documents/QFM_Policy_SpeedManagement.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf style={{ color: 'red', fontSize: '40px' }} />
                  Speed Management
                </a>
              </div>

              <div>
                <a
                  href="/documents/QFM_Policy_Mass&Dimension.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf style={{ color: 'red', fontSize: '40px' }} />
                  Mass and Dimension
                </a>
              </div>
              <div>
                <a
                  href="/documents/QFM_Policy_Drug&Alcohol.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf style={{ color: 'red', fontSize: '40px' }} />
                  Drug and Alcohol
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="terms-section-2 appearing-effect">
          <div className="terms-section-2-text-content">
            <h2>Terms and Conditions</h2>
            <div className="policies-grid">
              <div>
                <a
                  href="/documents/QFM_Terms_and_Conditions.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf style={{ color: 'red', fontSize: '40px' }} />
                  Terms and Conditions
                </a>
              </div>

            </div>
          </div>
        </div>
        <div className="terms-section-2 appearing-effect">
          <div className="terms-section-2-text-content" style={{ marginBottom: '-20px', }}>
            <h2>Credit Application</h2>
            <div className="policies-grid">
              <div>
                <a
                  href="/documents/QFM_Credit_Application.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf style={{ color: 'red', fontSize: '40px' }} />
                  Credit Application
                </a>
              </div>

            </div>
          </div>
        </div> */}
        <div className="terms-section-2 appearing-effect">
          <div className="terms-section-2-text-content" style={{ marginBottom: '-20px', }}>
            <h2>Introduction</h2>
            <div className="policies-grid">
              <div>
                <a
                  href="/documents/QFM_Introduction.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf style={{ color: 'red', fontSize: '40px' }} />
                  QFM Introduction
                </a>
              </div>

            </div>
          </div>
        </div>
        {isVisible && (
          <div className="scroll-up" onClick={scrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        <Footer />
      </div>
    </div >
  );
}

export default TandC;