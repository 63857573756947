import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '../css/Technology.css';

function Technology() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/freight-management-technology"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };
    return (
        <div className="technology-main-content">
            <Helmet>
                <title>Website & Logistics Integration | Logistics Solutions by QFM</title>
                <meta name="description" content="Streamline your freight operations with QFM's integrated logistics solutions. Connect your e-commerce store, ERP, or freight management system for real-time tracking, automated shipping, and seamless order fulfillment across Australia." />

                <meta name="keywords" content="Integrated Freight Solutions, Freight Integration, Logistics Integration, E-Commerce Shipping, ERP Freight Integration, Real-Time Tracking, Automated Freight Management, Quan Freight Management, QFM, QFM Logistics, Shipping Software, Freight API Integration, Warehouse Integration, Freight Technology Australia" />

                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />

                <meta property="og:title" content="Integrated Freight Solutions | Seamless Logistics Integration by QFM" />
                <meta property="og:description" content="Connect your online store or logistics system with QFM for seamless freight automation, live tracking, and efficient shipping across Australia." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-integrated-freight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/integrated-freight-solutions" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />

                <link rel="canonical" href="https://qfmlogistics.com.au/integrated-freight-solutions" />
                {/* NEED TO UPDATE */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/integrated-freight-solutions#webpage",
                                "url": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                "name": "Integrated Freight Solutions | Website & Logistics Automation by QFM",
                                "description": "Streamline your shipping process with QFM's integrated freight solutions. Connect your online store with our logistics network to automate order syncing, live pricing, and seamless delivery tracking across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/integrated-freight-solutions#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "CreativeWork",
                                "@id": "https://qfmlogistics.com.au#license",
                                "name": "Terms and Conditions",
                                "url": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/integrated-freight-solutions#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Management Technology",
                                        "item": "https://qfmlogistics.com.au/freight-management-technology"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Integrated Freight Solutions",
                                        "item": "https://qfmlogistics.com.au/integrated-freight-solutions"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/integrated-freight-solutions#technology-service",
                                "name": "Integrated Freight Solutions",
                                "description": "Seamless website and logistics integration solutions by QFM, connecting online stores with advanced freight management technology. Automate shipping, enhance tracking visibility, and optimize order fulfillment across Australia.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "serviceType": "eCommerce Logistics Integration",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-technology-website-integration.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-technology-website-integration.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-technology-website-integration.jpg",
                                "description": "Seamless website and logistics integration for efficient freight management across Australia",
                                "name": "Website & Logistics Integration - Automated Shipping Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-technology-website-integration-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-technology-website-integration-two.jpg",
                                "description": "Seamless website and logistics integration for automated freight management across Australia",
                                "name": "Website & Logistics Integration – Streamlined Freight Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-technology-website-integration-three.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-technology-website-integration-three.jpg",
                                "description": "Optimised website and logistics integration for real-time freight automation across Australia",
                                "name": "Website & Logistics Integration – Effortless Freight Automation by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {                                
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/integrated-freight-solutions#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Which eCommerce platforms does QFM integrate with?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "QFM integrates with Shopify, WooCommerce, BigCommerce, and other platforms via API. If you're using a different system, reach out to our team to confirm compatibility."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How does live pricing work in my store?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Live pricing calculates freight costs at checkout using real-time carrier rates. It considers package weight, dimensions, and applicable surcharges, ensuring customers receive accurate shipping estimates."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How does order syncing between my store and QFM work?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "When an order is placed, QFM receives the order details, creates a pending consignment, and updates tracking information in your store once shipped. This ensures seamless integration with your fulfillment process."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What freight services does QFM offer?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "QFM partners with over 20 carriers to offer freight solutions for cartons, pallets, oversized goods, and express shipping. We provide competitive bulk rates and scalable logistics support."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can I customise which shipping options my customers see?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, you can control which shipping options appear at checkout, including express services, free shipping thresholds, residential/business delivery options, and surcharges like tailgate or forklift handling."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How is the QFM integration set up?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our in-house team handles the full setup, ensuring seamless integration with your store. We configure live rates, order syncing, and shipping settings, conduct testing, and provide ongoing support."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I get in touch for more information or support?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "For inquiries about integration or troubleshooting assistance, visit our Contact Us page, call our support team, or email us. We’re happy to provide expert guidance tailored to your store’s needs."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What are the common integration workflows for QFM?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "QFM offers two workflows: one for stores without package details, where orders sync as pending consignments for manual processing, and another for stores with full package details, allowing automatic consignment creation and real-time tracking updates."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What factors should I consider when choosing an integration workflow?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Consider whether you need live rates at checkout, require address validation, and how you prefer to manage label printing. Our team can help tailor the integration to fit your business needs."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What is an API in freight logistics?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "An API (Application Programming Interface) connects systems to exchange real-time shipping data. It helps businesses track shipments, automate freight bookings, and enhance logistics efficiency."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How is an API used in logistics?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "APIs enable real-time data exchange between logistics systems, improving shipment tracking and estimated delivery times. Integrating with QFM’s API ensures accurate freight tracking and streamlined shipping processes."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How do freight and logistics APIs function?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Freight APIs act as a bridge between systems, allowing real-time shipment tracking and seamless logistics operations. They provide instant updates on order status, delivery progress, and estimated arrival times."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/integrated-freight-solutions",
                                            "name": "Integration FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="ourstory-intro-section-text-content">
                        <h1>Website Store Integration</h1>
                        {!isMobile && (
                            <p>Seamlessly connect your online store with a powerful logistics network through QFM’s website and logistics integration. Whether you're using Shopify, WooCommerce, BigCommerce, or other eCommerce platforms, our integrated freight solutions ensure real-time shipping rates, automated order processing, and smooth dispatch workflows. </p>

                        )}
                        <p>Our integration capabilities provide businesses with a scalable solution for handling shipments efficiently across Australia. From instant freight quotes at checkout to real-time tracking updates, QFM’s logistics system synchronises with your online store, ensuring fast and reliable deliveries. Whether you’re shipping individual parcels or bulk inventory, our technology-driven approach allows retailers, wholesalers, and suppliers to optimise their supply chain and keep up with growing customer demands.</p>

                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-technology-website-integration.jpg"
                            alt="Seamless website and logistics integration for efficient freight management across Australia"
                            title="Website & Logistics Integration - Automated Shipping Solutions by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">

                    <div className="default-quote-quote">
                        <Link to="/get-in-touch">
                            <h4>Get In Touch</h4>
                        </Link>
                    </div>

                    {!isMobile && (
                        <div className="default-quote-text-content">
                            <h5>Seamless Website & Logistics Integration for Smarter Freight Management</h5>
                            <p>
                                Connect your online store with our advanced freight solutions to automate shipping, enhance tracking visibility, and streamline logistics across Australia.
                            </p>
                        </div>
                    )}
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Store Integration with QFM Freight</h2>
                        <ul>
                            <li>
                                <b>Live Pricing for Accurate Cost Estimates:</b> Display real-time QFM freight rates at checkout, ensuring customers receive transparent shipping costs based on their order size, weight, and destination.
                            </li>
                            <li>
                                <b>Automated Order Syncing:</b> Orders placed in your store are instantly transferred to QFM, eliminating manual data entry and reducing fulfillment times for a streamlined logistics process.
                            </li>
                            <li>
                                <b>Customizable Freight Options for Customers:</b> Choose which QFM freight services appear at checkout, allowing customers to select from express, standard, or other shipping methods tailored to their needs.
                            </li>
                            <li>
                                <b>Ongoing Support & Logistics Expertise:</b> Our team provides continuous support for integration, troubleshooting, and optimization, ensuring a smooth and efficient freight workflow.
                            </li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-technology-website-integration-two.jpg"
                            loading="lazy"
                            alt="Seamless website and logistics integration for automated freight management across Australia"
                            title="Website & Logistics Integration – Streamlined Freight Solutions by QFM"
                        />
                       
                    </div>
                </div>

                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content">
                    <h2 style={isMobile ? { position: "relative", top: "-10px" } : {}}>
                    Benefits of Integrating QFM with your Website Store</h2>
                        <ul>
                            <li>
                                <b>Automated Freight Management:</b> Orders placed in your website store automatically sync with <b>QFM</b>, eliminating manual entry. This reduces errors, saves time, and instantly generates shipping labels and freight bookings.
                            </li>

                            <li>
                                <b>Real-Time Shipping Rates at Checkout:</b> Customers see <b>live QFM shipping rates</b> based on weight, dimensions, and destination, ensuring accurate pricing and a smoother checkout experience.
                            </li>

                            <li>
                                <b>Streamlined Order Fulfillment:</b> Orders are automatically <b>pushed to QFM’s system</b>, ready for dispatch. Shipping labels can then be generated within QFM platform, reducing manual work and improving efficiency.
                            </li>

                            <li>
                                <b>Improved Tracking & Centralized Shipment Management:</b> Track all shipments through the <b>QFM platform</b>, streamlining logistics management in one central location for full visibility and operational efficiency.
                            </li>

                            <li>
                                <b>Customizable Shipping Options:</b> Offer multiple QFM freight services, including <b>Express, Standard, and Road Freight</b>, tailored based on product size, weight, and urgency. <Link to="/our-services">View all our services here.</Link>
                            </li>

                            <li>
                                <b>Scalability & Efficiency for Growing Businesses:</b> Handles high order volumes effortlessly with <b>automated freight workflows</b>, supports <b>multi-location fulfillment</b>, and reduces logistics overhead.
                            </li>

                            <li>
                                <b>Cost Savings on Freight Operations:</b> Integration provides access to <b>QFM’s optimized freight rates</b>, reducing shipping costs while avoiding manual errors in freight calculations.
                            </li>

                            <li>
                                <b>Easy Setup & Integration:</b> The integration connects <b>QFM with your Store</b> using a simple API, with no need for custom development. Manage everything from one centralized dashboard.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="default-quote appearing-effect">
                {!isMobile && (
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch">
                            <h4>Contact Us</h4>
                        </Link>
                    </div>
                )}
                    {!isMobile && (
                        <div className="default-quote-text-content">
                            <h5>Effortless eCommerce & Freight Integration for Hassle-Free Shipping</h5>
                            <p>
                                Sync your online store with QFM’s logistics platform to simplify order fulfillment, improve delivery accuracy, and provide real-time tracking for your customers across Australia.
                            </p>
                        </div>
                    )}
                </div>

                {isMobile && (
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect" style={isMobile ? { position: "relative", top: "-30px" } : {}}>
                    <div className="ourstory-intro-section-image-container">
                    <img
    src="images/qfm-technology-website-integration-three.jpg"
    loading="lazy"
    alt="Optimised website and logistics integration for real-time freight automation across Australia"
    title="Website & Logistics Integration – Effortless Freight Automation by QFM"
/>
                       
                    </div>
                </div>
                )}
                {isMobile && (
                <div className="default-quote appearing-effect" style={isMobile ? { position: "relative", top: "-30px" } : {}}>

                    <div className="default-quote-quote">
                        <Link to="/get-in-touch">
                            <h4>Contact Us</h4>
                        </Link>
                    </div>
                </div>
                )}






                <div className="integration-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>


                        <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
                            <h5>Which eCommerce platforms does QFM integrate with?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                                <p>
                                    QFM integrates with major eCommerce platforms, including Shopify, WooCommerce, and BigCommerce. Our flexible API also allows compatibility with other platforms. If you're using a different system, reach out to our team to check if integration is possible for your store.
                                </p>
                            </div>
                        </div>


                        <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
                            <h5>How does live pricing work in my store?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "600px" : "0px" }}>
                                <p>
                                    Live pricing dynamically calculates freight costs at checkout based on the products in a customer's cart and their shipping address. Here’s how it works:
                                </p>
                                <ul>
                                    <li>The customer adds products to their cart and proceeds to checkout.</li>
                                    <li>Their shipping address is entered, and we receive the order details.</li>
                                    <li>We group items into packages based on weight and dimensions.</li>
                                    <li>We calculate real-time freight quotes using your selected carriers.</li>
                                    <li>Custom pricing adjustments, such as residential or tailgate fees, can be applied.</li>
                                    <li>The customer sees the final shipping price and selects their preferred shipping method.</li>
                                </ul>
                                <p>
                                    Common shipping options include Residential Shipping, Business Shipping, Tailgate Delivery, and Forklift Unload Available at Delivery Point.
                                </p>
                            </div>
                        </div>


                        <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
                            <h5>How does order syncing between my store and QFM work?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "600px" : "0px" }}>
                                <p>
                                    Order syncing ensures a seamless transition from your store to QFM’s freight system. The process works as follows:
                                </p>
                                <ul>
                                    <li>A customer places an order in your store.</li>
                                    <li>Your store automatically notifies QFM of the new order.</li>
                                    <li>A pending consignment is created in QFM with the customer’s details, warehouse location, and order reference.</li>
                                    <li>We can sync SKUs and apply applicable surcharges for tailgates and residential addresses.</li>
                                    <li>The order is picked and packed at the warehouse, and the consignment is updated.</li>
                                    <li>Warehouse staff can select a preferred carrier service or use QFM Live Rates for automatic carrier selection.</li>
                                    <li>Once the consignment is manifested, tracking details sync back to your store, marking the order as shipped.</li>
                                </ul>
                            </div>
                        </div>


                        <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
                            <h5>What freight services does QFM offer?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "300px" : "0px" }}>
                                <p>
                                    QFM works with over 20 carriers, providing freight solutions for cartons, pallets, oversized goods, and specialised deliveries. Our extensive network allows us to offer competitive bulk rates and efficient logistics solutions for businesses of all sizes.
                                </p>
                            </div>
                        </div>


                        <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
                            <h5>Can I customise which shipping options my customers see?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "300px" : "0px" }}>
                                <p>
                                    Yes, you have full control over which shipping options appear at checkout. You can enable or disable specific services based on your customer preferences, such as:
                                </p>
                                <ul>
                                    <li>Offering only express shipping for certain products.</li>
                                    <li>Providing free shipping above a specific cart value.</li>
                                    <li>Allowing business or residential deliveries based on the customer's address.</li>
                                    <li>Applying extra fees for special handling, such as tailgate or forklift unloading.</li>
                                </ul>
                                <p>
                                    This flexibility ensures you provide the best possible shipping options tailored to your business model.
                                </p>
                            </div>
                        </div>


                        <div className={`faq-item ${openFAQ === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
                            <h5>How is the QFM integration set up?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? "500px" : "0px" }}>
                                <p>
                                    Our in-house integration team handles the entire setup process, ensuring a smooth transition without requiring additional input from your developers. The steps include:
                                </p>
                                <ul>
                                    <li>Initial consultation to understand your business needs.</li>
                                    <li>Integration with your store, including Shopify, WooCommerce, BigCommerce, and other platforms.</li>
                                    <li>Configuration of live rates, order syncing, and freight customisation.</li>
                                    <li>Testing to ensure accurate shipping calculations and seamless order management.</li>
                                    <li>Ongoing support to address any issues and optimise the integration over time.</li>
                                </ul>
                                <p>
                                    We ensure that the process is simple and hassle-free, allowing you to focus on running your business while we handle the logistics.
                                </p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFAQ === 6 ? "active" : ""}`} onClick={() => toggleFAQ(6)}>
                            <h5>How can I get in touch for more information or support?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 6 ? "300px" : "0px" }}>
                                <p>
                                    If you have any questions about our integration services or need assistance with setup, our team is here to help.
                                    You can reach out through our <Link to="/get-in-touch">Contact Us</Link> page, call our support line, or email us directly.
                                    Whether you're looking to confirm platform compatibility or need troubleshooting assistance, we’re happy to provide guidance.
                                </p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFAQ === 7 ? "active" : ""}`} onClick={() => toggleFAQ(7)}>
                            <h5>What are the common integration workflows for QFM?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 7 ? "300px" : "0px" }}>
                                <p>
                                    There are two common integration workflows depending on whether you collect package dimensions, weights, and types in your system:
                                </p>
                                <ul>
                                    <li><b>Workflow 1:</b> If package details are not collected, a pending consignment is created in QFM with address details. Once the order is packed, packages are added, a carrier is selected, and labels are printed. Shipment updates sync back to your system.</li>
                                    <li><b>Workflow 2:</b> If package details are collected, a shipment is created in your system, including weights and dimensions. A consignment is then generated via API, triggering immediate printing and real-time status updates back to your system.</li>
                                </ul>
                                <p>
                                    A hybrid approach can also be used depending on your business workflow. Reach out to our team for guidance on the best setup for your store.
                                </p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFAQ === 8 ? "active" : ""}`} onClick={() => toggleFAQ(8)}>
                            <h5>What factors should I consider when choosing an integration workflow?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 8 ? "300px" : "0px" }}>
                                <p>
                                    When selecting the best integration workflow for your business, consider the following:
                                </p>
                                <ul>
                                    <li>Whether you need to display live shipping rates at checkout, which requires capturing package dimensions and weights.</li>
                                    <li>Address validation requirements to minimise delivery errors and improve accuracy.</li>
                                    <li>How you want to manage label generation and printing—whether automated through QFM or handled within your system.</li>
                                </ul>
                                <p>
                                    If you're unsure which setup works best for your operations, our team is available to provide guidance and tailor the integration to your specific needs.
                                </p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFAQ === 9 ? "active" : ""}`} onClick={() => toggleFAQ(9)}>
                            <h5>What is an API in freight logistics?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 9 ? "300px" : "0px" }}>
                                <p>
                                    An API (Application Programming Interface) is a technology that enables different systems to communicate and share data seamlessly. In freight logistics, an API allows merchants, carriers, and logistics providers to exchange real-time shipping data, track shipments, and automate processes for greater efficiency and visibility throughout the supply chain.
                                </p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFAQ === 10 ? "active" : ""}`} onClick={() => toggleFAQ(10)}>
                            <h5>How is an API used in logistics?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 10 ? "300px" : "0px" }}>
                                <p>
                                    In logistics, an API enables seamless data exchange between systems, providing real-time visibility of freight in transit. It helps businesses track shipments, monitor estimated delivery times, and stay informed about potential delays. By integrating with freight management platforms, an API ensures all parties have accurate and up-to-date shipping information.
                                </p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFAQ === 11 ? "active" : ""}`} onClick={() => toggleFAQ(11)}>
                            <h5>How do freight and logistics APIs function?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 11 ? "300px" : "0px" }}>
                                <p>
                                    Freight and logistics APIs act as a bridge between different systems, enabling real-time data exchange. Much like a waiter delivering orders between customers and the kitchen, an API facilitates communication between your logistics platform and your carrier's tracking system. This automated connection ensures you receive up-to-date shipment details, improving tracking visibility and helping businesses make informed decisions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Technology;